import { SelectOption } from './BMICalculator.types';

export const getHeightBMIOptions = (
  min: number,
  max: number,
  unit: 'ft' | 'in'
): SelectOption[] => {
  const options = [];
  for (let i = min; i <= max; i++) {
    options.push({ label: `${i} ${unit}`, value: String(i) });
  }

  return options;
};
