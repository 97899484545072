import { createApi } from '@reduxjs/toolkit/query/react';

import { setFrontDeskTypes, setMembershipPlans, setStates } from 'store/lookup/lookupSlice';

import { STATES_WHERE_ONBOARDING_IS_NOT_ALLOWED } from 'constants/onboarding';
import { baseQuery } from 'utils/services';

import {
  GetAppointmentCategoriesReqProps,
  GetAppointmentCategoriesResProps,
  GetAppointmentTypesReqProps,
  GetAppointmentTypesResProps,
  GetFrontDeskTypesReqProps,
  GetFrontDeskTypesResProps,
  GetMembershipPlansReqProps,
  GetMembershipPlansResProps,
  GetStatesReqProps,
  GetStatesResProps,
  GetSuggestedAddressesReqProps,
  GetSuggestedAddressesResProps
} from './lookup.types';

export const lookupApi = createApi({
  baseQuery,
  endpoints: (build) => ({
    getAppointmentCategories: build.query<
      GetAppointmentCategoriesResProps,
      GetAppointmentCategoriesReqProps
    >({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken || undefined
        },
        url: '/lookup/appointment-categories'
      })
    }),
    getAppointmentTypes: build.query<GetAppointmentTypesResProps, GetAppointmentTypesReqProps>({
      keepUnusedDataFor: 3600, // 1 hour
      query: ({ accessToken, ...params }) => ({
        headers: {
          Authorization: accessToken
        },
        params,
        url: '/lookup/appointment-types'
      })
    }),
    getFrontDeskTypes: build.query<GetFrontDeskTypesResProps, GetFrontDeskTypesReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          dispatch(setFrontDeskTypes(data.data));
        }
      },
      query: () => ({
        url: '/lookup/front-desk-request-types'
      })
    }),
    getMembershipPlans: build.query<GetMembershipPlansResProps, GetMembershipPlansReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data?.data) {
          dispatch(setMembershipPlans(data.data));
        }
      },
      query: (params) => ({
        params: {
          limit: 50,
          ...params
        },
        url: '/lookup/membership-plans'
      })
    }),
    getStates: build.query<GetStatesResProps, GetStatesReqProps>({
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded }) {
        const { data } = await cacheDataLoaded;
        if (data) {
          const states = data.data
            .map((v) => ({
              label: v.name,
              value: v.stateCode
            }))
            .filter((v) => !STATES_WHERE_ONBOARDING_IS_NOT_ALLOWED.includes(v.label));
          dispatch(setStates(states));
        }
      },
      query: (params) =>
        `/lookup/state-list${
          params?.hideStatesWithoutLicense ? '?hideStatesWithoutLicense=true' : ''
        }`
    }),
    getSuggestedAddresses: build.query<
      GetSuggestedAddressesResProps,
      GetSuggestedAddressesReqProps
    >({
      query: (params) => ({
        params,
        url: '/lookup/verify-addresses'
      })
    })
  }),
  reducerPath: 'lookupApi',
  tagTypes: ['Lookup']
});

export const {
  useGetFrontDeskTypesQuery,
  useGetStatesQuery,
  useGetMembershipPlansQuery,
  useLazyGetMembershipPlansQuery,
  useGetAppointmentTypesQuery,
  useLazyGetAppointmentTypesQuery,
  useGetAppointmentCategoriesQuery,
  useLazyGetAppointmentCategoriesQuery,
  useLazyGetSuggestedAddressesQuery
} = lookupApi;
