import { useEffect, useState } from 'react';

import { selectMifInfo, selectSignUp } from 'store';
import { SocialHistoryItemProps } from 'store/mif/mif.types';
import { setMifInformation } from 'store/mif/mifSlice';

import ButtonsGroup from 'pages/SignUp/regularFlows/mifSteps/parts/ButtonsGroup';
import StepHeaderInfo from 'pages/SignUp/regularFlows/mifSteps/parts/StepHeaderInfo';

import SocialHistory from 'widgets/mif/SocialHistory';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import useSubmitMifParts from 'hooks/useSubmitMifParts';
import { handleRequestCatch } from 'utils/helpers';

import { MifStepProps } from '../mifSteps.types';

const SocialHistoryPage: React.FC<MifStepProps> = ({ moveToStep }) => {
  const [data, setData] = useState<SocialHistoryItemProps[]>([]);
  const {
    mifInfo: { socialHistory }
  } = useAppSelector(selectMifInfo);
  const {
    user: { accessToken }
  } = useAppSelector(selectSignUp);
  const dispatch = useAppDispatch();

  const { isEdit, onNavigate } = useMifNavigate();
  const { sendMifPart, isLoading } = useSubmitMifParts();

  const handleSubmit = () => {
    const body = {
      accessToken,
      metrics: {
        socialHistory: data
      }
    };

    sendMifPart(body)
      .catch(handleRequestCatch)
      .finally(() => onNavigate(() => moveToStep('next')));
  };

  const setSelectedValues = () => {
    dispatch(
      setMifInformation({
        data,
        prop: 'socialHistory'
      })
    );
    handleSubmit();
  };
  useEffect(() => {
    setData(socialHistory || []);
  }, []);

  return (
    <div className="intake-form-wrapper">
      <StepHeaderInfo
        dataTestId="social_history"
        stepSubtitle="Please check all that apply. Your information will only be used for medical purposes."
        stepTitle="Social history"
      />
      <SocialHistory values={data} isPostOnboarding onUpdate={setData} />
      <ButtonsGroup
        isDisabledNextBtn={data.length === 0 || isLoading}
        loading={isLoading}
        nextBtnText={isEdit ? 'Update' : 'Complete'}
        hidePrev
        onClickNext={setSelectedValues}
      />
    </div>
  );
};

export default SocialHistoryPage;
