import classNames from 'classnames';

import { useZoomCall } from 'hooks';
import useWidth from 'hooks/useWidth';

import { VoiceIndicatorProps } from './voiceIndicator.types';

const VoiceIndicator: React.FC<VoiceIndicatorProps> = ({ isSpeaking, videoEnabled, local }) => {
  const { isMinimize, isOpenChat } = useZoomCall();
  const { isMobile } = useWidth();

  const getIndicatorStyle = (heightPercentage: string) =>
    classNames(
      'rounded-full bg-white text-sm transition-all',
      {
        [`voice-indicator-bar-${heightPercentage}`]: isSpeaking
      },
      local || isMinimize || (isMobile && isOpenChat) ? 'size-[3px]' : 'size-[5px]'
    );

  const getContainerStyle = () =>
    classNames('flex items-center justify-center rounded-full text-white', {
      absolute: videoEnabled,
      'absolute inset-0 z-10 m-auto bg-black/20': !local,
      'right-1 top-1 size-7 gap-0.5 bg-blue p-1': local,
      'size-16 gap-1 p-4': !local && !(isMinimize && !local) && !(isMobile && isOpenChat),
      'size-9 gap-0.5 p-2': (isMinimize && !local) || (isMobile && isOpenChat)
    });

  return (
    <div className={getContainerStyle()}>
      <span className={getIndicatorStyle('left h-[40%]')}></span>
      <span className={getIndicatorStyle('middle h-[70%]')}></span>
      <span className={getIndicatorStyle('right h-[40%]')}></span>
    </div>
  );
};

export default VoiceIndicator;
