import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import { useClickAway, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { VideoPlayer } from '@zoom/videosdk';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useCallLogTracksMutation } from 'services/appointments/appointments';

import { notifyError } from 'shared/Toast/Toast';

import { useMessages, useZoomCall } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useWidth from 'hooks/useWidth';

import { VideoCallControlsProps } from './controls.types';

const Controls: React.FC<VideoCallControlsProps> = ({ handleLogout }) => {
  const logEvent = useAnalytics();
  const [callLogTracks] = useCallLogTracksMutation();
  const refConfirm = useRef<HTMLDivElement>(null);
  const { channels, channelDetails } = useMessages();
  const channel = channels.find((item) => item.channelId === channelDetails?._id);
  const { isMobile } = useWidth();
  const {
    room,
    connectionLost,
    isMinimize,
    isOpenChat,
    audioEnabled,
    videoEnabled,
    appointment,
    showControls,
    cameraId,
    microphoneId,
    isFullscreen,
    toggleIsOpenSettings,
    toggleIsOpenChat,
    toggleIsMinimize,
    toggleIsFullscreen,
    toggleVideoEnabled,
    toggleAudioEnabled,
    toggleLoadingRoom
  } = useZoomCall();
  const [isOpenConfirm, toggleOpenConfirm] = useToggle(false);
  const isMinimizeOrMobile = isMinimize || (isMobile && isOpenChat);
  const stream = room?.getMediaStream();
  useClickAway(refConfirm, () => toggleOpenConfirm(false));

  const startAudio = () => {
    stream
      ?.startAudio({
        backgroundNoiseSuppression: true,
        microphoneId,
        mute: !audioEnabled
      })
      .catch((err) => err);
  };

  const stopVideo = () => {
    if (!room || !stream) return;
    return stream
      .stopVideo()
      .then(() => stream.detachVideo(room.getCurrentUserInfo().userId))
      .then(() => {
        document.querySelector('#local-video')?.querySelector('video-player')?.remove();
      });
  };

  const startVideo = () => {
    if (!room || !stream) return;
    return stream
      .startVideo({
        cameraId,
        originalRatio: true
      })
      .then(() => stream.attachVideo(room.getCurrentUserInfo().userId, 3))
      .then((userVideo) => {
        document.querySelector('#local-video')?.appendChild(userVideo as VideoPlayer);
      });
  };
  const handleCallLogTracks = (audio: boolean, video: boolean) => {
    callLogTracks({
      appointmentId: appointment?._id || '',
      tracks: {
        audio: {
          enabled: audio
        },
        video: {
          enabled: video
        }
      }
    }).unwrap();
  };

  const handleUpdatedAudioEnabled = () => {
    logEvent('meeting_room_micro_btn_click');
    (audioEnabled ? stream?.muteAudio() : stream?.unmuteAudio())
      ?.then(() => toggleAudioEnabled(!audioEnabled))
      ?.catch(({ reason }) => notifyError(reason));
    handleCallLogTracks(!audioEnabled, videoEnabled);
  };

  const handleUpdatedVideoEnabled = () => {
    if (!room || !stream) return;
    logEvent('meeting_room_camera_btn_click');

    (videoEnabled ? stopVideo() : startVideo())
      ?.then(() => toggleVideoEnabled(!videoEnabled))
      .catch(({ reason }) => notifyError(reason));

    handleCallLogTracks(audioEnabled, !videoEnabled);
  };

  const handleClickChat = () => {
    logEvent('meeting_room_chat_btn_click');
    toggleIsOpenChat();
  };

  const handleClickEndCall = () => {
    logEvent('meeting_room_leave_btn_click');
    toggleOpenConfirm();
  };

  const handleClickSettings = () => {
    logEvent('meeting_room_settings_btn_click');
    toggleIsOpenSettings();
  };

  const initializeRoom = async () => {
    if (videoEnabled) {
      await startVideo();
    }
    toggleLoadingRoom(false);
    startAudio();
  };

  useEffect(() => {
    initializeRoom();
  }, []);

  useEffect(() => {
    !showControls && toggleOpenConfirm(false);
  }, [showControls]);

  const buttonClassName = (active?: boolean) =>
    classNames('rounded-full shadow', {
      'bg-white hover:bg-gray-50': !active,
      'pointer-events-none opacity-30': connectionLost,
      'text-gray-400 bg-gray-50/10': active
    });
  const iconClassName = isMinimize ? 'size-9 p-2' : 'h-[52px] w-[52px] md:h-11 md:w-11 p-3';

  return (
    <motion.div
      animate={!showControls && !channel?.unreadMessageCount ? 'hidden' : 'visible'}
      initial="visible"
      variants={{
        hidden: {
          marginTop: isMobile && !isMinimize ? 134 : 0,
          transition: {
            duration: isMinimize ? 0 : 0.5,
            function: 'ease-in-out'
          },
          translateY: isMobile ? 0 : 134
        },
        visible: {
          marginTop: isMobile ? 0 : undefined,
          transition: {
            duration: 0.2,
            function: 'ease-in-out'
          },
          translateY: isMobile ? undefined : 0
        }
      }}
    >
      <div
        className={classNames('absolute z-10 flex items-center', {
          'bottom-2 left-8 gap-2 md:left-2': isMinimizeOrMobile,
          'inset-x-0 bottom-[50px] mx-auto w-[calc(100vw_-_2rem)] justify-between gap-3 rounded-full border border-white/10 bg-black/40 p-3 shadow-xl md:bottom-4 md:w-fit md:justify-center':
            !isMinimizeOrMobile
        })}
        ref={refConfirm}
        style={{
          backdropFilter: isMinimizeOrMobile ? 'none' : 'blur(14px)'
        }}
      >
        {isOpenConfirm && (
          <div className="absolute inset-x-0 bottom-full mx-auto mb-3 flex w-max gap-4 rounded-xl bg-white p-4 shadow">
            <Common.Button
              color="red"
              dataTestId="end_appointment"
              size="sm"
              onClick={handleLogout}
              onTouchStart={handleLogout}
            >
              End appointment
            </Common.Button>
            <Common.Button
              color="white-alt"
              dataTestId="cancel_btn"
              size="sm"
              onClick={toggleOpenConfirm}
              onTouchStart={toggleOpenConfirm}
            >
              Cancel
            </Common.Button>
          </div>
        )}
        {!(isMobile && (isOpenChat || isMinimize)) && (
          <>
            <button
              className={buttonClassName(!audioEnabled)}
              data-testid={`microphone_on_off_${audioEnabled}`}
              onClick={handleUpdatedAudioEnabled}
              onTouchStart={handleUpdatedAudioEnabled}
            >
              <Common.Icon
                className={iconClassName}
                name={audioEnabled ? 'microphone' : 'microphone-off'}
              />
            </button>
            <button
              className={buttonClassName(!videoEnabled)}
              data-testid={`camera_on_off_${videoEnabled}`}
              onClick={handleUpdatedVideoEnabled}
              onTouchStart={handleUpdatedVideoEnabled}
            >
              <Common.Icon className={iconClassName} name={videoEnabled ? 'video' : 'video-off'} />
            </button>
          </>
        )}
        {!isMinimizeOrMobile && (
          <>
            <Tooltip
              className="z-10 !mt-1.5 !rounded-xl !p-3 !text-sm !shadow-sm"
              classNameArrow="!w-4 !h-4"
              hidden={!channel?.unreadMessageCount}
              id="chat-button"
            />
            <button
              className={classNames('relative rounded-full shadow', {
                'animate-bounce bg-blue text-white before:absolute before:right-3 before:top-3 before:h-2 before:w-2 before:rounded-full before:border before:border-white before:bg-red hover:bg-blue-600':
                  channel?.unreadMessageCount,
                'bg-white hover:bg-gray-50': !channel?.unreadMessageCount,
                'pointer-events-none opacity-30': connectionLost
              })}
              data-testid="settings"
              data-tooltip-content="New message"
              data-tooltip-id="chat-button"
              onClick={handleClickChat}
              onTouchStart={handleClickChat}
            >
              <Common.Icon className={iconClassName} name="chat" />
            </button>
            <button
              className={buttonClassName()}
              data-testid="settings"
              data-tooltip-id="settings-tooltip"
              onClick={handleClickSettings}
              onTouchStart={handleClickSettings}
            >
              <Common.Icon className={iconClassName} name="dots-vertical" />
            </button>
            <button
              className="rounded-full bg-red shadow"
              data-testid="call_end"
              onClick={handleClickEndCall}
              onTouchStart={handleClickEndCall}
            >
              <Common.Icon className={`${iconClassName} text-white`} name="phone-end-filled" />
            </button>
          </>
        )}
      </div>
      <div
        className={classNames('absolute flex gap-2 max-md:hidden', {
          'bottom-4 right-4': !isMinimizeOrMobile,
          'right-2 top-2': isMinimizeOrMobile
        })}
      >
        <button onClick={toggleIsMinimize} onTouchStart={toggleIsMinimize}>
          <Common.Icon
            className="size-9 rounded-full bg-black/70 p-2 text-white"
            name={isMinimizeOrMobile ? 'maximize' : 'minimize'}
          />
        </button>
        <button
          className={isMinimizeOrMobile ? 'hidden' : ''}
          onClick={toggleIsFullscreen}
          onTouchStart={toggleIsFullscreen}
        >
          <Common.Icon
            className="size-9 rounded-full bg-black/70 p-2 text-white"
            name={isFullscreen ? 'expand-close' : 'expand'}
          />
        </button>
      </div>
    </motion.div>
  );
};

export default Controls;
