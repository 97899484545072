import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import DropContainer from 'features/DropContainer';

import useWidth from 'hooks/useWidth';

import { AdditionalInformationProps } from './additionalInformation.types';

const AdditionalInformation: React.FC<AdditionalInformationProps> = ({
  onContinue,
  category,
  title = 'Add any additional information for your appointment.',
  required = false
}) => {
  const [dragOverlay, setDragOverlay] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const { isMobile } = useWidth();
  const handleClick = (items: FileList | null) => {
    if (!items) {
      return;
    }
    const newItems = [...items].filter(
      (f) => !files.some((fi) => fi.lastModified === f.lastModified && fi.name === f.name)
    );
    items && setFiles([...files, ...newItems]);
  };

  const removeFile = (file: File) => {
    setFiles(files.filter((f) => f.name !== file.name && f.lastModified !== file.lastModified));
  };

  return (
    <div className="flex h-full grow flex-col justify-between">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Common.ColorTag color="primary" icon="check" text={category} />
          <h1 className="text-m2xl font-bold text-primary-700">{title}</h1>
        </div>
        <div>
          <DropContainer
            className={classNames(
              'flex flex-col gap-4 target-animate-div w-full cursor-pointer rounded-2xl border p-8 transition-all duration-300',
              dragOverlay ? 'border-2 border-solid border-primary bg-primary-400' : 'border-dashed'
            )}
            setDragOverlay={setDragOverlay}
            onChange={handleClick}
          >
            {
              <Common.Icon
                className={classNames(
                  'mx-auto size-12',
                  dragOverlay ? 'text-white' : 'text-gray-400'
                )}
                name="camera"
              />
            }
            {isMobile ? (
              <>
                <p
                  className={classNames(
                    'text-center text-mLg font-bold',
                    dragOverlay ? 'text-white' : 'text-gray'
                  )}
                >
                  Upload a photo or video
                </p>
                <p
                  className={classNames(
                    'text-center text-base font-medium',
                    dragOverlay ? 'text-white' : 'text-gray'
                  )}
                >
                  Your care team will be able to see any documents you upload here.
                </p>
              </>
            ) : (
              <>
                <p
                  className={classNames(
                    'text-center text-base font-medium',
                    dragOverlay ? 'text-white' : 'text-gray'
                  )}
                >
                  Drag your photos or videos here to start uploading.
                </p>
                <p
                  className={classNames(
                    'w-full text-center text-base font-bold',
                    dragOverlay ? 'text-white/30' : 'text-gray'
                  )}
                >
                  Or
                </p>
              </>
            )}
            <Common.Button
              className={classNames('mx-auto', { 'opacity-30': dragOverlay })}
              color="blue-alt"
              fullWidthOnMobile
            >
              Browse files
            </Common.Button>
          </DropContainer>
          <div
            className="mx-auto mt-4 flex w-full max-w-[385px] flex-col gap-2"
            data-testid="uploaded_files_block"
          >
            {files.map((file: File) => (
              <div
                className="flex items-center gap-2 rounded-2xl bg-gray-200 p-4 text-mSm md:py-2 md:text-base"
                key={file.lastModified + file.name}
              >
                <Common.Icon className="size-[37px] text-primary" name="image" />
                <span className="flex-1 truncate">{file.name}</span>
                <button data-testid="remove_button" onClick={() => removeFile(file)}>
                  <Common.Icon className="text-gray" name="close" />
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Common.Button
        className="mx-auto mt-4"
        color="blue"
        dataTestId="continue_btn"
        disabled={required && !files.length}
        fullWidthOnMobile
        onClick={() => onContinue(files)}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default AdditionalInformation;
