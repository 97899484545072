import { ActionType } from 'services/wm/wm.types';

import { selectUser } from 'store';

import { PlanCodes, WMDeviceFlow } from 'utils/enums';
import { checkIfUserIsInsuranceUser, checkIfUserIsTTUser, getLatestSurvey } from 'utils/helpers';

import { useAppSelector } from './index';

const useWeightManagement = () => {
  const {
    activePlanCode,
    isAsyncSignup,
    hadOrHasWeightManagement,
    weightManagement,
    activePricePoint,
    wmDeviceFlow
  } = useAppSelector(selectUser);

  // Action types are ordered by priority
  const incompleteSurvey = [
    ActionType.firstDeviceCheckIn,
    ActionType.weeklyDeviceCheckIn,
    ActionType.monthlyDeviceCheckIn,
    ActionType.monthlyMedicationCheckIn,
    ActionType.renewal,
    ActionType.checkIn
  ]
    .map((type) => getLatestSurvey(weightManagement?.incompleteSurveys, type as ActionType))
    .find(Boolean);

  const isWeightManagement = activePlanCode === PlanCodes.WeightManagementMembership;

  return {
    hadOrHasWeightManagement,
    incompleteSurvey,
    isAsyncPlan: isAsyncSignup && isWeightManagement,
    isInsurancePatient: checkIfUserIsInsuranceUser(activePricePoint),
    isRWEPatient: wmDeviceFlow === WMDeviceFlow.WMDevices,
    isTTPatient: checkIfUserIsTTUser(activePricePoint),
    isWeightManagement
  };
};

export default useWeightManagement;
