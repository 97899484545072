import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { PageWrapperProps } from './pageWrapper.types';

const VARIANTS = {
  animate: {
    opacity: 1,
    // translateY: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut'
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    },
    translateY: '-200px'
  },
  initial: {
    opacity: 0
    // translateY: '100%',
  }
};
const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = ({
  children,
  className,
  style
}) => {
  return (
    <motion.div
      animate="animate"
      className={className}
      exit="exit"
      initial="initial"
      style={style}
      variants={VARIANTS}
    >
      {children}
    </motion.div>
  );
};

export default PageWrapper;
