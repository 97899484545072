import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { PathName } from 'utils/enums';

import Heading from '../../components/Heading';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';

const Pregnancy: React.FC<{ migrationFlow?: 'tt' | 'wm'; onContinue: () => void }> = ({
  onContinue,
  migrationFlow
}) => {
  const { send, isLoading } = useSubmitOrchestrateForm();

  const [activeItem, setActiveItem] = useState<boolean>();
  const navigate = useNavigate();
  const query = useQuery();

  const handleSelect = (v: number | boolean) => {
    if (v === true) {
      return navigate({
        search: `s=${migrationFlow === 'tt' ? 'pregnancy-plans' : 'pregnancy'}&hideProgress=true&subStep=pregnancy-warning&answer=${v}`
      });
    } else {
      setActiveItem(false);
      send(
        'mif_qa',
        [{ answer: false, question: 'pregnancy_or_planning_to_become_pregnant' }],
        () => {
          setActiveItem(undefined);
          onContinue();
        }
      );
    }
  };

  const handleProspect = () => {
    navigate(PathName.Home);
  };
  const isErrorState = query.get('subStep') === 'pregnancy-warning';

  return (
    <div className="flex flex-col gap-6">
      {isErrorState ? (
        <>
          <h2 className="wm-signup-title text-center" data-testid="header">
            We regret to inform you that you are not suitable for GLP-1 medication.
          </h2>
          <Common.Alert type="warning" colorableBackground>
            Extensive clinical studies on the safety of GLP-1 medications on pregnant or
            breastfeeding women have not yet been conducted yet. Due to concerns about potential
            adverse effects, our affiliated providers are unable to write pregnant or intending to
            become pregnant women a prescription for GLP-1 medications at this point in time.
          </Common.Alert>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </>
      ) : (
        <>
          <Heading
            category="Plan"
            title="Are you pregnant, breastfeeding, or planning to become pregnant within the next 3 months?"
          />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={activeItem}
            onSelect={handleSelect}
          />
        </>
      )}
    </div>
  );
};

export default Pregnancy;
