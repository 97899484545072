import { useParams } from 'react-router-dom';

import { useGetAppointmentQuery } from 'services/appointments/appointments';

import Loader from 'shared/Loader';
import AppointmentStatus from 'widgets/createAppointment/extendedFlow/AppointmentStatus';

import { useGetActivePlan } from 'hooks/useGetActivePlan';

import RegularAppointment from './components/RegularAppointment';

const Appointment = () => {
  const { id = '' } = useParams<{ id: string }>();
  const { data, isLoading } = useGetAppointmentQuery(id);
  const { pricePoint } = useGetActivePlan();
  const paidForAppt =
    pricePoint?.initialAppointmentCost !== '0' || pricePoint?.subsequentAppointmentCost !== '0';
  const appointment = data?.data;

  if (isLoading) return <Loader isVisible />;

  return (appointment?.appointmentMethod === 'message' || appointment?.category === 'queue') &&
    ['careTeamSentMessage', 'pending'].includes(appointment?.appointmentStatus) ? (
    <AppointmentStatus
      appointment={{
        category: appointment.category,
        displayName: appointment.appointmentType.displayName,
        id: appointment._id,
        idVerificationSkippedAt: appointment.idVerificationSkippedAt,
        method: appointment.appointmentMethod,
        status: appointment.appointmentStatus
      }}
      postCheckout={paidForAppt}
    />
  ) : (
    <RegularAppointment appointment={appointment} />
  );
};

export default Appointment;
