import { createApi } from '@reduxjs/toolkit/query/react';

import { setCart } from 'store/shop/shopSlice';

import { baseQueryWithReauth } from 'utils/services';

import {
  AddProductToCartReq,
  CheckoutReqProps,
  CheckoutResProps,
  GetCartInfoRes,
  GetProductDetailsRes,
  GetProductsListReq,
  GetProductsListRes,
  GetShopOrdersRes,
  GetSingleOrderDetailsRes
} from './shop.types';

export const shopApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addToCart: build.mutation<unknown, AddProductToCartReq>({
      query: ({ productId, ...body }) => ({
        body,
        method: 'POST',
        url: `/store/cart/${productId}`
      })
    }),
    deleteFromCart: build.mutation<unknown, Pick<AddProductToCartReq, 'productId'>>({
      query: ({ productId }) => ({
        method: 'DELETE',
        url: `/store/cart/${productId}`
      })
    }),
    getCart: build.query<GetCartInfoRes, void>({
      keepUnusedDataFor: 0,
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setCart(data.data));
        });
      },
      query: () => ({
        url: '/store/cart'
      })
    }),
    getProducts: build.query<GetProductsListRes, GetProductsListReq>({
      keepUnusedDataFor: 0,
      query: (params) => ({
        url: '/store/products',
        ...(params && { params })
      })
    }),
    getShopOrders: build.query<GetShopOrdersRes, void>({
      query: () => ({
        url: '/store/orders'
      })
    }),
    getSingleOrderDetails: build.query<GetSingleOrderDetailsRes, string>({
      query: (id) => ({
        url: `/store/orders/${id}`
      })
    }),
    getSingleProductDetails: build.query<GetProductDetailsRes, string>({
      query: (id) => ({
        url: `/store/products/${id}`
      })
    }),
    storeCheckout: build.mutation<CheckoutResProps, CheckoutReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/store/checkout'
      })
    })
  }),
  reducerPath: 'shopApi',
  tagTypes: ['shop']
});

export const {
  useGetProductsQuery,
  useGetSingleProductDetailsQuery,
  useLazyGetCartQuery,
  useGetCartQuery,
  useAddToCartMutation,
  useDeleteFromCartMutation,
  useGetSingleOrderDetailsQuery,
  useLazyGetSingleOrderDetailsQuery,
  useGetShopOrdersQuery,
  useLazyGetShopOrdersQuery,
  useStoreCheckoutMutation
} = shopApi;
