import React from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';

import { getAshLabKitInfo } from 'pages/Labs/labs.settings';

import { BaseModalProps } from 'modals/modal.types';

import { STORE_ORDER_TRACKING_URL } from 'constants/externalLinks';
import useWidth from 'hooks/useWidth';
import { DateFormat } from 'utils/enums';

import { ShopOrderItemShortInfo } from 'models/shop';
interface ShopOrderDetailsProps extends BaseModalProps {
  data?: ShopOrderItemShortInfo;
}

const ShopOrderDetails: React.FC<ShopOrderDetailsProps> = ({ data, isOpen, onClose }) => {
  const { isMobile } = useWidth();
  const isLabKit = data?.items[0].product?.type?.toLowerCase().includes('lab kit');

  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="base">
      {data ? (
        <div className="flex flex-col gap-6 p-4" data-testid="receipt_modal">
          <div className="flex flex-col gap-2">
            <span className="text-gray" data-testid="invoice_order_number">
              Invoice #{data.orderNumber}
            </span>
            <span className="text-mLg font-bold">
              {data?.items.map((el) => el.product.type).join(' and ')}
            </span>
            <span>{dayjs(data.createdAt).format(DateFormat.MMMM_DD_YYYY)}</span>
          </div>
          <div
            className="flex flex-col gap-4 rounded-2xl border border-gray-200 bg-gray-50 p-4"
            data-testid="receipt_body"
          >
            {data.items?.map((item) => (
              <div className="flex items-center justify-between" key={item.product._id}>
                <span>{item.product.name}</span>
                <span>${item.product.unitPrice}</span>
              </div>
            ))}
            {/* NO DISCOUNTS FOR V1 */}
            {/* <div className="flex items-center justify-between text-orange">
              <span>50% off</span>
              <span>-$124.50</span>
            </div> */}
            <div className="flex items-center justify-between font-bold">
              <span>Total due</span>
              <span data-testid="total_due">${data.total}</span>
            </div>
            <div className="h-px w-full bg-gray-200"></div>
            <div>
              <div className="flex items-center justify-between font-bold">
                <span>Amount paid</span>
                <span data-testid="amount_paid">${data.total}</span>
              </div>
              <span className="text-gray">
                Payment from{' '}
                <span className="capitalize">{data.invoice.payment.paymentMethod.cardBrand}</span> -{' '}
                {data.invoice.payment.paymentMethod.maskedCardNumber}
              </span>
            </div>
          </div>
          <div>
            {!!data.shippingStatus && (
              <span className="mb-1 block font-bold">
                Shipment:{' '}
                {isLabKit
                  ? getAshLabKitInfo(data.shippingStatus)
                  : data.shippingStatus || 'Ordered'}
              </span>
            )}
            <span>{data.trackingNumber}</span>
          </div>
          <div className="flex gap-3 max-md:flex-col max-md:py-6 md:justify-center">
            <a href={data.invoice.invoiceUrl.toString()} target="_blank" rel="noreferrer">
              <Common.Button
                color={isMobile ? 'blue' : 'white-alt'}
                preIcon="download"
                size="lg"
                fullWidthOnMobile
              >
                Download
              </Common.Button>
            </a>
            {data.trackingNumber && (
              <Common.Button
                className={isMobile ? 'hidden' : ''}
                color="blue"
                size="lg"
                fullWidthOnMobile
                onClick={() =>
                  window.open(STORE_ORDER_TRACKING_URL + data.trackingNumber, '_blank')
                }
              >
                Track Shipment
              </Common.Button>
            )}
          </div>
        </div>
      ) : (
        <span className="m-auto block text-center text-gray">
          Error while getting information, please try again later
        </span>
      )}
    </Common.Modal>
  );
};

export default ShopOrderDetails;
