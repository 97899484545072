import { useEffect, useState } from 'react';

import { selectMifInfo, selectSignUp } from 'store';
import { ChronicDiseasesItemProps } from 'store/mif/mif.types';
import { setMifInformation } from 'store/mif/mifSlice';

import ChronicDiseases from 'widgets/mif/ChronicDiseases';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';
import useSubmitMifParts from 'hooks/useSubmitMifParts';
import { handleRequestCatch } from 'utils/helpers';

import { MifStepProps } from '../mifSteps.types';
import ButtonsGroup from '../parts/ButtonsGroup';
import StepHeaderInfo from '../parts/StepHeaderInfo';

const ChronicDiseasesPage: React.FC<MifStepProps> = ({ moveToStep }) => {
  const {
    mifInfo: { chronicDiseases: storedData }
  } = useAppSelector(selectMifInfo);
  const { user } = useAppSelector(selectSignUp);

  const { isEdit, onNavigate } = useMifNavigate();
  const { sendMifPart, isLoading } = useSubmitMifParts();

  const [chronicDiseasesData, setChronicDiseasesData] = useState<ChronicDiseasesItemProps[]>([]);
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    const body = {
      accessToken: user?.accessToken,
      metrics: {
        chronicDiseases: chronicDiseasesData
      }
    };

    sendMifPart(body)
      .catch(handleRequestCatch)
      .finally(() => onNavigate(() => moveToStep('next')));
  };

  const setSelectedValues = () => {
    dispatch(
      setMifInformation({
        data: chronicDiseasesData,
        prop: 'chronicDiseases'
      })
    );
    handleSubmit();
  };

  useEffect(() => {
    setChronicDiseasesData(storedData || []);
  }, []);

  return (
    <div className="intake-form-wrapper">
      <StepHeaderInfo
        dataTestId="chronic_diseases"
        stepSubtitle="Have you had any of the following medical conditions?"
        stepTitle="Chronic diseases"
      />
      <ChronicDiseases
        chronicDiseases={chronicDiseasesData}
        isPostOnboarding
        onUpdate={setChronicDiseasesData}
      />
      <ButtonsGroup
        isDisabledNextBtn={chronicDiseasesData.length === 0 || isLoading}
        loading={isLoading}
        nextBtnText={isEdit ? 'Update' : 'Next'}
        hidePrev
        onClickNext={setSelectedValues}
      />
    </div>
  );
};

export default ChronicDiseasesPage;
