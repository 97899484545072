import { Common } from '@thecvlb/design-system';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';

import { FlowTypes } from 'utils/enums';

import Medication from 'assets/icons/medication.png';

const TTResults: React.FC<AltSignupStepProps> = ({ onContinue, selectedFlow }) => {
  const isBenefitCheck = selectedFlow === FlowTypes.WeightManagementBenefitsCheckNew;

  const ttSteps = [
    'Purchase Triple Therapy Program',
    ...(isBenefitCheck ? [] : ['Complete labs']),
    'Meet with a provider',
    'Receive your medication',
    '30-day checkup'
  ];
  return (
    <div className="flex h-full flex-col items-center justify-center gap-6 pt-8">
      <div className="relative">
        <img alt="example-2" src={Medication} />
        <div className="absolute -right-5 -top-5 rounded-full bg-secondary-100 p-4 shadow">
          <Common.Icon className="size-6 text-secondary" name="check" />
        </div>
      </div>
      <div className="flex flex-col items-center gap-2">
        <h3 className="wm-signup-title text-center" data-testid="header">
          Good news!
        </h3>
        <span className="text-center text-primary-700" data-testid="sub_header">
          You’ve pre-qualified for the LifeMD Weight Management Triple Therapy Program.
        </span>
      </div>
      <div className="mb-4 flex w-full flex-col gap-6 rounded-2xl border border-gray-200 bg-white p-4 shadow-lg">
        <h3 className="text-center text-mLg font-bold text-primary-700">Next steps</h3>
        <div className="flex flex-col gap-6">
          {...ttSteps.map((step, index) => (
            <div className="flex items-center gap-3" key={step}>
              <span className="block size-10 flex-none rounded-full bg-primary-100 p-2 text-center text-primary">
                {index + 1}
              </span>
              <span className="font-semibold">{step}</span>
            </div>
          ))}
        </div>
      </div>
      <Common.Button className="max-md:mt-auto" color="blue" fullWidthOnMobile onClick={onContinue}>
        Continue
      </Common.Button>
    </div>
  );
};

export default TTResults;
