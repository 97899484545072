import { useContext } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppDispatch, RootState } from 'store';

import { CommonSocketContext } from 'contexts/commonSocketContext/commonSocketContext';
import { FrontDeskContext } from 'contexts/frontDeskContext/frontDeskContext';
import { MessagesContext } from 'contexts/messagesContext/messagesContext';
import { ZoomCallContext } from 'contexts/zoomCallContext/zoomCallContext';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useQuery = () => new URLSearchParams(useLocation().search);
export const useCommonSocket = () => useContext(CommonSocketContext);
export const useZoomCall = () => useContext(ZoomCallContext);
export const useMessages = () => useContext(MessagesContext);
export const useFrontDesk = () => useContext(FrontDeskContext);
