import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import SeparatorIcon from 'assets/icons/vertical-separator.svg?react';

const NextSteps: React.FC<{ activeStep?: string }> = ({ activeStep }) => {
  const cardClassName = 'flex flex-col gap-3 rounded-2xl border bg-white p-4 ';
  const headingClassName = 'text-mBase font-bold';

  return (
    <div className="flex flex-col gap-6">
      <h3 className="text-mLg font-semibold text-primary-700">Next steps</h3>
      <div>
        {activeStep === 'checkout' ? (
          <div className={classNames(cardClassName, 'border-2 border-primary-300 shadow-xl')}>
            <Common.ColorTag color="charcoal" icon="map-point" text="You are here" />
            <h4 className={headingClassName}>Complete checkout</h4>
          </div>
        ) : (
          <div className={classNames(cardClassName, 'border-2 border-primary-300 shadow-xl')}>
            <Common.ColorTag color="charcoal" text="Start here" />
            <h4 className={headingClassName}>Complete medical questionnaire</h4>
            <p className="text-mSm">
              We have a few more questions to ask you before you can meet with a provider.
            </p>
          </div>
        )}
        <div className="pl-6">
          <SeparatorIcon />
          <SeparatorIcon className="-mt-3.5 mb-0.5" />
        </div>

        <div className={cardClassName}>
          <h4 className={headingClassName}>Meet with a provider</h4>
          <p className="text-mSm">
            During this session, the provider will review your health history and weight loss goals;
            prescribe treatment, if appropriate; and confirm a prescription refill process.
          </p>
        </div>
        <div className="pl-6">
          <SeparatorIcon />
          <SeparatorIcon className="-mt-3.5 mb-0.5" />
        </div>
        <div className={cardClassName}>
          <h4 className={headingClassName}>Shipping and delivery</h4>
          <p className="text-mSm">
            If medication is prescribed, we'll coordinate shipment directly to your preferred
            address.
          </p>
        </div>
        <div className="pl-6">
          <SeparatorIcon />
          <SeparatorIcon className="-mt-3.5 mb-0.5" />
        </div>
        <div className={cardClassName}>
          <h4 className={headingClassName}>Complete labs</h4>
          <p className="text-mSm">
            We strongly recommend you complete lab work to assess kidney and liver function prior to
            starting medication. Lab work will be required before receiving your first medication
            refill.
          </p>
          <p className="text-mXs text-gray">
            LifeMD will provide a lab order for you to complete a comprehensive metabolic panel
            (CMP), which is a routine blood test.
          </p>
        </div>
        <div className="pl-6">
          <SeparatorIcon />
          <SeparatorIcon className="-mt-3.5 mb-0.5" />
        </div>
        <div className={cardClassName}>
          <h4 className={headingClassName}>30-day check up</h4>
          <p className="text-mSm">
            Within 30 days, you'll have a follow-up with your provider to confirm dosing and answer
            any questions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
