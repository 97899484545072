import { useGenerateDynamicLinkMutation } from 'services/auth/auth';
import { DynamicLinkActions } from 'services/general/general.types';

import useWidth from 'hooks/useWidth';
import { handleRequestCatch } from 'utils/helpers';

const useContinueInApp = ({
  accessToken,
  expiredToken,
  firstAppointmentId,
  navigateFn,
  action = DynamicLinkActions.WAITING_ROOM
}: {
  accessToken: string;
  action?: DynamicLinkActions;
  expiredToken: string;
  firstAppointmentId?: string;
  navigateFn: () => void;
}) => {
  const { isMobile } = useWidth();
  const [generateDynamicLink] = useGenerateDynamicLinkMutation();

  return () => {
    if (isMobile) {
      generateDynamicLink({
        accessToken,
        action,
        expiredToken,
        src: 'signUp',
        ...(!!firstAppointmentId && { appointmentId: firstAppointmentId })
      })
        .unwrap()
        .then(({ data: { dynamicLink } }) => setTimeout(() => window.open(dynamicLink, '_top')))
        .catch((e) => handleRequestCatch(e, 'Please try again'));
    } else {
      navigateFn();
    }
  };
};

export default useContinueInApp;
