import { useForm } from 'react-hook-form';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectNewAppointmentExtended } from 'store';

import EncryptedBadge from 'shared/EncryptedBadge';
import Loader from 'shared/Loader';
import PaymentFormNew from 'widgets/PaymentFormNew';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { useAppSelector } from 'hooks';
import { useGetLifeMDPlusPlan } from 'hooks/useGetLifeMDPlusPlan';

import AppointmentCardWithPlanUpdate from './AppointmentCardWithPlanUpdate';

const ConfirmWithoutSubscription: React.FC<{
  loading: boolean;
  onProceed: (data: PaymentFormFields) => void;
}> = ({ onProceed, loading }) => {
  const { control, getValues, handleSubmit } = useForm<PaymentFormFields>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange'
  });
  const { callMethod } = useAppSelector(selectNewAppointmentExtended);

  const { lifeMDPlusPlan, defaultPricePoint, isFetching } = useGetLifeMDPlusPlan();

  const appointmentCost =
    callMethod === 'message'
      ? defaultPricePoint?.asyncAppointmentCost
      : defaultPricePoint?.subsequentAppointmentCost;
  const finalPrice = appointmentCost;
  const submitBtnTitle = finalPrice ? `Confirm $${finalPrice} payment` : 'Confirm';

  const getMembershipData = () => {
    if (defaultPricePoint && lifeMDPlusPlan) {
      const qty = defaultPricePoint.paymentInterval.qty;

      return {
        discount: defaultPricePoint.totalCost,
        paymentDue: appointmentCost,
        paymentIntervalUnits: qty === 1 ? '/mo' : `/every ${qty} mo`,
        planName: lifeMDPlusPlan?.planName,
        totalCost: defaultPricePoint.totalCost
      };
    }
  };

  const headingClassName = 'font-bold text-gray-700 md:hidden';
  return (
    <div className="max-md:h-full max-md:pb-4">
      <Loader isVisible={isFetching} />
      <div className="mx-auto flex size-full max-w-[500px] flex-col px-6 md:rounded-xl md:bg-white">
        <div className="mx-auto flex w-full max-w-[500px] flex-col gap-4 max-md:h-full md:gap-8 md:py-8">
          <h1 className="large-title hidden md:block" data-testid="heading">
            {!!appointmentCost ? 'Confirm and Pay' : 'Confirm'}
          </h1>
          <h2 className={headingClassName}>Items</h2>

          <AppointmentCardWithPlanUpdate
            appointmentCost={appointmentCost}
            callMethod={callMethod}
            finalPrice={appointmentCost}
            membershipInfo={getMembershipData()}
          />

          <form className="flex flex-col gap-4" onSubmit={handleSubmit(onProceed)}>
            <h2 className="hidden text-xl font-bold text-primary-700 md:block">Payment method</h2>
            <h2 className={headingClassName}>Payment</h2>
            {!isFetching && (
              <PaymentFormNew control={control} getValues={getValues} showEligible={false} />
            )}

            <div className="mt-auto flex flex-1 flex-col items-center gap-4 max-md:justify-end">
              <Common.Button
                color="blue"
                disabled={isFetching}
                isLoading={loading}
                fullWidthOnMobile
              >
                {submitBtnTitle}
              </Common.Button>
              <EncryptedBadge />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConfirmWithoutSubscription;
