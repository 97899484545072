export enum Gender {
  Man = '0',
  NonBinary = '4',
  NotListed = '6',
  PreferToHide = '7',
  TransgenderMan = '2',
  TransgenderWoman = '3',
  TwoSpirit = '5',
  Unknown = '8',
  Woman = '1'
}

export enum SexAtBirth {
  Female = '1',
  Male = '0',
  Other = '2',
  Unknown = '3'
}

export enum WMDeviceFlow {
  WMDevices = 'wmDevices'
  // WMScales = 'wmScales'
}

/* Notifications settings */
export enum NotificationTypes {
  BrowserNotification = 'browserNotification',
  EmailNotification = 'emailNotification',
  TextNotification = 'textNotification'
}

export enum NotificationNames {
  AppointmentChanges = 'appointmentChanges',
  AppointmentReminder = 'appointmentReminder',
  Messages = 'messages',
  newOffers = 'newOffers'
}

export enum PathName {
  AccountInformation = '/account-settings/account-information',
  AccountSettings = '/account-settings',
  About = '/settings/about',
  Settings = '/settings',
  Legal = '/settings/legal',
  MedicaidWaiver = '/settings/medicaid-waiver',
  HippaAgreement = '/settings/hippa-agreement',
  AddMeasurement = '/add-measurement',
  Appointment = '/appointment',
  AppointmentRequired = '/wm-appointment-required',
  CurrentWeight = '/current-weight',
  BillingDetails = '/account-settings/billing-details',
  HealthProfile = '/health-profile',
  MedicalIntakeForm = '/health-profile/medical-intake-form',
  HealthMetrics = '/health-profile/health-metrics',
  HealthConditions = '/health-profile/health-conditions',
  ChronicDiseases = '/health-profile/chronic-diseases',
  SocialHistory = '/health-profile/social-history',
  WomensHealth = '/health-profile/womens-health',
  Medications = '/health-profile/medications',
  Allergies = '/health-profile/allergies',
  MedicalRecords = '/health-profile/medical-records',
  SignInAndSecurity = '/account-settings/sign-in-and-security',
  ChangePassword = '/account-settings/change-password',
  Insurance = '/account-settings/insurance',
  ChangePlan = '/change-plan',
  Checkout = '/checkout',
  CompleteAccount = '/complete-account',
  ConfirmAccount = '/confirm-account',
  CreateAppointmentExtended = '/create-appointment-extended',
  CreateFreeAccount = '/create-free-account',
  CreateNewPassword = '/create-new-password',
  Home = '/',
  GetCare = '/get-care',
  DeviceStatus = '/device-status',
  Documents = '/documents',
  UploadDocuments = '/upload-documents',
  ForgotPassword = '/forgot-password',
  // HowToUseDevices = '/how-to-use-devices',
  IntakeForm = '/intake-form',
  Labs = '/labs',
  History = '/history',
  Login = '/login',
  Messages = '/messages',
  MigrateToBundlePlan = '/migrate-to-bundle-plan',
  MigrateToPlan = '/migrate-to-plan',
  // MigrateToCareOnlyPlan = '/migrate-to-care-only-plan',
  MigrateToTT = '/migrate-to-tt',
  MigrateToWM = '/migrate-to-wm',
  // MissingDataWMDevices = '/missing-data',
  NotFound = '/not-found',
  Notifications = '/account-settings/notifications',
  AppointmentMif = '/mif-form',
  PaymentMethods = '/payment-methods',
  // PostOnboarding = '/post-onboarding',
  Prescriptions = '/prescriptions',
  MyPrescriptions = '/my-prescriptions',
  Pharmacy = '/my-prescriptions/pharmacy',
  DiscountCard = '/my-prescriptions/discount-card',
  Shop = '/shop',
  ShopAddress = '/shop/address',
  ShopCart = '/shop/cart',
  ShopCheckout = '/shop/checkout',
  ShopOrders = '/shop/orders',
  ShopProducts = '/shop/products',
  SignUp = '/signup',
  KrogerLocations = '/kroger-locations',
  KrogerSavingsClub = '/kroger-savings-club',
  KrogerConnectID = '/connect-kroger-id',
  Support = '/settings/support',
  UnpaidInvoice = '/unpaid-invoice',
  UnsupportedAge = '/unsupported-age',
  UpgradeAccount = '/upgrade-account',
  UpgradePlan = '/upgrade-plan',
  UpgradeToAnnualPlan = '/upgrade-to-annual',
  VerifyEmail = '/verify/email',
  VerifyYourIdentity = '/verify-your-identity',
  Weight = '/weight',
  MyResources = '/my-resources',
  ZoomCall = '/zoom-call',
  MoveFromTirzepatide = '/move-from-tirzepatide',
  KrogerPlus = '/kroger-plus'
}

export enum PlanTypes {
  ConciergeCare = 'cc',
  FlexCare = 'fc',
  LifeMDMembership = 'p',
  LifeMDPlus = 'lmp',
  NotSelected = 'n',
  TotalCare = 'tc',
  UnlimitedPlan = 'u',
  UrgentCare = 'uc',
  WeightManagement = 'w'
}

export enum FlowTypes {
  AcipHexBFlow = 'aciphex-b',
  AcipHexFlow = 'aciphex',
  AvodartBFlow = 'avodart-b',
  AvodartFlow = 'avodart',
  BasicExtendedDynamicFlow = 'b1xc', //TODO this flow is no longer used
  BasicExtendedFlow = 'b1x', //TODO this flow is no longer used
  BasicExtendedNewPlansFlow = 'b1n', //TODO this flow is no longer used
  BasicFlow = 'b1', //TODO this flow is no longer used
  BasicFlowText = 'b1b', //TODO this flow is no longer used
  BlueLineFlow = 'bl',
  HRTClubFlow = 'hrt', //TODO this flow is no longer used
  HealthWarehouseFlow = 'hw',
  HumatinBFlow = 'humatin-b',
  HumatinFlow = 'humatin',
  InsuranceFlow = 'i1',
  InsuranceFlowAlt = 'r1',
  LovazaBFlow = 'lovaza-b',
  LovazaFlow = 'lovaza',
  MixedFlow = 'b3-hidden',
  MixedFlowWithRequiredPopup = 'b3c-hidden',
  QueueFlow = 'q1', //TODO this flow is no longer used
  QueueFlowWithNewPlans = 'q2', //TODO this flow is no longer used
  QueueFlowWithNewPlansAlt = 'q2b', //TODO this flow is no longer used
  ShortFlow = 'b2-hidden',
  ShortFlowWithOptionalPopup = 'b2c-hidden',
  TripleTherapy = 'tt',
  UTIFlow = 'uti',
  WeightManagementFlow = 'wm1',
  WeightManagementFlowAsync = 'wma',
  WeightManagementFlowExtended = 'wm2',
  WeightManagementBenefitsCheckNew = 'wmi4-hidden',
  WeightManagementFlowInsurance = 'wmi',
  WeightManagementFlowInsuranceAlt = 'wmi2',
  WeightManagementFlowInsuranceDiscovery = 'wmi3',
  WeightManagementFlowInsuranceMedications = 'wmbc',
  WeightManagementFlowMedicaid = 'wmm',
  WeightManagementFlowOptavia = 'wm-optavia',
  WeightManagementBalladHealth = 'wm-ballad-health',
  WeightManagementSpecific = 'wmspec'
}

/* eslint typescript-sort-keys/string-enum: ["off"] */
export enum SignUpSteps {
  AdditionalConditions = 'additional-conditions',
  Agreement = 'agreement',
  Allergies = 'allergies',
  AppointmentCategory = 'appointment-category',
  AppointmentConfirmation = 'appointment-confirmation',
  AppointmentDetails = 'appointment-details',
  BipolarDisorder = 'bipolar-disorder',
  BlueLineIntro = 'blue-line-intro',
  CheckPrescriptionCoverage = 'wm-check-prescription-coverage',
  ChooseProvider = 'choose-provider',
  ChronicDiseases = 'chronic-diseases',
  Confirmation = 'confirmation',
  CreateNewUser = 'create-new-user',
  HealthConditions = 'health-conditions',
  InitialInfo = 'initial-info',
  Insurance = 'insurance',
  InsuranceType = 'insurance-types',
  Intro = 'intro',
  MarketingCompare = 'compare-to-others',
  MarketingLowerPrice = 'lower-price',
  MarketingMoneyBackGuarantee = 'money-back-guarantee',
  MarketingRatedNumberOne = 'rated-number-one-for-glp-1',
  MarketingRegularMeetings = 'regular-meetings',
  MarketingWorkingOnApproval = 'working-on-approval',
  MarketingVideo = 'intro-video',
  Medications = 'medications',
  MedicaidWaiver = 'medicaid-waiver',
  MifSummary = 'mif-summary',
  NoticeForCalifornia = 'notice-for-california-patients',
  Payment = 'payment',
  PaymentOptions = 'payment-options',
  Pharmacy = 'pharmacy',
  PickVideoOrMessageAppointment = 'pick-appointment-type',
  PlanSelect = 'plan-select',
  PreConfirmation = 'pre-confirmation',
  PreInsurance = 'check-insurance',
  PreferredMedication = 'preferred-medication',
  Pregnancy = 'wm-pregnancy',
  SCResults = 'results',
  SelectLabs = 'select-labs',
  ShouldUseInsurance = 'should-use-insurance',
  SocialHistory = 'social-history',
  State = 'state',
  Summary = 'summary',
  SwitchToApp = 'switch-to-app',
  TwoOptionsFinish = 'finish-signup',
  UploadBodyPhoto = 'upload-body-photo',
  VerifyIdentity = 'verify-identity',
  WMAddress = 'wm-address',
  WMAge = 'wm-age',
  WMBasicInfo = 'wm-basic-info',
  WMBloodPressure = 'wm-blood-pressure',
  WMBupropion = 'wm-bupropion',
  WMCheckout = 'wm-checkout',
  WMCompleteOnboarding = 'wm-complete-onboarding',
  WMCongrats = 'wm-congrats',
  WMDOB = 'wm-dob',
  WMDiabetesQuestion = 'wm-diabetes-question',
  WMGallbladderQuestion = 'wm-gallbladder-question',
  WMGallStonesQuestion = 'wm-gallstones-question',
  WMGlaucoma = 'wm-glaucoma',
  WMHeightAndWeight = 'height-and-weight',
  WMKetogenicDiet = 'wm-ketogenic-diet',
  WMKidneyStones = 'wm-kidney-stones',
  WMKnowledgeAboutGLP1 = 'wm-knowledge-about-glp-1',
  WMLabWork = 'wm-lab-work',
  WMLacticAcidosis = 'wm-lactic-acidosis',
  WMLiverOrKidneyImpairment = 'wm-liver-or-kidney-impairment',
  WMMAOInhibitors = 'wm-mao-inhibitors',
  WMMedicalConditions = 'wm-medical-conditions',
  WMNewTargetWeight = 'wm-new-target-weight',
  WMPancreatitisQuestion = 'wm-pancreatitis-question',
  WMPhoneNumber = 'wm-phone-number',
  WMPriorSeizures = 'wm-prior-seizures',
  WMScheduleAppointment = 'wm-schedule-appointment',
  WMSetPassword = 'wm-set-password',
  WMSexAtBirth = 'wm-sex-at-birth',
  WMSideEffectsWithMetforminBupropion = 'wm-side-effects-with-metformin-bupropion',
  WMTTResults = 'wm-tt-results',
  WMThyroidNodulesQuestion = 'wm-thyroid-nodules-question',
  WMUserHealthState = 'wm-user-health-state',
  WMWeightLoseAim = 'wm-weight-lose-aim',
  WMWeightLoseWithoutUncertainty = 'wm-weight-lose-without-uncertainty',
  WMWhatNext = 'wm-what-next',
  WMWhyTT = 'wm-why-triple-therapy',
  WMYouAreInGoodHands = 'wm-you-are-in-good-hands',
  WithOrWithoutInsurance = 'using-insurance',
  WomensHealth = 'womens-health',
  YourWeightLossProgram = 'your-weight-loss-program',
  InsuranceAppointmentsAndMedications = 'insurance-for-appointments-and-medications',
  InsuranceForAppointments = 'insurance-for-appointments',
  InsuranceMedicationsToBeDiscussed = 'insurance-medications-to-be-discussed',
  InsuranceForMedications = 'insurance-for-medications',
  InsuranceLowCostOptions = 'insurance-low-cost-options',
  CheckMyBenefits = 'check-my-benefits',
  PersonalDetails = 'personal-details',
  DobStep = 'dob',
  AddInsurance = 'add-insurance',
  VerifyInsurance = 'verify-insurance',
  UnlockYourResults = 'unlock-your-results',
  BenefitsResult = 'benefits-result'
}

export enum FeatureFlag {
  AnnualPlanUpgradePage = 'annual-plan-upgrade-page-feature',
  // AsyncSignup = 'async-signup',
  BillingDetails = 'billing-details-feature',
  BillingHistory = 'billing-history-feature',
  DarkMode = 'dark-mode',
  DocumentsPage = 'documents-page-feature',
  ExtendedHeightAndWeightUI = 'extended-height-and-weight-ui',
  ExtendedUpgradeFlow = 'extended-migration-to-wm',
  GLPPrescription = 'glp-prescription',
  HealthMetrics = 'health-metrics-feature',
  HealthProfilePage = 'health-profile-feature',
  Labcorp = 'labcorp-labs-feature',
  MedicalIntakeForm = 'medical-intake-form-feature',
  MemberPricing = 'member-pricing-feature',
  MigrateToBundlePlan = 'migrate-to-bundle-plan',
  OnboardingWMScales = 'onboarding-wm-scales',
  Paypal = 'paypal-feature',
  PharmacyTab = 'pharmacy-tab-feature',
  PrescriptionDiscountCard = 'prescription-discount-card-feature',
  BenefitsCheck = 'benefits-check-feature',
  Reports = 'reports-feature',
  StateMismatch = 'state-mismatch',
  SuggestedAddress = 'suggested-address',
  UpgradeToLifeMDPlus = 'upgrade-to-lifemd-plus',
  WeightChartDetails = 'weight-chart-details-feature',
  MigrateFromTirzepatide = 'migrate-from-tirzepatide',
  VideoRecording = 'video-recording',
  SteadymdProviders = 'steadymd-providers-feature'
}

export enum PlanCodes {
  // AnnualMembership = 'lifemd-annual-membership',
  // Appointment = 'appointment-30',
  ConciergeMembership = 'concierge-care',
  FlexCare = 'limited-access',
  InitialAppointment = 'initial-appointment',
  LifeMDMembership = 'membership',
  TotalCareMembership = 'total-care',
  UnlimitedMembership = 'unlimited-membership',
  UrgentCare = 'urgent-care',
  WeightManagementMembership = 'weight-management',
  LifeMDPlus = 'lifemd-plus'
}

export enum HealthProfileNavItem {
  MedicalIntakeForm = 'Medical intake form',
  HealthMetrics = 'Health metrics',
  HealthConditions = 'Health conditions',
  SocialHistory = 'Social history',
  ChronicDiseases = 'Chronic diseases',
  AdditionalHealthConditions = 'Additional health conditions',
  Medications = 'Medications',
  Allergies = 'Allergies',
  WomenHealth = `Women's health`
}

export enum PageTitle {
  AccountInformation = 'Account information',
  MedicaidWaiver = 'Medicaid waiver',
  HippaAgreement = 'HIPAA Marketing Authorization',
  Appointments = 'Appointments',
  GetCare = 'Get care',
  Cart = 'Cart',
  Weight = 'Weight',
  Back = 'Back',
  BillingCard = 'Billing & card',
  ChangePassword = 'Change password',
  SignInAndSecurity = 'Sign in & security',
  Insurance = 'Insurance',
  // ChoosePharmacy = 'Choose pharmacy',
  ChoosePlan = 'Change your plan',
  ConfirmPlanChange = 'Confirm plan change',
  CreateNewRequest = 'Create new request',
  // Dashboard = 'Dashboard',
  Documents = 'Documents',
  // UploadDocuments = 'Upload Documents',
  HealthProfile = 'Health profile',
  HealthMetrics = 'Health metrics',
  ChronicDiseases = 'Chronic diseases',
  WomensHealth = 'Womens health',
  SocialHistory = 'Social history',
  Medications = 'Medications',
  HealthConditions = 'Health conditions',
  Allergies = 'Allergies',
  MedicalRecords = 'Medical records',
  LabsImaging = 'Labs & Imaging',
  Messages = 'Messages',
  // MyAccount = 'My account',
  About = 'About',
  AccountSettings = 'Account settings',
  Legal = 'Legal',
  History = 'History',
  // MyProvider = 'My provider',
  Notifications = 'Notifications',
  PaymentMethod = 'payment method',
  Prescriptions = 'Prescriptions',
  Shop = 'Shop',
  KrogerSavingsClub = 'Kroger Savings Club',
  Support = 'Support',
  MyResources = 'My resources',
  UpgradePlan = 'Upgrade plan',
  // WMArticle = 'Guide',
  // WeightManagement = 'Weight Management',
  KrogerPlus = 'Kroger Plus',
  KrogerLocations = 'Kroger Locations'
}

export enum DateFormat {
  D = 'D', // 1
  MMM = 'MMM', // Jan
  MMMM_D = 'MMMM D', // January 1
  MMMM_DD_YYYY = 'MMMM DD, YYYY', // May 25, 2024
  // MMMM_D_h_mma_z = 'MMMM D, h:mm a z', // January 1, 1:00 am EST
  // MMMM_YYYY = 'MMMM YYYY', // January 2018
  MMM_D = 'MMM D', // Jan 1
  MMM_DD = 'MMM DD', // Jan 01
  MMM_DD_YYYY = 'MMM DD, YYYY', // May 25 · 2024
  MMM_D_YYYY_h_mma_z = 'MMM D · YYYY [at] h:mm a', // Jan 01 · 2018 at 1:00 am
  MMM_D_h_mma = 'MMM D · h:mma', // Jan 1 · 1:00am
  MMM_D_h_mma_z = 'MMM D · h:mm a z',
  MMM_YY = "MMM 'YY", // May '24
  MM_DD = 'MM/DD', // 01/01
  MM_DD_YYYY = 'MM/DD/YYYY', // 01/01/2018
  /** Use this format for BE requests */
  YYYY_MM_DD = 'YYYY-MM-DD', // 2023-06-06
  ddd = 'ddd', // Sun
  dddd = 'dddd', // Sunday
  dddd_MMMM_D_h_mm_A = 'dddd, MMMM D · h:mm A', // Sunday, January 1 · 1:00 AM
  // dddd_MMMM_D_h_mma_z = 'dddd, MMMM D · h:mm a z', // Sunday, January 1 · 1:00am EST
  h_A = 'h A', // 1:00 am
  h_mm_a = 'h:mm a', // 1:00 am
  h_mma_z = 'h:mm a z', // 1:00 am EST
  // hh_mma = '(hh:mm a)', // (01:00 am)
  hh_mma_z = 'hh:mm a z' // 01:00 am EST
  // z = 'z' // EST
}

export type ScreenSizes = 'xl' | 'lg' | '2xl' | 'sm' | 'xs' | 'md';

export enum WebViewPaths {
  BillingPage = 'billing',
  CompleteAccount = 'complete-account',
  CreateAppointment = 'appMobile',
  HealthProfile = 'health-profile',
  IntakeForm = 'intake-form',
  MigrateToTT = 'migrate-to-tt',
  Prescriptions = 'prescriptions',
  UpgradeToWM = 'upgrade-to-wm',
  TirzepatideMigration = 'tirzepatide-migration'
}

export enum InternalLinkAction {
  AskToUpgradeToTheWM = 'ask-to-upgrade-to-the-wm'
}

export enum DeepLinkAction {
  DiscountCard = 'discount-card',
  FrontDeskChannel = 'front-desk-channel',
  IntakeForm = 'intake-form',
  Labs = 'labs',
  MessageChannel = 'message-channel',
  PlanRecommendation = 'plan-recommendation',
  UnpaidInvoice = 'unpaid-invoice',
  UpdateCreditCard = 'update-credit-card',
  WMAddWeight = 'wm-add-weight',
  WMAppointmentRequired = 'wm-appointment-required',
  WMDevicesNewMeasurements = 'wm-devices-new-measurements',
  WMDevicesShipment = 'wm-devices-shipment',
  WMGuide = 'wm-guide',
  WMPaywall = 'wm-paywall',
  WMSurvey = 'wm-survey',
  WaitingRoom = 'waiting-room'
}

export enum CompleteFreemiumSteps {
  AppointmentDetails = 'appointment-details',
  AppointmentFiles = 'appointment-files',
  BookAppointment = 'book-appointment',
  Checkout = 'checkout',
  ConfirmedAppointment = 'confirmed-appointment',
  CreateUser = 'create-user',
  IntakeForm = 'intake-form',
  Intro = 'intro',
  QuickCheck = 'quick-check',
  SelectPlan = 'select-plan'
}

export enum DiscoverInsuranceResults {
  Success = 'wm-discover-result-success',
  Inactive = 'wm-discover-result-inactive',
  NotVerified = 'wm-discover-result-not-verified'
}

export enum ReviewProblemType {
  MyProvider = 'my-provider',
  UsingApp = 'using-app'
}

export enum ReviewOrigin {
  Appointment = 'appointment',
  Provider = 'provider'
}

export enum ShippingStatuses {
  // ASH statuses
  ASH_KIT_ACCESSIONED = 'ASH_KIT_ACCESSIONED', // kit is received by the lab
  ASH_KIT_DELIVERED_TO_PATIENT = 'ASH_KIT_DELIVERED_TO_PATIENT',
  ASH_KIT_FULFILLMENT_CANCELED = 'ASH_KIT_FULFILLMENT_CANCELED', // Handle same as rejected
  ASH_KIT_IN_TRANSIT_TO_LAB = 'ASH_KIT_IN_TRANSIT_TO_LAB',
  ASH_KIT_ISSUE = 'ASH_KIT_ISSUE', // Handle same as rejected
  ASH_KIT_REJECTED = 'ASH_KIT_REJECTED',
  ASH_ORDER_FULFILLED = 'ASH_ORDER_FULFILLED', // order is on the way to patient
  ASH_RESULTS_READY = 'ASH_RESULTS_READY',
  ASH_TEST_SAMPLES_RECEIVED = 'ASH_TEST_SAMPLES_RECEIVED',
  // END of ASH statuses
  // Blocked = 'Blocked',
  Delivered = 'Delivered',
  // Exception = 'Exception',
  // InProgress = 'In Progress',
  Placed = 'Placed',
  // Processing = 'Processing',
  Shipped = 'Shipped'
}

export enum EmailStatus {
  LEGACY = 'legacy',
  PENDING = 'pending',
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified'
}

// export enum OrderStatuses {
//   CANCELED = 'Canceled',
//   COMPLETED = 'Completed',
//   IN_PROGRESS = 'In Progress',
//   MISSING_INFO = 'Missing Info',
//   NEW = 'New',
//   PENDING = 'Pending',
// }

export enum MessageEvent {
  APPOINTMENT_CANCELLED = 'appointmentCancelled',
  APPOINTMENT_COMPLETE = 'appointmentComplete',
  APPOINTMENT_CREATED = 'appointmentCreated',
  APPOINTMENT_MISSED = 'appointmentMissed'
}
export enum MIFTabs {
  AllAnswers = 'All answers',
  WarningsOnly = 'Warnings only'
}

export enum TaskStatuses {
  // ACTIVE = 'Active',
  // CANCELLED = 'Cancelled',
  COMPLETED = 'Completed',
  // DELETED = 'Deleted',
  NEW = 'New'
  // OPEN = 'Open',
  // URGENT = 'URGENT'
}

export enum MessageTab {
  CARE_TEAM = 'care-team',
  FRONT_DESK = 'front-desk'
}

export enum TirzepatideMigrationPatientGroup {
  // ELIGIBLE_FOR_BRANDED = 'eligible-for-branded',
  // MAINTENANCE = 'maintenance',
  NOT_ELIGIBLE_FOR_BRANDED = 'not-eligible-for-branded'
  // UNKNOWN = 'unknown'
}

export enum TirzepatideMigrationPatientAction {
  MOVED_TO_BRANDED = 'moved-to-branded', // Handled outside of the LifeMD system
  ACCEPTED_TIRZEPATIDE_PLUS = 'accepted-tirzepatide-plus', // Based on the MIF response
  ACCEPTED_TIRZEPATIDE_PLUS_MAINTENANCE = 'accepted-tirzepatide-plus-maintenance', // Based on the MIF response
  ACCEPTED_TIRZEPATIDE_PLUS_BUNDLED = 'accepted-tirzepatide-plus-bundled', // Based on the MIF response
  REQUESTED_APPOINTMENT = 'requested-appointment', // User action. Should be sent from the front-end
  ACCEPTED_SEMAGLUTIDE = 'accepted-semaglutide', // User action. Should be sent from the front-end
  ACCEPTED_SEMAGLUTIDE_BUNDLED = 'accepted-semaglutide-bundled', // Based on the plan change
  ACCEPTED_SEMAGLUTIDE_MAINTENANCE = 'accepted-semaglutide-maintenance', // Based on the plan change
  APPOINTMENT_SCHEDULED = 'appointment-scheduled'
}

export enum AppointmentListTypes {
  DEFAULT = 0,
  MISSED_BY_PATIENT = 1,
  UPCOMING = 2,
  COMPLETED = 3,
  CANCELED = 4,
  PAST = 5
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}
