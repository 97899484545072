import { AnimatePresence, motion } from 'framer-motion';

import { ExpandableElementProps } from './expandablePanel.types';

const VARIANTS = {
  exit: {
    opacity: 0,
    transition: {
      duration: 0.01
    }
  },
  hidden: {
    opacity: 0,
    translateY: -10
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3
    },
    translateY: 0
  }
};
const ExpandableElement: React.FC<ExpandableElementProps> = ({
  className = 'py-4 md:py-10',
  content,
  dataTestId,
  header,
  toggleContent,
  isShowContent
}) => {
  return (
    <div className={className} data-testid={dataTestId}>
      <header data-testid="accordion_item_header" aria-hidden onClick={toggleContent}>
        {header}
      </header>
      <AnimatePresence initial={false} mode="popLayout">
        {isShowContent && (
          <motion.div
            animate="visible"
            className="mt-4"
            exit="exit"
            initial="hidden"
            variants={VARIANTS}
          >
            {content}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ExpandableElement;
