import { useCreateProspectAccountMutation } from 'services/auth/auth';
import { CreateProspectAccountReqProps } from 'services/auth/auth.types';

import { selectNewAppointmentExtended } from 'store';
import { setNewAppointmentUser } from 'store/appointments/appointmentsSlice';

import { useAppDispatch, useAppSelector } from 'hooks';
import { handleRequestCatch } from 'utils/helpers';

export const useCreateProspect = () => {
  const dispatch = useAppDispatch();
  const [sendPartialData, { isLoading }] = useCreateProspectAccountMutation();
  const { user } = useAppSelector(selectNewAppointmentExtended);

  const send = ({
    data,
    cb,
    errorCb
  }: {
    cb: () => void;
    data: Partial<CreateProspectAccountReqProps>;
    errorCb?: (error: unknown) => void;
  }) => {
    const { email, firstName, lastName, termsAndConditionsAccepted } = user || {
      email: '',
      firstName: '',
      lastName: '',
      termsAndConditionsAccepted: false
    };
    sendPartialData({ email, firstName, lastName, termsAndConditionsAccepted, ...data })
      .unwrap()
      .then(() => {
        dispatch(setNewAppointmentUser(data));
        cb();
      })
      .catch((e) => {
        if (typeof errorCb === 'function') {
          errorCb(e);
        } else {
          handleRequestCatch(e as MessageEvent);
        }
      });
  };
  return {
    loading: isLoading,
    send
  };
};
