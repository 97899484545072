import { PreviewChangeSubscriptionResProps } from 'services/myAccount/myAccount.types';

import { PricePoint } from 'models/plans.types';

export const getFixedFinalPrice = (finalCost?: number) => {
  return finalCost ? `${(finalCost / 100).toFixed((finalCost / 100) % 1 !== 0 ? 2 : 0)}` : '';
};

export const getMemberShipInfoForAppointmentCard = (
  isLifeMDPlusFlow?: boolean,
  selectedPricePoint?: PricePoint | null,
  previewChangeSubscription?: PreviewChangeSubscriptionResProps,
  isChangingPlan?: boolean | null,
  isUpdatingRecommendation?: boolean,
  planName?: string
) => {
  if (
    isLifeMDPlusFlow &&
    selectedPricePoint &&
    previewChangeSubscription &&
    (isChangingPlan || isUpdatingRecommendation)
  ) {
    const qty = selectedPricePoint.paymentInterval.qty;

    return {
      paymentDue: previewChangeSubscription?.data.paymentDue,
      paymentIntervalUnits: qty === 1 ? '/mo' : `/every ${qty} mo`,
      planName: planName,
      totalCost: selectedPricePoint.totalCost,
      ...(Number(previewChangeSubscription?.data.creditUsed) && {
        creditUsed: previewChangeSubscription?.data.creditUsed
      })
    };
  }
};

export const getAppointmentCost = (
  existingPricePoint?: PricePoint | null,
  isAsync?: boolean,
  isRefillAppt?: boolean
) => {
  if (!existingPricePoint) {
    return;
  }
  if (isAsync) {
    return isRefillAppt
      ? existingPricePoint.asyncMedicationRefillCost
      : existingPricePoint.asyncAppointmentCost;
  }
  return existingPricePoint.subsequentAppointmentCost;
};
