import { RangeScaleItemProps } from '../RangeSection/rangeSection.types';

export const getRangeSections = (
  value: number,
  referenceMax?: number | null,
  referenceMin?: number | null
): RangeScaleItemProps[] => {
  if (referenceMax && referenceMin) {
    const range = referenceMax - referenceMin;
    const HighRangeValue = referenceMax + range;

    const showLowRangerMarker = value < referenceMin;
    const showMiddleRangerMarker = value >= referenceMin && value <= referenceMax;
    const showHighRangerMarker = value > referenceMax;
    return [
      {
        color: showLowRangerMarker ? 'yellow' : 'gray',
        end: referenceMin,
        isValueOutOfRange: value <= 0,
        label: 'Low',
        showRangerMarker: showLowRangerMarker,
        start: 0,
        value
      },
      {
        color: showMiddleRangerMarker ? 'blue' : 'gray',
        end: referenceMax,
        label: 'Middle',
        showRangerMarker: showMiddleRangerMarker,
        start: referenceMin,
        value
      },
      {
        color: showHighRangerMarker ? 'yellow' : 'gray',
        end: HighRangeValue,
        isValueOutOfRange: value >= HighRangeValue,
        label: 'High',
        showRangerMarker: showHighRangerMarker,
        start: referenceMax,
        value
      }
    ];
  }

  if (referenceMax || referenceMin) {
    const isResultWithMaximumIdealRange = !!referenceMax;
    const middleValue = isResultWithMaximumIdealRange ? referenceMax : referenceMin;

    if (!middleValue) return [];

    const showLowRangerMarker = isResultWithMaximumIdealRange
      ? value <= middleValue
      : value < middleValue;
    const showHighRangerMarker = isResultWithMaximumIdealRange
      ? value > middleValue
      : value >= middleValue;

    return [
      {
        color: showLowRangerMarker ? (isResultWithMaximumIdealRange ? 'blue' : 'yellow') : 'gray',
        end: middleValue,
        isValueOutOfRange: value <= 0,
        label: 'Low',
        showRangerMarker: showLowRangerMarker,
        start: 0,
        value
      },
      {
        color: showHighRangerMarker ? (!isResultWithMaximumIdealRange ? 'blue' : 'yellow') : 'gray',
        end: middleValue * 2,
        isValueOutOfRange: value >= middleValue * 2,
        label: 'High',
        showRangerMarker: showHighRangerMarker,
        start: middleValue,
        value
      }
    ];
  }
  return [];
};
