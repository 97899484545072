import { useEffect } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetSet } from 'react-use';
import { useFlag } from '@unleash/proxy-client-react';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';

import {
  useAddAppointmentMutation,
  useUploadFilesMutation
} from 'services/appointments/appointments';
import {
  useCombinedPaymentMutation,
  useCreateProspectAccountMutation,
  useHoldProviderMutation,
  useRefreshTokenMutation
} from 'services/auth/auth';
import { useGenerateDynamicLinkQueueMutation } from 'services/general/general';
import { DynamicLinkActions } from 'services/general/general.types';
import {
  useGetMembershipPlansQuery,
  useLazyGetAppointmentTypesQuery
} from 'services/lookup/lookup';
import { useSendMifResponseMutation } from 'services/mifs/mifs';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { RootState, selectMifInfo, selectNewAppointmentExtended, selectUser, store } from 'store';
import {
  clearNewAppointmentExtended,
  setNewAppointmentExtended
} from 'store/appointments/appointmentsSlice';
import { clearAppointmentMif } from 'store/mif/mifSlice';
import { clearUser, setUser } from 'store/user/userSlice';

import { Props } from 'containers/CreateAppointmentExtended/Content/content.types';
import {
  buildBodyForApptSchedule,
  defineFlowSteps
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.settings';
import {
  CareTypes,
  CREATE_APPOINTMENT_STEPS,
  PaymentStepProps,
  StepName
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';
import { notifyError, notifySuccess } from 'shared/Toast/Toast';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { DEFAULT_LAB_TESTS_APPT_CODE } from 'constants/defaults';
import { WM_INSURANCE_VALID_PRICE_POINTS } from 'constants/pricepoints';
import { useAppDispatch, useAppSelector, useQuery } from 'hooks';
import { useGetLifeMDPlusPlan } from 'hooks/useGetLifeMDPlusPlan';
import { FeatureFlag, PathName, PlanCodes } from 'utils/enums';
import { findAppointmentTypeByData, handleRequestCatch } from 'utils/helpers';
import { handlePostHogEvent } from 'utils/posthog';

import useWidth from '../useWidth';

import { AppointmentCallMethod } from 'models/appointment.types';

import {
  buildBodyForPayment,
  buildPayload,
  getNextStep
} from './useCreateAppointmentExtended.settings';

export const useCreateAppointmentExtended = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const { isMobile } = useWidth();
  const { lifeMDPlusPlan, defaultPricePoint } = useGetLifeMDPlusPlan();
  const isUpgradeToLifeMDPlusEnable = useFlag(FeatureFlag.UpgradeToLifeMDPlus);
  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const {
    activePlanCode,
    activePlanId,
    activePricePoint,
    accessToken,
    refreshToken,
    companyPartners
  } = useAppSelector(selectUser);
  const {
    membershipData,
    src,
    plans: appointmentPlans,
    mifCode,
    uploadRequired,
    labsResultID,
    predefinedAppointmentQuery,
    ...rest
  } = useAppSelector(selectNewAppointmentExtended);

  const partner = companyPartners?.onboardingPartner?.partnerName;

  const [handleRefreshToken] = useRefreshTokenMutation();
  const [getAppointmentTypes, { data: appointmentTypes, isLoading: isLoadingApptTypes }] =
    useLazyGetAppointmentTypesQuery();
  const [updateMyAccount, { isLoading: isLoadingUpdateUser }] = useUpdateMyAccountMutation();
  const [addAppointment, { isLoading: isLoadingAddAppointment }] = useAddAppointmentMutation();
  const { data: plans, isFetching: isFetchingPlans } = useGetMembershipPlansQuery();
  const [submitMif, { isLoading: isSubmittingResults }] = useSendMifResponseMutation();
  const [submitPayment, { isLoading: isLoadingSubmitPayment }] = useCombinedPaymentMutation();
  const [getMyAccount, { isFetching: isGettingMyAccount }] = useLazyGetMyAccountQuery();
  const [createAccount, { isLoading: isLoadingCreateAccount }] = useCreateProspectAccountMutation();
  const [holdProvider, { isLoading }] = useHoldProviderMutation();
  const [generateDynamicLink, { isLoading: isGeneratingLink }] =
    useGenerateDynamicLinkQueueMutation();

  const [uploadFiles] = useUploadFilesMutation();

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);
  const [steps, setSteps] = useGetSet([...CREATE_APPOINTMENT_STEPS]);

  const continueInWeb = () => {
    sessionStorage.setItem('appointmentIsScheduled', 'true');
    dispatch(clearAppointmentMif());
    moveToStep('confirmation');
  };

  const continueInApp = () => {
    const reactiveState = store.getState() as RootState;
    const apptID = reactiveState.appointments.newAppointmentExtended._id;
    if (!apptID) {
      return notifyError('Appointment is not scheduled');
    }
    generateDynamicLink({
      accessToken,
      action: DynamicLinkActions.APPOINTMENT_CONFIRMATION,
      appointmentId: apptID,
      token: reactiveState.user.expiredToken ?? ''
    })
      .unwrap()
      .then(({ data: { dynamicLink } }) => setTimeout(() => window.open(dynamicLink, '_top')))
      .catch((e) => handleRequestCatch(e, 'Please try again'));
  };

  const predefinedCategory = query.get('c') ?? '';
  const currentStep = (query.get('s') ?? '') as StepName;
  const srcFromQuery = (useQuery().get('src') as CareTypes) ?? '';
  const dateFromQuery = query.get('date') || '';
  const resultId = query.get('resultId') || '';

  const currentPlan = plans?.data.find((p) => p.planCode === activePlanCode);
  const selectedPlan = plans?.data.find(
    (p) => p._id === (membershipData?.planId ? membershipData.planId : activePlanId)
  );

  const selectedPP = selectedPlan?.pricePoints.find(
    (pp) => pp.planPricePointId === activePricePoint
  );

  const isRequiredToUpgradeToLifeMDPlus =
    isUpgradeToLifeMDPlusEnable &&
    (!activePlanCode ||
      activePlanCode === PlanCodes.FlexCare ||
      (!!appointmentPlans ? !appointmentPlans.some((p) => p.code === activePlanCode) : false));
  const isInsurancePatient =
    !!activePricePoint && WM_INSURANCE_VALID_PRICE_POINTS.includes(activePricePoint);
  const haveToPayForAppt =
    activePlanCode === PlanCodes.LifeMDPlus ||
    isRequiredToUpgradeToLifeMDPlus ||
    Number(selectedPP?.subsequentAppointmentCost) > 0 ||
    isInsurancePatient;

  const exitFlow = (to: To, options?: NavigateOptions) => {
    navigate(to, options);
    dispatch(clearNewAppointmentExtended());
  };

  const scheduleAppointment = (
    callMethod?: AppointmentCallMethod | null,
    onScheduleAppointment?: () => void,
    onRejectSchedule?: () => void
  ) => {
    const body = buildBodyForApptSchedule({
      ...rest,
      callType: callMethod || rest.callMethod,
      ...(rest.code === DEFAULT_LAB_TESTS_APPT_CODE && !!labsResultID && { resultId: labsResultID })
    });
    const scheduleAppointmentThen = () => {
      sessionStorage.setItem('appointmentIsScheduled', 'true');
      dispatch(clearAppointmentMif());
      moveToStep('confirmation');
    };

    addAppointment(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(setNewAppointmentExtended({ _id: data._id, status: 'created' }));
        if (rest.files?.length && uploadRequired) {
          const formData = new FormData();
          rest.files.forEach((file: File) => {
            formData.append('appointmentImages', file);
          });
          uploadFiles({
            appointmentId: data._id,
            body: formData
          })
            .unwrap()
            .catch(() => {
              toast.warn(
                'Appointment is scheduled successfully but there is an error with the uploading of the files'
              );
            })
            .finally(scheduleAppointmentThen);
        } else {
          scheduleAppointmentThen();
        }
      })
      .catch((e) => {
        onRejectSchedule?.();
        handleRequestCatch(e);
        moveToStep('prev');
      })
      .finally(() => onScheduleAppointment?.());
  };

  const handleSelectTime = (data: { doctorId: string; endTime: string; startTime: string }) => {
    dispatch(setNewAppointmentExtended(data));
  };

  const handleSubmitMIF = (shouldSendResults?: boolean) => {
    if (!shouldSendResults) {
      return moveToStep({ step: 'mif' });
    }
    const body = appointmentMif.map((item) => {
      if ('answer' in item) {
        const { question, answer, textAreaFields } = item;
        return {
          answer,
          question,
          ...(textAreaFields && { textAreaFields })
        };
      }
      return item;
    });

    mifCode &&
      submitMif({ body, id: mifCode })
        .unwrap()
        .then(() => {
          moveToStep({ step: 'mif' });
        })
        .catch(handleRequestCatch);
  };

  // This method is for the guest flow only - we create account, then submit mif (if needed) and then move next
  const handleCreateAccount = (password: string) => {
    const body = { ...rest.user, password, repeatPassword: password };
    createAccount(body)
      .unwrap()
      .then(({ info }) => {
        dispatch(
          setUser({
            accessToken: info?.accessToken,
            refreshToken: info?.refreshToken
          })
        );
        getMyAccount()
          .unwrap()
          .then(() => {
            if (!!mifCode) {
              handleSubmitMIF(true);
            } else {
              moveToStep({ step: 'create-account-password' });
            }
          })
          .catch(handleRequestCatch);
      })
      .catch(handleRequestCatch);
  };

  const handleNewUser = (formData: PaymentFormFields) => {
    if (!defaultPricePoint || !lifeMDPlusPlan) {
      throw new Error('LifeMD plus membership plan is not found');
    }

    const handleCreateUserThen = () => {
      const isMobileWebView = sessionStorage.getItem('mobile-webview') === 'true';
      if (isMobile && isMobileWebView) {
        continueInApp();
      } else {
        continueInWeb();
      }
    };
    const body = buildBodyForPayment({
      data: { ...rest, accessToken },
      defaultPricePoint,
      formData,
      lifeMDPlusPlan
    });

    submitPayment(body)
      .unwrap()
      .then(({ data }) => {
        dispatch(setUser({ ...data.userData, elationId: String(data.userData.elationId) }));
        dispatch(setNewAppointmentExtended({ _id: data.appointmentData._id, status: 'created' }));
        if (rest.files?.length && uploadRequired) {
          const formData = new FormData();
          rest.files.forEach((file: File) => {
            formData.append('appointmentImages', file);
          });
          uploadFiles({
            appointmentId: data.appointmentData._id,
            body: formData
          })
            .unwrap()
            .catch(() => {
              toast.warn(
                'Appointment is scheduled successfully but there is an error with the uploading of the files'
              );
            })
            .finally(handleCreateUserThen);
        } else {
          handleCreateUserThen();
        }
      })
      .catch((e) => {
        if (e?.status === 401 && !!refreshToken) {
          handleRefreshToken({ isAuthorized: true, token: refreshToken })
            .unwrap()
            .then(() => handleNewUser(formData))
            .catch((e) => {
              handleRequestCatch(e, 'Session expired, please login and try again');
              dispatch(clearNewAppointmentExtended());
              dispatch(clearUser());
              navigate({ pathname: PathName.Login }, { replace: true });
            });
        } else {
          handleRequestCatch(
            e,
            !!refreshToken
              ? 'Can not connect your card'
              : 'Session expired, please login and try again'
          );
          handlePostHogEvent('purchase_failed');
          if (!refreshToken) {
            dispatch(clearUser());
            dispatch(clearNewAppointmentExtended());
            navigate({ pathname: PathName.Login }, { replace: true });
          }
        }
      });
  };

  const handleUpgradePlan = (data: PaymentStepProps) => {
    if (!isRequiredToUpgradeToLifeMDPlus && !data.isNewUser) {
      return scheduleAppointment();
    }
    if (data.isNewUser) {
      handleNewUser(data.formData);
    } else {
      updateMyAccount({
        planId: membershipData?.planId,
        ...(!!membershipData?.planPricePoint && {
          planPricePointId: membershipData?.planPricePoint?.planPricePointId
        })
      })
        .unwrap()
        .then(() => {
          scheduleAppointment(
            null,
            () => {
              dispatch(
                setUser({
                  activePlanId: selectedPlan?._id,
                  activePricePoint: membershipData?.planPricePoint?.planPricePointId
                })
              );
            },
            () => notifySuccess('Your plan is changed successfully')
          );
        });
    }
  };

  const moveToStep: Props['moveToStep'] = (type, extraSearch = ''): void | Promise<void> => {
    const reactiveApptState = (store.getState() as RootState).appointments?.newAppointmentExtended;
    const reactiveUserState = (store.getState() as RootState).user;
    const isAsyncEnabledForTheUser =
      reactiveUserState.features?.offerAsyncAppointments.status === 'enabled';
    const isQualifiedForAsyncAppointment = reactiveApptState?.isQualifiedForAsyncAppointment;
    const additionalSearch = extraSearch ? '&' + extraSearch : '';
    const currentStepIndex = steps().indexOf(currentStep);
    // if we need to store some data in redux, let's do it here
    const payload = buildPayload(type);
    !!payload && dispatch(setNewAppointmentExtended(payload));
    const basicExitPath = !!reactiveUserState.accessToken ? PathName.Home : PathName.Login;
    const shouldAllowToSeeAsyncSelectScreen =
      !isInsurancePatient &&
      !!isAsyncEnabledForTheUser &&
      !!reactiveApptState.asyncAllowed &&
      isQualifiedForAsyncAppointment !== false; // don't change. If value is null, it means that user didn't fill mif at all (suitable).

    // when arg type is an object, then we'll have conditional next step, so below is the logic to define it
    if (typeof type === 'object') {
      const nextStep = getNextStep(type, {
        accessToken: reactiveUserState.accessToken,
        careProviders: reactiveApptState.careProviders,

        code: reactiveApptState?.code,

        hasPredefinedAppointmentType: !!predefinedAppointmentQuery,

        // And if it's true, then user doesn't have any DQ during mif
        haveToPayForAppt,

        isRequiredToUpgradeToLifeMDPlus,
        shouldAllowToSeeAsyncSelectScreen,
        shouldAskDetails: !!reactiveApptState?.shouldAskDetails,
        src: srcFromQuery || src,
        uploadRequired
      });

      if (nextStep === 'shop') {
        return navigate({ pathname: PathName.Shop });
      }
      if (nextStep === 'mif') {
        dispatch(clearAppointmentMif());
      }

      // trying to check if we can schedule appointment right now
      if (!haveToPayForAppt) {
        let canScheduleRightNow = false;
        if (
          type.step === 'upload-files' &&
          !rest.shouldAskDetails &&
          !shouldAllowToSeeAsyncSelectScreen
        ) {
          canScheduleRightNow = true;
        }
        // this condition is for the future. Right now only LifeMD appointments may have details-for-provider step
        if (
          type.step === 'details-for-provider' &&
          reactiveApptState.careProviders?.includes('SteadyMD')
        ) {
          canScheduleRightNow = true;
        }
        if (
          type.step === 'appointment-types-picker' &&
          !type.data.mifCode &&
          !type.data.uploadRequired &&
          !type.data.shouldAskDetails &&
          !type.data.asyncAllowed &&
          !isAsyncEnabledForTheUser &&
          type.data.careProviders?.includes('SteadyMD') // otherwise we go to the date-time step first
        ) {
          canScheduleRightNow = true;
        }
        if (type.step === 'qualified-for-async' && type.data.callMethod === 'message') {
          canScheduleRightNow = true;
        }
        if (type.step === 'date-time') {
          canScheduleRightNow = true;
        }
        if (
          type.step === 'mif' &&
          !uploadRequired &&
          !rest.shouldAskDetails &&
          !shouldAllowToSeeAsyncSelectScreen &&
          reactiveApptState.careProviders?.includes('SteadyMD')
        ) {
          canScheduleRightNow = true;
        }
        // hide because now after this step we always go to the payment
        // if (type.step === 'pre-checkout' && !haveToPayForAppt) {
        // canScheduleRightNow = true;
        // }
        if (canScheduleRightNow) {
          return scheduleAppointment();
        }
      }

      if (type.step === 'payment-checkout') {
        return;
      }

      if (type.step === 'date-time' && !activePlanCode) {
        const body = {
          appointmentTime: {
            endTime: rest.endTime,
            startTime: rest.startTime
          },
          appointmentTypeId: rest.appointmentTypeId,
          doctorId: rest.doctorId,
          planId: membershipData?.planId,
          sessionId: nanoid(),
          state: rest.user?.address?.state ?? '',
          timezone: dayjs.tz.guess()
        };
        return holdProvider(body)
          .unwrap()
          .then(({ data }) => {
            dispatch(setNewAppointmentExtended({ bookedSlotId: data.bookedSlotId }));
            const indexOfCurrentStep = steps().indexOf(type.step);
            const indexOfTheNextStep = !!nextStep ? steps().indexOf(nextStep) : 0;
            toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
            return nextStep
              ? navigate({ search: `s=${nextStep}${additionalSearch}` })
              : exitFlow({ pathname: basicExitPath });
          });
      }

      const indexOfCurrentStep = steps().indexOf(type.step);
      const indexOfTheNextStep = !!nextStep ? steps().indexOf(nextStep) : 0;
      toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
      return nextStep
        ? navigate({ search: `s=${nextStep}${additionalSearch}` })
        : exitFlow({ pathname: basicExitPath });
    } else if (type === 'prev') {
      toggleBackAnimation(true);
      return navigate(-1);
    } else if (type === 'next') {
      toggleBackAnimation(false);
      let nextStep = steps()[currentStepIndex + 1];
      return nextStep
        ? navigate({ search: `s=${nextStep}${additionalSearch}` })
        : exitFlow({ pathname: basicExitPath });
    } else {
      if (!steps().includes(type)) {
        toggleBackAnimation(true);
        notifyError('Something went wrong, please try again');
        return navigate({ search: `s=${steps()[0]}` }, { replace: true });
      }
      toggleBackAnimation(false);
      navigate(
        { search: `s=${type}${additionalSearch}` },
        {
          replace: type === 'confirmation'
        }
      );
    }
  };

  const isQualifiedForAsyncAppointment = (store.getState() as RootState).appointments
    ?.newAppointmentExtended?.isQualifiedForAsyncAppointment;

  const onInit = async () => {
    try {
      if (srcFromQuery) {
        const correctSRCOptions: CareTypes[] = [
          'prescriptions',
          'talk-to-a-doctor',
          'shop-labs',
          'labs-appt'
        ];
        dispatch(
          setNewAppointmentExtended({
            src: correctSRCOptions.includes(srcFromQuery) ? srcFromQuery : 'talk-to-a-doctor'
          })
        );
      }
      if (resultId) {
        dispatch(setNewAppointmentExtended({ labsResultID: resultId }));
      }

      const [appointmentsResponse] = await Promise.all([
        getAppointmentTypes({
          ...(!!partner && { partner }),
          accessToken,
          skipAuthFilters: !!accessToken
        }).unwrap(),
        ...(accessToken ? [getMyAccount().unwrap()] : [])
      ]);

      const predefinedApptType = findAppointmentTypeByData(
        appointmentsResponse.data,
        predefinedCategory
      );
      if (predefinedApptType) {
        dispatch(
          setNewAppointmentExtended({
            ...predefinedApptType,
            appointmentTypeId: predefinedApptType._id,
            predefinedAppointmentQuery: predefinedCategory,
            src: 'talk-to-a-doctor',
            status: 'pending'
          })
        );
      }

      if (!accessToken) {
        setSteps([...CREATE_APPOINTMENT_STEPS]);
        if (!currentStep || !CREATE_APPOINTMENT_STEPS.includes(currentStep)) {
          navigate(
            { search: `s=${CREATE_APPOINTMENT_STEPS[predefinedApptType ? 1 : 0]}` },
            { replace: true }
          );
          !predefinedApptType && dispatch(clearNewAppointmentExtended());
        }
        return;
      }

      if (
        (currentStep !== 'confirmation' && rest.status === 'created') ||
        currentStep === 'choose-type-of-care'
      ) {
        dispatch(clearNewAppointmentExtended());
      }
      if (dateFromQuery) {
        dispatch(setNewAppointmentExtended({ initialDate: dateFromQuery }));
      }
      const filteredSteps: StepName[] = defineFlowSteps(CREATE_APPOINTMENT_STEPS, {
        haveToPayForAppt,
        isExistingPatient: !!accessToken,
        isInsurancePatient,
        isRequiredToUpgradeToLifeMDPlus
      });
      setSteps(filteredSteps);
      if (
        !currentStep ||
        (!filteredSteps.includes(currentStep) && currentStep !== 'create-account-password')
      ) {
        navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
        !predefinedApptType && dispatch(clearNewAppointmentExtended());
      }
    } catch (e) {
      if (e instanceof Error && 'status' in e && e.status === 500) {
        type ErrorResponseType = {
          data: { errorCode: number; message: string };
          status: number;
        };
        const error = e as unknown as ErrorResponseType;
        notifyError(error?.data?.message, false, {
          toastId: 'getting-user-data'
        });
        exitFlow({ pathname: PathName.BillingDetails }, { replace: true });
      } else if (e instanceof Error && 'status' in e) {
        notifyError(e.message);
      } else throw e;
    }
  };

  useEffect(() => {
    const init = async () => await onInit();
    init();
  }, []);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
    if (rest.status === 'created' && currentStep !== 'confirmation') {
      dispatch(clearNewAppointmentExtended());
      navigate(PathName.GetCare, { replace: true });
    }
    if (!!accessToken && currentStep.includes('create-account')) {
      notifySuccess('Your account is created successfully');
      moveToStep({
        step: 'create-account-password'
      });
    }
    if (isQualifiedForAsyncAppointment === false && currentStep === 'qualified-for-async') {
      moveToStep({ data: { callMethod: 'video' }, step: 'qualified-for-async' });
    }
    if (!currentStep && !predefinedCategory) {
      navigate({ search: 's=choose-type-of-care' }, { replace: true });
    }
  }, [currentStep, predefinedCategory]);

  return {
    appointmentTypes: appointmentTypes?.data ?? [],
    currentPlan,
    currentStep,

    defaultPlanId:
      isUpgradeToLifeMDPlusEnable && !rest.careProviders?.includes('SteadyMD')
        ? lifeMDPlusPlan?._id
        : undefined,

    exitFlow,

    handleCreateAccount,

    handleSelectTime,

    handleSubmitMIF,

    handleUpgradePlan,

    haveToPayForAppt,

    initialLoading: isGettingMyAccount || isFetchingPlans || isLoadingApptTypes,

    isBackAnimation: isBackAnimation(),

    isRequiredToUpgradeToLifeMDPlus,
    // is only for initial loading (to hide rest of the screen or make it's disabled before data is loaded)
    //loading for the loading spinner
    loading:
      isLoadingUpdateUser ||
      isLoadingAddAppointment ||
      isLoadingApptTypes ||
      isLoadingCreateAccount ||
      isSubmittingResults ||
      isLoadingSubmitPayment ||
      isLoading ||
      isGeneratingLink,
    moveToStep,
    selectedPlan,
    steps: steps()
  };
};
