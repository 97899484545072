import { AppointmentTypeItem } from 'models/appointment.types';

export const setAppointmentOptions = (
  onlyFeatured: boolean,
  appointmenttypes?: AppointmentTypeItem[],
  searchKey?: string
) => {
  return (
    appointmenttypes
      ?.filter((type) => !onlyFeatured || type.featured)
      ?.filter(
        (type) => !searchKey || type.displayName.toLowerCase().includes(searchKey.toLowerCase())
      )
      ?.map((type) => ({
        data: type,
        description: type?.subtitle,
        label: type.displayName,
        value: type._id
      })) ?? []
  );
};
