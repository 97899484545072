import { Common } from '@thecvlb/design-system';

import Collapse from 'shared/Collapse';

import { FAQProps } from './faq.types';

const FAQ: React.FC<FAQProps> = ({ isOpen, onClose, type }) => {
  const getContent = () => {
    return [
      ...(type === 'eligible'
        ? [
            {
              content: (
                <>
                  Custom compounding involves creating medications by altering, mixing, or combining
                  ingredients to tailor them to the specific needs of an individual patient. These
                  compounded medications offer a level of personalization that standard prescription
                  drugs do not provide. Patients should only use compounded medications when
                  prescribed by a licensed health care provider. Compounding pharmacies must use
                  pharmaceutical-grade ingredients sourced from FDA-registered facilities when
                  creating compounded medications.
                </>
              ),
              title: 'What is custom compounding?'
            },
            {
              content:
                'Your provider will be the best source for details on the specific formulation prescribed to you and can walk you through your questions.',
              title: 'What ingredients are in the custom Tirzepatide compound?'
            },
            {
              content: 'No, the cost of compounded tirzepatide will remain the same at this time.',
              title: 'Will there be a price change for the new compounded option?'
            }
          ]
        : [
            {
              content:
                'On December 19, 2024 the U.S. Food and Drug Administration announced that the shortage of tirzepatide injection, a glucagon-like peptide 1 (GLP-1) medication, has been resolved. Tirzepatide is the active ingredient in the weight-loss medication Zepbound® and the diabetes medication Mounjaro®. This announcement also confirmed timelines for when pharmacies can no longer supply the essential copy of tirzepatide.',
              title: 'What did the FDA announce?'
            },
            {
              content: (
                <>
                  Both semaglutide and tirzepatide are medications used to improve blood sugar
                  control in adults with type 2 diabetes and to aid in weight loss for those who are
                  obese or overweight.
                  <br />
                  <br />
                  Tirzepatide works by activating both GLP-1 and GIP receptors, helping to enhance
                  insulin release and promote weight loss. Semaglutide targets the GLP-1 receptor,
                  which stimulates insulin and inhibits glucagon release — also aiding in appetite
                  control. Both medications are administered through subcutaneous injections using a
                  pen-like device, typically once a week. They also share common side effects.
                  <br />
                  <br />
                  The dosing and titration schedules do differ between the medications. Your
                  provider will ensure you are prescribed the appropriate dose based on your current
                  prescribed dosage of tirzepatide.
                </>
              ),
              title: 'What is the difference between semaglutide and tirzepatide?'
            },
            {
              content:
                'Yes, switching from tirzepatide to semaglutide is safe, but it should always be done under the guidance of a licensed healthcare provider.',
              title: 'Is it safe to switch from tirzepatide to semaglutide?'
            },
            ...(type === 'not-eligible-care-only'
              ? [
                  {
                    content:
                      'As you are currently on a subscription plan that does not include the cost of medication, there will be no difference in subscription cost as a result of this change. While costs can vary based on dosage and titration, compounded semaglutide typically has a lower “out of pocket” cost compared to compounded tirzepatide. Therefore, your overall out-of-pocket cost is expected to decrease going forward.',
                    title: 'Is there a cost difference?'
                  }
                ]
              : [])
          ])
    ];
  };

  return (
    <Common.Modal close={onClose} isOpen={isOpen} size="sm" title="FAQs">
      {getContent().map((item) => (
        <Collapse key={item.title} title={item.title}>
          {item.content}
        </Collapse>
      ))}
      <Common.Button
        className="mt-6 md:hidden"
        color="white-alt"
        fullWidthOnMobile
        onClick={onClose}
      >
        Close
      </Common.Button>
    </Common.Modal>
  );
};

export default FAQ;
