import { useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { SortOrder } from 'utils/enums';

export interface SortConfig {
  direction: SortOrder;
  key: string;
}

export const useSortableData = (
  items?: { [key: string]: string | number }[],
  config: SortConfig | null = null
) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | null>(config);

  const sortedItems = useMemo(() => {
    if (!items) {
      return;
    }
    const sortableItems = [...items];
    if (sortConfig !== null) {
      if (sortConfig.key === 'date') {
        sortableItems.sort((a, b) =>
          sortConfig.direction === 'ASC'
            ? dayjs(a[sortConfig.key]).diff(b[sortConfig.key])
            : dayjs(b[sortConfig.key]).diff(a[sortConfig.key])
        );
      } else {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ASC' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ASC' ? 1 : -1;
          }
          return 0;
        });
      }
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: string) => {
    const isASC = sortConfig && sortConfig.key === key && sortConfig.direction === SortOrder.ASC;
    const direction = isASC ? SortOrder.DESC : SortOrder.ASC;
    setSortConfig({ direction, key });
    return direction;
  };

  return { items: sortedItems, requestSort };
};
