import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { useFlag } from '@unleash/proxy-client-react';
import {
  ConnectionChangePayload,
  ConnectionState,
  ParticipantPropertiesPayload,
  VideoCapturingState
} from '@zoom/videosdk';
import classNames from 'classnames';

import {
  useLazyGetAppointmentQuery,
  useUpdateCallErrorsMutation
} from 'services/appointments/appointments';
import { UpdateCallErrorsReqProps } from 'services/appointments/appointments.types';
import {
  useLazyGetProviderQuery,
  useLazyGetProviderReviewsQuery
} from 'services/providers/providers';
import { GetReviewResProps } from 'services/providers/providers.types';

import { selectUser } from 'store';

import ConfirmToRefresh from 'modals/ConfirmToRefresh';
import FeedbackReview from 'modals/FeedbackReview';
import PlatformReview from 'modals/PlatformReview';
import ProviderReview from 'modals/ProviderReview';
import { notifyError } from 'shared/Toast/Toast';
import Captions from 'widgets/zoom/Captions';
import ChatSidebar from 'widgets/zoom/ChatSidebar';
import Controls from 'widgets/zoom/Controls';
import Header from 'widgets/zoom/Header';
import LocalParticipant from 'widgets/zoom/LocalParticipant';
import RemoteParticipants from 'widgets/zoom/RemoteParticipants';

import { useAppSelector, useZoomCall } from 'hooks';
import { useRouteMatch } from 'hooks/useRouteMatch';
import useWidth from 'hooks/useWidth';
import { FeatureFlag, PathName, ReviewOrigin } from 'utils/enums';
import { isUpgradeToAnnualAlreadyShown } from 'utils/helpers';

import { handleDevicePermissionChange } from './room.settings';

const Room = () => {
  const ref = useRef<HTMLDivElement>(null);
  const refRoom = useRef<HTMLDivElement>(null);
  const {
    appointment,
    room,
    isMinimize,
    isOpenChat,
    isFullscreen,
    participantDisconnected,
    loadingRoom,
    setAppointment,
    resetValuesToDefault,
    loggerClient,
    toggleVideoEnabled,
    toggleParticipantDisconnected,
    setShowControls
  } = useZoomCall();

  const [isOpenPlatformReview, toggleOpenPlatformReview] = useToggle(false);
  const [isOpenProviderReview, toggleOpenProviderReview] = useToggle(false);
  const [isOpenFeedbackReview, toggleOpenFeedbackReview] = useToggle(false);
  const [isOpenConfirmToRefresh, toggleIsOpenConfirmToRefresh] = useToggle(false);

  const isAnnualPlanUpgradePageFeature = useFlag(FeatureFlag.AnnualPlanUpgradePage);
  const { isMobile } = useWidth();
  const { isAnnualMembership, isUnlimitedPlan, userId, reviewedProviders } =
    useAppSelector(selectUser);
  const navigate = useNavigate();
  const [getAppointment] = useLazyGetAppointmentQuery();
  const [getReview, { data: reviewData }] = useLazyGetProviderReviewsQuery();
  const [getProvider, { data: providerData }] = useLazyGetProviderQuery();
  const [updateCallErrors] = useUpdateCallErrorsMutation();

  const provider = providerData?.data;
  const providerId = appointment?.doctorId;
  const reviewsTotalCount = (reviewData?.info as GetReviewResProps['info'])?.totalCount ?? 0;
  const isRoom = room && !(isOpenProviderReview || isOpenPlatformReview || isOpenFeedbackReview);
  const isZoomCall = useRouteMatch(PathName.ZoomCall)?.isExact;
  const stream = room?.getMediaStream();
  const [providerForReview, setProviderForReview] = useState({
    displayName: '',
    profileImage: '',
    userId: ''
  });
  const navigateToUpgradeAnnualOrDashboard = () => {
    navigate(
      isAnnualPlanUpgradePageFeature &&
        appointment?.availableReview &&
        !isAnnualMembership &&
        !isUnlimitedPlan &&
        !isUpgradeToAnnualAlreadyShown(userId)
        ? PathName.UpgradeToAnnualPlan
        : PathName.Home
    );
  };

  const handleLogout = async () => {
    if (
      appointment?.appointmentStatus === 'completed' &&
      Number(provider?.rating) > 4.5 &&
      Number(reviewsTotalCount) > 100
    ) {
      toggleOpenPlatformReview();
    } else if (
      appointment?.appointmentStatus === 'completed' &&
      providerId &&
      (!reviewedProviders?.length || !reviewedProviders.includes(providerId))
    ) {
      toggleOpenProviderReview();
    } else {
      isZoomCall && navigate(PathName.Home);
    }

    loggerClient?.reportToGlobalTracing();
    resetValuesToDefault();
  };

  const handleUpdatedAppointment = () => {
    providerId &&
      !providerData?.data &&
      getProvider({
        providerId,
        shouldUpdateState: false
      });

    providerId && !reviewsTotalCount && getReview({ limit: 1, providerId });

    if (appointment?.appointmentStatus === 'completed' && participantDisconnected) {
      handleLogout();
    }
  };

  const handleSubmitClosePlatformReview = () => {
    toggleOpenPlatformReview();
    toggleOpenFeedbackReview();
  };

  const handleSubmitCloseProviderReview = () => {
    toggleOpenProviderReview();
    toggleOpenFeedbackReview();
  };

  const handleSkipProviderReview = () => {
    toggleOpenProviderReview();
    navigateToUpgradeAnnualOrDashboard();
  };

  const handleCloseFeedbackReview = () => {
    toggleOpenFeedbackReview();
    navigateToUpgradeAnnualOrDashboard();
  };

  const handleSkipPlatformReview = () => {
    toggleOpenPlatformReview();
    navigateToUpgradeAnnualOrDashboard();
  };

  const handleUpdatedParticipantDisconnected = () => {
    if (!participantDisconnected || !appointment?._id) return;
    getAppointment(appointment._id)
      .unwrap()
      .then(({ data }) => setAppointment(data));
  };

  const handleRoomDisconnected = (event: UpdateCallErrorsReqProps['event'], message?: string) => {
    if (!appointment?._id) return;
    updateCallErrors({
      appointmentId: appointment._id,
      event,
      message
    });
  };

  const handleConnectionChange = (payload: ConnectionChangePayload) => {
    payload.reason && notifyError(payload.reason);

    switch (payload.state) {
      case ConnectionState.Closed:
        handleRoomDisconnected('disconnected', payload.reason);
        handleLogout();
        break;
      case ConnectionState.Reconnecting:
        handleRoomDisconnected('reconnecting', payload.reason);
        break;
      case ConnectionState.Fail:
        handleLogout();
        break;
    }
  };

  const handleActiveMediaFailed = () => toggleIsOpenConfirmToRefresh(true);

  const handleUserRemoved = (payload: Array<ParticipantPropertiesPayload>) => {
    if (!payload.length) return;

    const user = payload.find((el) => el.userId !== room?.getCurrentUserInfo()?.userId);

    if (!user) return;

    payload?.forEach((participant) => {
      const videoElement = document.querySelector('#remote-video') as HTMLCanvasElement;
      stream?.stopRenderVideo(videoElement, participant?.userId);
    });

    const noRemoteParticipant = !room
      ?.getAllUser()
      .find((el) => el.userId !== room?.getCurrentUserInfo().userId);
    noRemoteParticipant && toggleParticipantDisconnected(true);
  };
  const handleVideoCapturingChange = (payload: { state: VideoCapturingState }) =>
    payload.state === VideoCapturingState.Stopped && toggleVideoEnabled(false);

  useEffect(handleUpdatedAppointment, [appointment]);

  useEffect(handleUpdatedParticipantDisconnected, [participantDisconnected, appointment]);

  useEffect(() => {
    room?.on('connection-change', handleConnectionChange);
    room?.on('device-permission-change', handleDevicePermissionChange);
    room?.on('active-media-failed', handleActiveMediaFailed);
    room?.on('user-removed', handleUserRemoved);
    room?.on('video-capturing-change', handleVideoCapturingChange);
    return () => {
      room?.off('connection-change', handleConnectionChange);
      room?.off('device-permission-change', handleDevicePermissionChange);
      room?.on('active-media-failed', handleActiveMediaFailed);
      room?.off('user-removed', handleUserRemoved);
      room?.off('video-capturing-change', handleVideoCapturingChange);
    };
  }, [room]);

  useEffect(() => {
    !isZoomCall && loadingRoom && handleLogout();
  }, [isZoomCall, loadingRoom]);

  useEffect(() => {
    if (!room) return;
    let timeout: ReturnType<typeof setTimeout>;

    const handleSetShowControls = () => {
      setShowControls(true);
      clearTimeout(timeout);
      timeout = setTimeout(() => setShowControls(false), 3000);
    };

    handleSetShowControls();

    refRoom.current?.addEventListener('mousemove', handleSetShowControls);
    refRoom.current?.addEventListener('touchstart', handleSetShowControls);

    return () => {
      refRoom.current?.removeEventListener('mousemove', handleSetShowControls);
      refRoom.current?.removeEventListener('touchstart', handleSetShowControls);
    };
  }, [refRoom.current, room]);

  useEffect(() => {
    if (!appointment) return;
    setProviderForReview({
      displayName: appointment.staffName,
      profileImage: appointment.staffImage ?? '',
      userId: appointment.doctorId ?? ''
    });
  }, [appointment]);

  const zoomCallRoomContentClassName = classNames('overflow-hidden aspect-video', {
    'absolute z-30 md:rounded-2xl pointer-events-none left-5 top-2.5 z-20 h-[56px] max-w-[76px] rounded-xl shadow':
      isMobile && isOpenChat,
    'md:max-w-[900px] m-auto': !isFullscreen,
    'relative z-10 h-full md:h-auto shadow-2xl rounded-2xl': !isMobile || !isOpenChat,
    'w-[205px] md:!h-fit': isMinimize,
    'w-full': !isMinimize
  });

  return (
    <>
      <ConfirmToRefresh isOpen={isOpenConfirmToRefresh} onClose={toggleIsOpenConfirmToRefresh} />
      <ProviderReview
        isOpen={isOpenProviderReview}
        provider={providerForReview}
        onClose={handleSubmitCloseProviderReview}
        onSkip={handleSkipProviderReview}
      />
      <PlatformReview
        isOpen={isOpenPlatformReview}
        origin={ReviewOrigin.Appointment}
        onClose={handleSubmitClosePlatformReview}
        onSkip={handleSkipPlatformReview}
      />
      <FeedbackReview
        isOpen={isOpenFeedbackReview}
        origin={ReviewOrigin.Appointment}
        onClose={handleCloseFeedbackReview}
      />
      {isRoom && (
        <Draggable
          allowAnyClick={true}
          axis={isMinimize ? 'both' : 'none'}
          bounds="parent"
          disabled={isMobile && isOpenChat}
          nodeRef={ref as React.RefObject<HTMLElement>}
        >
          <div
            className={classNames('fixed z-40 flex', {
              'bottom-5 left-5': isMinimize,
              hidden: loadingRoom,
              'left-0 top-0 h-full w-full bg-black/70': !isMinimize
            })}
            ref={ref}
          >
            <Header />
            <div className={zoomCallRoomContentClassName} ref={refRoom}>
              <RemoteParticipants />
              <div className={isMinimize ? '' : 'relative'}>
                <LocalParticipant />
                <Captions />
                <Controls handleLogout={handleLogout} />
              </div>
            </div>
            <ChatSidebar />
          </div>
        </Draggable>
      )}
    </>
  );
};

export default Room;
