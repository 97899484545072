import { Common } from '@thecvlb/design-system/lib/src';

import { PricePoint } from 'models/plans.types';

import WmLoseGraph from 'assets/images/signUp/chart-lose-weight.svg';

interface TTWeightGraphAndInfoProps {
  discountPrice?: string;
  pricePoint?: PricePoint;
  regularPrice?: string;
}

const TTWeightGraphAndInfo: React.FC<TTWeightGraphAndInfoProps> = ({
  discountPrice,
  regularPrice,
  pricePoint
}) => {
  return (
    <div>
      <div className="flex w-full flex-col rounded-2xl bg-white shadow">
        <div className="flex flex-col gap-3 p-4">
          <div>
            <img alt="Weight loss graph" className="w-full max-md:h-[143px]" src={WmLoseGraph} />
          </div>
          <h4 className="text-mXl font-bold">Triple Therapy</h4>
          <span className="text-mSm">
            Three medications, combined in carefully selected doses, with the goal of supporting
            your weight-loss goals.
          </span>
        </div>
        <div className="h-px w-full bg-gray-100" />
        <div className="flex flex-col gap-1 p-4">
          <span className="text-mXs text-gray-900">Get started</span>
          <p>
            <span className="mb-1 block text-m2xl text-primary md:text-2xl">
              <span className="font-bold">{discountPrice}</span> for your first month
            </span>
            <span className="block font-semibold text-gray">{regularPrice} thereafter</span>
          </p>
        </div>
        <div className="h-px w-full bg-gray-100" />
        <div className="flex flex-col gap-2.5 p-4">
          <h3 className="mb-0.5 text-mBase font-bold text-primary-700">What’s included:</h3>
          {!!pricePoint?.additionalInfo?.length &&
            pricePoint.additionalInfo.map((a) => (
              <div className="flex gap-2 text-primary-700" key={a.question}>
                <Common.Icon className="flex-none" name="check" />
                <span className="text-mSm md:text-sm">{a.question}</span>
              </div>
            ))}
        </div>
      </div>
      {/* <span className="mt-3 block text-center text-m2xs text-gray">
        *At-home testing may not be available in all regions and will incur an additional charge if
        elected.
      </span> */}
      <span className="mt-2 block text-center text-m2xs text-gray">
        *If you do not qualify for treatment, and have accurately provided your BMI, completed
        required lab tests, and attended your appointments, you will receive a full refund.*
      </span>
    </div>
  );
};

export default TTWeightGraphAndInfo;
