import { lazy, Suspense } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { AnimatePresence } from 'framer-motion';

import {
  selectDocumentsAndLinksStatus,
  selectHealthProfileStatus,
  selectLabsAndImagingStatus,
  selectMessagingStatus,
  selectMigrateToBundlePlanStatus,
  selectReOnboardingWMStatus,
  selectUser
} from 'store';

import AccountInfo from 'pages/AccountSettings/AccountInfo';
import BillingDetails from 'pages/AccountSettings/BillingDetails';
import ChangePassword from 'pages/AccountSettings/ChangePassword';
import Insurance from 'pages/AccountSettings/Insurance';
import Notifications from 'pages/AccountSettings/Notifications';
import SignInAndSecurity from 'pages/AccountSettings/SignInAndSecurity/SignInAndSecurity';
import Appointment from 'pages/Appointment';
import AppointmentMif from 'pages/AppointmentMif';
import ChangePlan from 'pages/ChangePlan';
import Checkout from 'pages/Checkout';
import KrogerConnectID from 'pages/KrogerConnectID';
import KrogerLocations from 'pages/KrogerLocations';
import LabResults from 'pages/Labs/components/LabResults';
import HippaAgreement from 'pages/Legal/HippaAgreement';
import MedicaidWaiver from 'pages/Legal/MedicaidWaiver';
// import CreateAppointment from 'pages/CreateAppointment';
import Messages from 'pages/Messages';
import MigrateFromTirzepatide from 'pages/MigrateFromTirzepatide';
import NotFound from 'pages/NotFound';
import PaymentMethod from 'pages/PaymentMethod';
import Cart from 'pages/shop/Cart';
import ShopCheckout from 'pages/shop/Checkout';
import ShopHome from 'pages/shop/Home';
import Order from 'pages/shop/Order';
import OrdersHistory from 'pages/shop/OrdersHistory';
import Product from 'pages/shop/Product';
import Products from 'pages/shop/Products';
import Address from 'pages/shop/ShippingAddress';
import Shop from 'pages/shop/Shop';
import UpgradePlan from 'pages/UpgradePlan';
import UpgradeToAnnualPlan from 'pages/UpgradeToAnnualPlan';

import CreateAppointmentExtended from 'containers/CreateAppointmentExtended';
import MigrateToTT from 'containers/MigrateToTT';
import MigrateToWM from 'containers/MigrateToWM';
import PostOnboarding from 'containers/PostOnboarding';
import Loader from 'shared/Loader';

import { SIGNUP_PATH } from 'constants/onboarding';
import { FrontDeskProvider } from 'contexts/frontDeskContext/frontDeskContext';
import { ZoomCallProvider } from 'contexts/zoomCallContext/zoomCallContext';
import { useAppSelector } from 'hooks';
import useFreemium from 'hooks/useFreemium';
import usePartnerPatient from 'hooks/usePartnerPatient';
import useWeightManagement from 'hooks/useWeightManagement';
import socketFrontDesk from 'socket/socketFrontDesk';
import { FeatureFlag, InternalLinkAction, PathName } from 'utils/enums';
import { lazyRetry } from 'utils/helpers';

import Router, { SignupRouter } from './Router';
import { RouteScrollTop } from './ScrollToTop';

const HealthProfile = lazy(() => lazyRetry(() => import('pages/HealthProfile')));
const AccountSettings = lazy(() => lazyRetry(() => import('pages/AccountSettings')));
const Login = lazy(() => lazyRetry(() => import('pages/Login')));
const SignUp = lazy(() => lazyRetry(() => import('containers/SignUp')));
const UnsupportedAge = lazy(() => lazyRetry(() => import('pages/UnsupportedAge')));
const Home = lazy(() => lazyRetry(() => import('pages/Home')));
const Care = lazy(() => lazyRetry(() => import('pages/GetCare')));
const Main = lazy(() => lazyRetry(() => import('layouts/Main')));
const PrescriptionStatus = lazy(() => lazyRetry(() => import('pages/PrescriptionStatus')));
const DeviceStatus = lazy(() => lazyRetry(() => import('pages/DeviceStatus')));
const AddMeasurement = lazy(() => lazyRetry(() => import('pages/AddMeasurement')));
const CompleteAccount = lazy(() => lazyRetry(() => import('containers/CompleteAccount')));
const CreateNewPassword = lazy(() => lazyRetry(() => import('pages/CreateNewPassword')));
const ForgotPassword = lazy(() => lazyRetry(() => import('pages/ForgotPassword')));
// const CreateFreeAccount = lazy(() => lazyRetry(() => import('pages/CreateFreeAccount')));
const Weight = lazy(() => lazyRetry(() => import('pages/Weight')));
const MedicalIntakeForm = lazy(() =>
  lazyRetry(() => import('pages/HealthProfile/MedicalIntakeForm'))
);
const HealthMetrics = lazy(() => lazyRetry(() => import('pages/HealthProfile/HealthMetrics')));
const HealthConditions = lazy(() =>
  lazyRetry(() => import('pages/HealthProfile/HealthConditions'))
);
const ChronicDiseases = lazy(() => lazyRetry(() => import('pages/HealthProfile/ChronicDiseases')));
const SocialHistory = lazy(() => lazyRetry(() => import('pages/HealthProfile/SocialHistory')));
const Medications = lazy(() => lazyRetry(() => import('pages/HealthProfile/Medications')));
const Allergies = lazy(() => lazyRetry(() => import('pages/HealthProfile/Allergies')));
const WomensHealth = lazy(() => lazyRetry(() => import('pages/HealthProfile/WomensHealth')));
const MedicalRecords = lazy(() => lazyRetry(() => import('pages/HealthProfile/MedicalRecords')));
const Settings = lazy(() => lazyRetry(() => import('pages/Settings')));
const About = lazy(() => lazyRetry(() => import('pages/About')));
const Legal = lazy(() => lazyRetry(() => import('pages/Legal')));
const Labs = lazy(() => lazyRetry(() => import('pages/Labs')));
const Documents = lazy(() => lazyRetry(() => import('pages/Documents')));
const History = lazy(() => lazyRetry(() => import('pages/History')));
const MyResources = lazy(() => lazyRetry(() => import('pages/MyResources')));
const MyPrescriptions = lazy(() => lazyRetry(() => import('pages/Prescriptions/MyPrescriptions')));
const Pharmacy = lazy(() => lazyRetry(() => import('pages/Prescriptions/Pharmacy')));
const DiscountCard = lazy(() => lazyRetry(() => import('pages/Prescriptions/DiscountCard')));
const AppointmentRequired = lazy(() => lazyRetry(() => import('pages/AppointmentRequired')));
const Article = lazy(() => lazyRetry(() => import('pages/Article')));
const ZoomCall = lazy(() => lazyRetry(() => import('pages/ZoomCall')));
const VerifyEmail = lazy(() => lazyRetry(() => import('pages/VerifyEmail')));
const ConfirmAccount = lazy(() => lazyRetry(() => import('pages/ConfirmAccount')));
const OptaviaSupport = lazy(() => lazyRetry(() => import('pages/Support')));
const MigrateToPlan = lazy(() => lazyRetry(() => import('pages/MigrateToPlan')));
const MigrateToBundlePlan = lazy(() => lazyRetry(() => import('pages/MigrateToBundlePlan')));
const MigrateToBundlePlanFromWM = lazy(() =>
  lazyRetry(() => import('pages/MigrateToBundlePlanFromWM'))
);
const UnpaidInvoice = lazy(() => lazyRetry(() => import('pages/UnpaidInvoice')));
const KrogerSavingsClub = lazy(() => lazyRetry(() => import('pages/KrogerSavingsClub')));
const CurrentWeight = lazy(() => lazyRetry(() => import('pages/CurrentWeight')));
const KrogerPlus = lazy(() => lazyRetry(() => import('pages/KrogerPlus')));
const VerifyYourIdentity = lazy(() => lazyRetry(() => import('pages/VerifyYourIdentity')));

const AppRouter = () => {
  const { flagsReady } = useFlagsStatus();
  const isEnabledReportsFeature = useFlag(FeatureFlag.Reports);
  const isEnabledHealthProfilePageFeature = useFlag(FeatureFlag.HealthProfilePage);
  const isEnabledDocumentsPageFeature = useFlag(FeatureFlag.DocumentsPage);
  const isEnableMigrationToBundlePlan = useFlag(FeatureFlag.MigrateToBundlePlan);
  const isUpgradeToLifeMDPlusEnable = useFlag(FeatureFlag.UpgradeToLifeMDPlus);

  const { weightManagement, accessToken, state } = useAppSelector(selectUser);
  const isDisabledDocumentsAndLinks = useAppSelector(selectDocumentsAndLinksStatus);
  const isDisabledHealthProfile = useAppSelector(selectHealthProfileStatus);
  const isDisabledLabsAndImaging = useAppSelector(selectLabsAndImagingStatus);
  const isDisabledMigrateToBundlePlan = useAppSelector(selectMigrateToBundlePlanStatus);
  const isExtendedReOnboardingWMFlowEnabled = useAppSelector(selectReOnboardingWMStatus);

  const isPaywallMessaging = useAppSelector((store) => selectMessagingStatus(store, 'paywall'));
  const location = useLocation();
  const isFreemium = useFreemium();
  const { isWeightManagement, hadOrHasWeightManagement, isTTPatient, isInsurancePatient } =
    useWeightManagement();

  const hasUpdateInfo =
    location.search.includes('newPlanID') || location.search.includes('newPPID');

  const isOptaviaPatient = usePartnerPatient('Optavia');
  const isKrogerPatient = usePartnerPatient('Kroger');

  const isAbleToScheduleAppt =
    (!accessToken && isUpgradeToLifeMDPlusEnable) || (!!accessToken && (!isFreemium || !!state));

  const isAbleToChangeToTheBundle =
    isEnableMigrationToBundlePlan &&
    (!isDisabledMigrateToBundlePlan ||
      isExtendedReOnboardingWMFlowEnabled ||
      weightManagement?.offerBundled?.isRequiredAppointment);
  const isAbleToChangeToMoveFromTirzepatide = true;

  if (!flagsReady) {
    return <Loader isVisible />;
  }
  return (
    <>
      <RouteScrollTop />
      <Suspense fallback={<Loader isVisible />}>
        <AnimatePresence mode="wait">
          <Routes>
            <Route
              element={
                isAbleToScheduleAppt ? (
                  <CreateAppointmentExtended />
                ) : (
                  <Navigate to={PathName.CompleteAccount + '/quick-check'} />
                )
              }
              path={PathName.CreateAppointmentExtended}
            />
            <Route
              element={
                accessToken ? <Navigate to={`${PathName.Home + location.search}`} /> : <SignUp />
              }
              path={`${PathName.SignUp}/:plan/:flow/:stepName`}
            />
            <Route element={<SignupRouter />} path={`${PathName.SignUp}/:plan/:flow`} />
            <Route element={<SignupRouter />} path={`${PathName.SignUp}/:plan`} />
            <Route element={<SignupRouter />} path={`${PathName.SignUp}`} />
            <Route element={<UnsupportedAge />} path={PathName.UnsupportedAge} />
            <Route element={<Router />}>
              <Route
                element={
                  <ZoomCallProvider>
                    <FrontDeskProvider socket={socketFrontDesk}>
                      <AnimatePresence mode="wait">
                        <Routes key={location.pathname} location={location}>
                          <Route element={<Main />}>
                            <Route element={<Home />} index />
                            <Route element={<Care />} path={PathName.GetCare} />
                            <Route element={<ZoomCall />} path={PathName.ZoomCall} />
                            <Route element={<OptaviaSupport />} path={PathName.Support} />
                            <Route
                              element={
                                isPaywallMessaging ? (
                                  <Navigate to={PathName.Home} replace />
                                ) : (
                                  <Messages />
                                )
                              }
                              path={PathName.Messages}
                            />
                            <Route
                              element={
                                isKrogerPatient ? (
                                  <KrogerSavingsClub />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.KrogerSavingsClub}
                            />
                            <Route
                              element={
                                isKrogerPatient ? (
                                  <KrogerLocations />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.KrogerLocations}
                            />
                            <Route
                              element={
                                isKrogerPatient ? (
                                  <KrogerConnectID />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.KrogerConnectID}
                            />
                            {/*TODO remove in a future*/}
                            <Route
                              element={<Navigate to={PathName.CreateAppointmentExtended} replace />}
                              path={`/appointments${PathName.CreateAppointmentExtended}`}
                            />
                            <Route
                              element={<Navigate to={PathName.GetCare} replace />}
                              path="/appointments"
                            />
                            <Route
                              element={<Navigate to={PathName.CreateAppointmentExtended} replace />}
                              path={`/appointments${PathName.CreateAppointmentExtended}`}
                            />
                            <Route
                              element={<Navigate to={PathName.GetCare} replace />}
                              path="/appointments"
                            />
                            <Route
                              element={<UpgradeToAnnualPlan />}
                              path={PathName.UpgradeToAnnualPlan}
                            />
                            <Route element={<Appointment />} path={`${PathName.Appointment}/:id`} />
                            <Route
                              element={
                                !isDisabledLabsAndImaging && isEnabledReportsFeature ? (
                                  <LabResults />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={`${PathName.Labs}/:id`}
                            />
                            <Route
                              element={
                                !isDisabledLabsAndImaging && isEnabledReportsFeature ? (
                                  <Labs />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.Labs}
                            />
                            <Route
                              element={
                                !isDisabledDocumentsAndLinks && isEnabledDocumentsPageFeature ? (
                                  <Documents />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.Documents}
                            />
                            <Route element={<History />} path={PathName.History} />
                            <Route element={<Settings />} path={PathName.Settings} />
                            <Route element={<About />} path={PathName.About} />
                            <Route element={<Legal />} path={PathName.Legal} />
                            <Route element={<AccountSettings />} path={PathName.AccountSettings} />
                            <Route
                              element={
                                !isDisabledHealthProfile && isEnabledHealthProfilePageFeature ? (
                                  <Outlet />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                            >
                              <Route element={<HealthProfile />} path={PathName.HealthProfile} />
                              <Route
                                element={<MedicalIntakeForm />}
                                path={PathName.MedicalIntakeForm}
                              />
                              <Route element={<HealthMetrics />} path={PathName.HealthMetrics} />
                              <Route
                                element={<HealthConditions />}
                                path={PathName.HealthConditions}
                              />
                              <Route
                                element={<ChronicDiseases />}
                                path={PathName.ChronicDiseases}
                              />
                              <Route element={<SocialHistory />} path={PathName.SocialHistory} />
                              <Route element={<Medications />} path={PathName.Medications} />
                              <Route element={<Allergies />} path={PathName.Allergies} />
                              <Route element={<WomensHealth />} path={PathName.WomensHealth} />
                              <Route element={<MedicalRecords />} path={PathName.MedicalRecords} />
                            </Route>
                            <Route element={<MyPrescriptions />} path={PathName.MyPrescriptions} />
                            <Route element={<Pharmacy />} path={PathName.Pharmacy} />
                            <Route element={<DiscountCard />} path={PathName.DiscountCard} />
                            <Route element={<AccountInfo />} path={PathName.AccountInformation} />
                            <Route element={<ChangePassword />} path={PathName.ChangePassword} />
                            <Route
                              element={<SignInAndSecurity />}
                              path={PathName.SignInAndSecurity}
                            />
                            <Route element={<Insurance />} path={PathName.Insurance} />
                            <Route element={<Notifications />} path={PathName.Notifications} />
                            <Route
                              element={
                                isFreemium ? (
                                  <Navigate to={PathName.NotFound} replace />
                                ) : (
                                  <BillingDetails />
                                )
                              }
                              path={PathName.BillingDetails}
                            />
                            <Route element={<MedicaidWaiver />} path={PathName.MedicaidWaiver} />
                            <Route element={<HippaAgreement />} path={PathName.HippaAgreement} />
                            <Route
                              element={<PaymentMethod />}
                              path={PathName.PaymentMethods + '/:action'}
                            />
                            <Route element={<UpgradePlan />} path={PathName.UpgradePlan} />
                            <Route element={<ChangePlan />} path={PathName.ChangePlan} />
                            <Route
                              element={
                                isAbleToChangeToTheBundle ? (
                                  !isWeightManagement ? (
                                    <MigrateToBundlePlan />
                                  ) : (
                                    <MigrateToBundlePlanFromWM />
                                  )
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.MigrateToBundlePlan}
                            />
                            <Route
                              element={
                                !hasUpdateInfo ? (
                                  <Navigate to={PathName.ChangePlan} replace />
                                ) : (
                                  <Checkout />
                                )
                              }
                              path={PathName.Checkout}
                            />
                            <Route
                              element={
                                isWeightManagement ? (
                                  <Weight />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.Weight}
                            />
                            <Route
                              element={<Navigate to={PathName.Weight} replace />}
                              path="/weight-management"
                            />
                            <Route
                              element={
                                isWeightManagement ? (
                                  <MyResources />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={`${PathName.MyResources}/:id`}
                            />
                            <Route
                              element={
                                isWeightManagement ? (
                                  <Article />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={`${PathName.MyResources}/:id/:articleId`}
                            />
                            <Route
                              element={
                                isKrogerPatient ? (
                                  <KrogerPlus />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.KrogerPlus}
                            />
                            <Route element={<UnpaidInvoice />} path={PathName.UnpaidInvoice} />
                            <Route
                              element={
                                isWeightManagement ? (
                                  <Shop />
                                ) : (
                                  <Navigate to={PathName.NotFound} replace />
                                )
                              }
                              path={PathName.Shop}
                            >
                              <Route element={<ShopHome />} index />
                              <Route element={<Cart />} path={PathName.ShopCart} />
                              <Route element={<ShopCheckout />} path={PathName.ShopCheckout} />
                              <Route element={<Products />} path={PathName.ShopProducts} />
                              <Route element={<Address />} path={PathName.ShopAddress} />
                              <Route element={<Product />} path={PathName.ShopProducts + '/:id'} />
                              <Route element={<OrdersHistory />} path={PathName.ShopOrders} />
                              <Route element={<Order />} path={PathName.ShopOrders + '/:id'} />
                              <Route element={<Navigate to={PathName.Shop} />} path="*" />
                            </Route>
                            <Route element={<NotFound />} path={PathName.NotFound} />
                            <Route element={<Navigate to={PathName.NotFound} />} path="*" />
                          </Route>
                          <Route
                            element={
                              isWeightManagement ? (
                                <AppointmentRequired />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.AppointmentRequired}
                          />
                          <Route element={<CurrentWeight />} path={PathName.CurrentWeight} />
                          <Route
                            element={
                              hadOrHasWeightManagement && !isWeightManagement ? (
                                <Navigate
                                  to={{
                                    pathname: PathName.Home,
                                    search: `action=${InternalLinkAction.AskToUpgradeToTheWM}`
                                  }}
                                  replace
                                />
                              ) : (
                                <PrescriptionStatus />
                              )
                            }
                            path={PathName.Prescriptions}
                          />
                          <Route
                            element={
                              isOptaviaPatient ? (
                                <Navigate to={PathName.NotFound} replace />
                              ) : (
                                <AppointmentMif />
                              )
                            }
                            path={PathName.AppointmentMif + '/:mifID'}
                          />
                          <Route
                            element={
                              isWeightManagement ? (
                                <DeviceStatus />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.DeviceStatus}
                          />
                          <Route element={<MigrateToPlan />} path={PathName.MigrateToPlan} />
                          <Route
                            element={
                              isWeightManagement ? (
                                <AddMeasurement />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.AddMeasurement}
                          />
                          <Route
                            element={
                              isFreemium ? (
                                <CompleteAccount />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={`${PathName.CompleteAccount}/:stepName?`}
                          />
                          <Route
                            element={
                              isFreemium ? (
                                <CompleteAccount flow="short" />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={`${PathName.UpgradeAccount}/:stepName?`}
                          />
                          <Route element={<PostOnboarding />} path={PathName.IntakeForm} />
                          <Route
                            element={
                              isAbleToChangeToMoveFromTirzepatide ? (
                                <MigrateFromTirzepatide />
                              ) : (
                                <Navigate to={PathName.AccountInformation} />
                              )
                            }
                            path={PathName.MoveFromTirzepatide}
                          />
                          <Route
                            element={
                              isTTPatient || isInsurancePatient ? (
                                <Navigate to={PathName.Weight} />
                              ) : (
                                <MigrateToTT />
                              )
                            }
                            path={PathName.MigrateToTT}
                          />
                          <Route
                            element={
                              !isWeightManagement && !hadOrHasWeightManagement ? (
                                <MigrateToWM />
                              ) : (
                                <Navigate to={PathName.NotFound} replace />
                              )
                            }
                            path={PathName.MigrateToWM}
                          />
                        </Routes>
                      </AnimatePresence>
                    </FrontDeskProvider>
                  </ZoomCallProvider>
                }
                path="/*"
              />
              <Route element={<VerifyYourIdentity />} path={PathName.VerifyYourIdentity} />
              <Route element={<Login />} path={PathName.Login} />
              <Route element={<CreateNewPassword />} path={PathName.CreateNewPassword} />
              <Route element={<ForgotPassword />} path={PathName.ForgotPassword} />
              <Route
                element={<Navigate to={SIGNUP_PATH} replace />}
                path={PathName.CreateFreeAccount}
              />
              {/*<Route element={<CreateFreeAccount />} path={PathName.CreateFreeAccount} />*/}
              <Route element={<VerifyEmail />} path={PathName.VerifyEmail} />
              <Route element={<ConfirmAccount />} path={PathName.ConfirmAccount} />
            </Route>
          </Routes>
        </AnimatePresence>
      </Suspense>
    </>
  );
};

export default AppRouter;
