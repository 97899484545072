import { useTitle } from 'react-use';
import classNames from 'classnames';

import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useCreateAppointmentExtended } from 'hooks/useCreateAppointmentExtended';

import Content from './Content';

const CreateAppointmentExtended = () => {
  useTitle('LifeMD - Schedule new appointment');

  const {
    currentStep,
    exitFlow,
    initialLoading,
    loading,
    moveToStep,
    isBackAnimation,
    currentPlan,
    selectedPlan,
    handleSelectTime,
    handleUpgradePlan,
    appointmentTypes,
    isRequiredToUpgradeToLifeMDPlus,
    handleCreateAccount,
    handleSubmitMIF,
    haveToPayForAppt,
    defaultPlanId
  } = useCreateAppointmentExtended();

  return (
    <FadeWrapper
      className={classNames('flex flex-col max-md:h-full', {
        'pointer-events-none': initialLoading
      })}
    >
      {initialLoading ? (
        <Loader isVisible />
      ) : (
        <>
          <Header hideStepper onClickPrev={() => moveToStep('prev')} />
          <Content
            appointmentTypes={appointmentTypes}
            currentPlan={currentPlan}
            exitFlow={exitFlow}
            haveToPayForAppt={haveToPayForAppt}
            isBackAnimation={isBackAnimation}
            isRequiredToUpgradeToLifeMDPlus={isRequiredToUpgradeToLifeMDPlus}
            loading={loading}
            moveToStep={moveToStep}
            selectedPlan={selectedPlan}
            step={currentStep}
            defaultPlanId={defaultPlanId}
            onCreateAccount={handleCreateAccount}
            onSelectDateAndTime={handleSelectTime}
            onSubmitMIF={handleSubmitMIF}
            onUpgradePlan={handleUpgradePlan}
          />
        </>
      )}
    </FadeWrapper>
  );
};

export default CreateAppointmentExtended;
