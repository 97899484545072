export const DEFAULT_APPOINTMENT_TYPE = 'General Medical Care';

export const DEFAULT_HRT_APPOINTMENT_TYPE = 'HRT Club Consultation';

export const DEFAULT_WEIGHT_MANAGEMENT_TYPE = 'Weight Loss'; // for the following (non onboarding) wm appts

export const DEFAULT_UTI_FLOW_APPOINTMENT_TYPE = 'UTI';

export const PROVIDERS_ITEMS_COUNT = 2;

export const ASAP_OPTION_DATE = 'asap';

export const APPROX_WAIT_TIME = '~30 min wait';

export const DEFAULT_FRONT_DESK_TYPE_FOR_WM_UPGRADE = 'Weight management upgrade';

export const WITHINGS_BODY_PRO_2_SCALE_PRICE = '199';

export const DEFAULT_SCALE_ADDON_CODE = 'withings-body-pro-2-scale';

export const DUMMY_PRICE_OF_AT_HOME_LABS = '58';

export const TRIPLE_THERAPY_PLAN_NAME = 'Triple Therapy';

export const DEFAULT_APPOINTMENT_CODE = 'general-medical-care';

export const PRESCRIPTION_REFILL_APPT_CODE = 'prescription-refills';

export const DEFAULT_OPTAVIA_WM_APPT_CODE = 'wm-optavia';

export const DEFAULT_WM_APPT_CODE = 'wm';

export const DEFAULT_LAB_TESTS_APPT_CODE = 'lab-tests';

export const GOVERNMENT_ID_ERRORS = [
  'InvalidIdPhotoError',
  'NameMatchError',
  'BarcodeMatchError',
  'BirthDateMatchError',
  'PublicPersonaDetectError',
  'ExpiredIdError',
  'UnsupportedIdPhotoError',
  'InvalidIdError'
];

export const SELF_PORTRAIT_ERRORS = ['InvalidUserPhotoError', 'FaceMatchError'];

export const VETERANS_INSURANCE_PROVIDER = 'Veterans Affairs (VA) / TRICARE';
