export const SIGNUP_CONFIRM_LINKS = {
  privacy: 'https://lifemd.com/privacy',
  telehealth: 'https://lifemd.com/consent-to-telehealth',
  terms: 'https://lifemd.com/terms'
};
export const LIFEMD_WEBSITE = 'https://lifemd.com';
export const LINK_TO_FAQ =
  'https://support.lifemd.com/hc/en-us/articles/4413445509015-What-is-LifeMD-s-policy-on-prescribing-controlled-substances-';
export const LINK_CALL_HELP =
  'https://support.lifemd.com/hc/en-us/articles/4418910761751-Video-Conferencing-Options-and-Troubleshooting-Tips';
// export const LINK_TO_LOCAL_URGENT_CARE = 'https://www.google.com/search?q=local+urgent+care';
// export const LINK_TO_LOCAL_EMERGENCY_SERVICES =
//   'https://www.google.com/search?q=local+emergency+services';
export const LINK_TO_SUPPORT = 'https://support.lifemd.com/hc/en-us';
export const PRESCRIPTION_TRACKING_URL = 'https://www.google.com/search?q=';
export const WM_DEVICES_TRACKING_URL = 'https://www.google.com/search?q=';
export const STORE_ORDER_TRACKING_URL = 'https://www.google.com/search?q=';
export const PRESCRIPTION_INSTRUCTIONS_URL =
  'https://lifemd.com/portal/content/administering-compounded-medication.php';
export const WEIGHT_MANAGEMENT_VIDEO_URL =
  'https://player.vimeo.com/video/849391069?h=1d4a5a0704&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1';
export const LINK_TO_ACCESSING_APPOINTMENTS =
  'https://support.lifemd.com/hc/en-us/articles/4413826265623';
export const LINK_TO_POSTHOG = 'https://app.posthog.com';
export const TRUST_PILOT_URL = 'https://www.trustpilot.com/review/lifemd.com';
export const LINK_TO_TRACKQUANT = 'https://click.trackquant.com';
export const LINK_TO_SCHEDULE_LABS = 'https://appointment.questdiagnostics.com/as-home';
export const LINK_TO_SIGNUP_WM_REDIRECT =
  'https://lifemd.com/weight-management/?source=signup-wm-redirect';
export const LINK_TO_AT_HOME_LABS = 'https://lifemd.com/at-home-labs';
export const LINK_TO_ACCESSIBILITY = 'https://lifemd.com/accessibility';
export const LINK_TO_MEMBERSHIP = 'https://www.lifemd.com/membership';
export const LINK_TO_LIFEMD = 'https://lifemd.com';

export const LINKS_TO_KROGER_PLUS = {
  boost: 'https://lifemd.page.link/kroger-boost',
  create_account: 'https://lifemd.page.link/kroger-sign-up',
  drug: 'https://lifemd.page.link/kroger-savings-club-drug',
  earn_money: 'https://lifemd.page.link/kroger-cashback',
  exclusive_savings: 'https://lifemd.page.link/kroger-coupons',
  free_membership: 'https://www.kroger.com/pr/free-membership',
  frequently_questions: 'https://www.krogerhealthsavings.com/frequently-asked-questions',
  join: 'https://lifemd.page.link/kroger-savings-club-join',
  products: 'https://lifemd.page.link/kroger-products',
  save_at_the_pump: 'https://lifemd.page.link/kroger-fuel-points',
  schedule_now: 'https://lifemd.page.link/kroger-schedule-nutrition-appt',
  shops_list: 'https://lifemd.page.link/kroger-shop-lists',
  sign_in: 'https://lifemd.page.link/kroger-sign-in',
  ways_to_shop: 'https://lifemd.page.link/kroger-ways-to-shop'
};
