import { Common } from '@thecvlb/design-system';

import { selectUser } from 'store';
import { HealthConditionsItemProps } from 'store/mif/mif.types';

import ListItemWrapper from 'shared/animationWrappers/ListItemWrapper';

import { MEDICAL_HISTORY_FORM_FIELDS } from 'constants/formFields';
import { useAppSelector } from 'hooks';
import { SexAtBirth } from 'utils/enums';

import { HealthConditionsProps } from './healthConditions.types';

const HealthConditions: React.FC<HealthConditionsProps> = ({
  onUpdate,
  healthConditions,
  loading = false,
  isPostOnboarding
}) => {
  const { sexAtBirth } = useAppSelector(selectUser);

  const optionNamesList = healthConditions.map((v) => v.name);
  const NONE_ELEMENT = MEDICAL_HISTORY_FORM_FIELDS.find((e) => e.fieldID === '0');

  const handleChange = (name: string) => {
    let history: HealthConditionsItemProps[];
    if (optionNamesList.includes(name)) {
      history = healthConditions.filter((field) => field.name !== name);
    } else {
      if (name === NONE_ELEMENT?.fieldID) {
        history = [{ name, notes: '' }];
      } else {
        history = [...healthConditions, { name, notes: '' }].filter(
          (e) => e.name !== NONE_ELEMENT?.fieldID
        );
      }
    }
    onUpdate(history);
  };

  const handleChangeNotes = (id: string, notes: string) => {
    onUpdate(healthConditions.map((v) => (v.name === id ? { ...v, notes } : v)));
  };

  const filteredOptions = MEDICAL_HISTORY_FORM_FIELDS.filter((el) => {
    // 9 stands for 'Pregnancy with bleeding'
    return sexAtBirth !== SexAtBirth.Female ? el.fieldID !== '9' : true;
  });

  return (
    <div className="flex flex-col gap-2" data-testid="health_condition">
      {filteredOptions.map((el) => (
        <ListItemWrapper dataTestId="health_condition" key={el.fieldID}>
          <Common.RichCheckbox
            checked={optionNamesList.includes(el.fieldID)}
            dataTestId="checkbox"
            disabled={loading}
            key={el.fieldID}
            label={el.label}
            size={isPostOnboarding ? undefined : 'lg'}
            value={el.fieldID}
            onChange={() => handleChange(el.fieldID)}
          >
            {optionNamesList.includes(el.fieldID) && el.fieldID !== NONE_ELEMENT?.fieldID && (
              <Common.TextArea
                dataTestId="text_area"
                disabled={loading}
                label={`Notes ${loading ? '- saving...' : ''}`}
                name="drug"
                placeholder={`Please add any relevant notes here...\n- Are you being treated for this? How? \n- When did it ${
                  el.label === 'Recently suffered an injury' ? 'happen' : 'start'
                }?`}
                rows={5}
                value={healthConditions.find((value) => value.name === el.fieldID)?.notes}
                onChange={(e) => handleChangeNotes(el.fieldID, e.target.value)}
              />
            )}
          </Common.RichCheckbox>
        </ListItemWrapper>
      ))}
    </div>
  );
};

export default HealthConditions;
