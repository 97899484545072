import { Common } from '@thecvlb/design-system';
import { IconProps } from '@thecvlb/design-system/lib/src/common/Icon';

import { formatPaymentMethod } from 'utils/helpers';

import { PaymentIconProps } from './paymentIcon.types';

const PaymentIcon: React.FC<PaymentIconProps> = ({ cardNumber, cardType }) => {
  const icons: { [key: string]: IconProps['name'] } = {
    american_express: 'amex',
    discover: 'discover',
    master_card: 'mastercard',
    visa: 'visa'
  };

  const icon = icons[formatPaymentMethod(cardNumber, cardType)];

  return icon ? <Common.Icon className="size-full" name={icon} /> : null;
};

export default PaymentIcon;
