import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppointmentItem, AppointmentsState } from './appointments.types';

const initialState: AppointmentsState = {
  currentAppointment: undefined,
  newAppointmentExtended: {
    appointmentDescription: '',
    appointmentTypeId: '',
    callMethod: 'video',
    displayName: '',
    doctorId: '',
    endTime: '',
    initialDate: '',
    isQualifiedForAsyncAppointment: null,
    isWeightManagementAppointment: false,
    membershipData: {
      planId: '',
      planPricePoint: null
    },
    src: '',
    startTime: '',
    status: 'pending',
    uploadRequired: false,
    user: {
      email: '',
      firstName: '',
      lastName: '',
      termsAndConditionsAccepted: false
    }
  },
  pastAppointments: [],
  upcomingAppointments: []
};

const appointmentsSlice = createSlice({
  initialState,
  name: 'appointments',
  reducers: {
    clearNewAppointmentExtended: (state) => {
      state.newAppointmentExtended = initialState.newAppointmentExtended;
    },
    setCurrentAppointment: (state, action: PayloadAction<AppointmentItem>) => {
      state.currentAppointment = action.payload;
    },
    setNewAppointmentExtended: (
      state,
      action: PayloadAction<Partial<AppointmentsState['newAppointmentExtended']>>
    ) => {
      state.newAppointmentExtended = { ...state.newAppointmentExtended, ...action.payload };
    },
    setNewAppointmentUser: (
      state,
      action: PayloadAction<Partial<AppointmentsState['newAppointmentExtended']['user']>>
    ) => {
      state.newAppointmentExtended.user = {
        ...state.newAppointmentExtended.user,
        ...action.payload
      };
    },
    setPastAppointments: (state, action: PayloadAction<AppointmentItem[]>) => {
      state.pastAppointments = action.payload;
    },
    setUpcomingAppointments: (state, action: PayloadAction<AppointmentItem[]>) => {
      state.upcomingAppointments = action.payload;
    }
  }
});

export const {
  setUpcomingAppointments,
  setPastAppointments,
  setCurrentAppointment,
  setNewAppointmentExtended,
  clearNewAppointmentExtended,
  setNewAppointmentUser
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
