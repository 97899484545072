import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetBillingHistoryQuery } from 'services/myAccount/myAccount';
import { BillingHistoryItemProps } from 'services/myAccount/myAccount.types';

import Pagination from 'features/Pagination';
import { PopupInfoProps } from 'features/SlidePanel/slidePanel.types';
import Table from 'features/Table';
import Loader from 'shared/Loader';

import { useDisableScroll } from 'hooks/useDisableScroll';
import useWidth from 'hooks/useWidth';
import { SortOrder } from 'utils/enums';

import ModalContent from './Modal/ModalContent';
import ModalTitle from './Modal/ModalTitle';
import { desktopHeaderSettings } from './billingHistory.settings';
import PopupInfo from './PopupInfo';
import TableColumnItem from './TableColumnItem';

const BillingHistory = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    limit: '50',
    pageNo: '0'
  });
  const { handleDisableScroll } = useDisableScroll(searchParams);
  const { limit, pageNo } = Object.fromEntries([...searchParams]);

  const { data, isLoading, isFetching } = useGetBillingHistoryQuery({ limit, pageNo });
  const { isMobile } = useWidth();
  const [popupInfo, setPopupInfo] = useState<PopupInfoProps & { isShow: boolean }>({
    content: null,
    isShow: false,
    title: ''
  });

  const changePage = useCallback(
    (data: { selected: number }) => {
      handleDisableScroll();
      searchParams.set('pageNo', data.selected.toString());
      const newQueryParams = searchParams.toString();
      setSearchParams(newQueryParams);
    },
    [searchParams]
  );

  const togglePopup = () => {
    setPopupInfo({ ...popupInfo, isShow: !popupInfo.isShow });
  };

  const openPanel = (item: BillingHistoryItemProps) => {
    setPopupInfo({
      content: <ModalContent item={item} />,
      isShow: true,
      title: <ModalTitle item={item} />
    });
  };

  const handleSort = ({ sortField, sortOrder }: { sortField: string; sortOrder: SortOrder }) => {
    handleDisableScroll();
    searchParams.set('sortField', sortField === 'date' ? 'createdAt' : sortField);
    searchParams.set('sortOrder', sortOrder);
    const newQueryParams = searchParams.toString();
    setSearchParams(newQueryParams);
  };

  return (
    <>
      <Loader isVisible={isLoading || isFetching} />
      {data?.data && (
        <div className="overflow-hidden rounded-xl bg-white shadow">
          <PopupInfo popupInfo={popupInfo} togglePopup={togglePopup} />
          <Table
            boldCols={['amount']}
            customCol={(item) => <TableColumnItem item={item} />}
            data={data.data ?? []}
            handleSort={handleSort}
            headers={[
              {
                id: 'invoice',
                size: isMobile ? '75%' : '50%'
              },
              ...(!isMobile ? desktopHeaderSettings : []),
              {
                id: 'amount',
                size: '10%'
              }
            ]}
            hideActions
            onClickRow={openPanel}
          />

          <Pagination
            params={{
              limit: +limit,
              pageNo: +pageNo,
              totalCount: data.info.totalCount ?? 0
            }}
            onChange={changePage}
          />
        </div>
      )}
    </>
  );
};

export default BillingHistory;
