import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectShop } from 'store';
import { setShippingAddress } from 'store/shop/shopSlice';

import Address from 'shared/form/Address';

import { useAppDispatch, useAppSelector } from 'hooks';

import { MailingAddress } from 'models/user.types';

const ShippingAddress = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { shippingAddress } = useAppSelector(selectShop);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid },
    trigger
  } = useForm<MailingAddress>({
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: true
  });

  const onSubmit = (data: MailingAddress) => {
    if (!isValid) {
      return trigger();
    }
    dispatch(setShippingAddress(data));
    navigate(-1);
  };

  useEffect(() => {
    if (!!shippingAddress.address) {
      reset(shippingAddress);
    }
  }, []);

  return (
    <form className="flex h-full flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-center text-2xl font-bold text-primary-700 max-md:hidden">
        Shipping address
      </h2>
      <Address control={control} setValue={setValue} trigger={trigger} isDividedToTwoColumns />
      <div className="flex gap-4 max-md:mt-auto md:self-end md:p-4">
        <Common.Button
          className="max-md:hidden"
          color="blue-alt"
          type="button"
          fullWidthOnMobile
          onClick={() => navigate(-1)}
        >
          Back
        </Common.Button>
        <Common.Button color="blue" type="submit" fullWidthOnMobile>
          Save
        </Common.Button>
      </div>
    </form>
  );
};

export default ShippingAddress;
