import { Common } from '@thecvlb/design-system';

import { useGetGlobalMessagesQuery } from 'services/general/general';

import { selectLookup, selectNewAppointmentExtended, selectUser } from 'store';

import RadioGroup from 'shared/RadioGroup';

import { PRESCRIPTION_REFILL_APPT_CODE } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import { PlanCodes } from 'utils/enums';

import { AppointmentCallMethod } from 'models/appointment.types';

import { QualifiedForAsyncProps } from './qualifiedForAsync.types';
const QualifiedForAsync: React.FC<QualifiedForAsyncProps> = ({ category, onSelect }) => {
  const { data } = useGetGlobalMessagesQuery();

  const { activePlanId, activePlanCode, activePricePoint, isFirstAppointmentCompleted } =
    useAppSelector(selectUser);

  const { isWeightManagement } = useWeightManagement();
  const { membershipPlans } = useAppSelector(selectLookup);
  const { code } = useAppSelector(selectNewAppointmentExtended);
  const lifeMdPlusPricePoint = membershipPlans.find(
    (plan) => plan.planCode === PlanCodes.LifeMDPlus
  )?.pricePoints?.[0];

  const getCurrentOrFuturePricePoint = () => {
    if (!activePlanId || !activePricePoint || activePlanCode === PlanCodes.FlexCare) {
      return lifeMdPlusPricePoint;
    }
    return (
      membershipPlans
        .find((plan) => plan._id === activePlanId)
        ?.pricePoints?.find((pp) => pp.planPricePointId === activePricePoint) ?? null
    );
  };

  const currentOrFuturePricePoint = getCurrentOrFuturePricePoint();

  const messageCost =
    code === PRESCRIPTION_REFILL_APPT_CODE
      ? lifeMdPlusPricePoint?.asyncMedicationRefillCost
      : lifeMdPlusPricePoint?.asyncAppointmentCost;
  const isMessageBasedIncludedWithPlan = currentOrFuturePricePoint?.asyncAppointmentCost === '0';

  const videoCost = isFirstAppointmentCompleted
    ? lifeMdPlusPricePoint?.subsequentAppointmentCost
    : lifeMdPlusPricePoint?.initialAppointmentCost;
  const isVideoBasedIncludedWithPlan = isFirstAppointmentCompleted
    ? currentOrFuturePricePoint?.subsequentAppointmentCost === '0'
    : currentOrFuturePricePoint?.initialAppointmentCost === '0';

  const getSubLabel = (isIncludedWithPlan: boolean, price?: string) => (
    <p className="text-sm text-primary-400">
      <span className={isIncludedWithPlan ? 'text-primary-200 line-through' : ''}>${price}</span>{' '}
      {isIncludedWithPlan && <span>Included with plan</span>}
    </p>
  );

  const OPTIONS = [
    {
      description: data?.messageAppointmentDescription,
      label: data?.messageAppointmentText || '',
      subLabel: getSubLabel(isMessageBasedIncludedWithPlan, messageCost),
      value: 'message' as AppointmentCallMethod
    },
    {
      description: data?.videoAppointmentDescription,
      label: data?.videoAppointmentText || '',
      subLabel: getSubLabel(isVideoBasedIncludedWithPlan, videoCost),
      value: 'video' as AppointmentCallMethod
    }
  ];

  const handleSelect = (callMethod: AppointmentCallMethod) => {
    onSelect({
      callMethod,
      price: callMethod === 'message' ? messageCost : videoCost
    });
  };

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Common.ColorTag color="primary" icon="check" text={category} />
        <h1 className="text-m2xl font-bold text-primary-700" data-testid="async_header">
          How would you like to be seen for {category}?
        </h1>
        {isWeightManagement && (
          <p>You’ve got exclusive discounts with your Weight Management membership.</p>
        )}
      </div>
      <RadioGroup items={OPTIONS} onSelect={handleSelect} />
    </div>
  );
};

export default QualifiedForAsync;
