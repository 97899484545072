import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';

import { OrderItemProps } from './orderItem.types';

const OrderItem: React.FC<OrderItemProps> = ({ order, onClick }) => {
  return (
    <button className="flex flex-col p-4 text-left" data-testid="open_order_btn" onClick={onClick}>
      <div className="flex flex-col gap-1" data-testid="order_item">
        <p className="flex justify-between text-mBase font-bold">
          <span data-testid="order_number">{order.orderNumber}</span>
          <span className="text-mBase font-bold" data-testid="order_total">
            ${order.total}
          </span>
        </p>
        <p className="text-mBase" data-testid="order_tracking_number">
          Tracking: {order.trackingNumber}
        </p>
        <p className="text-mBase text-gray" data-testid="ordered_date">
          Ordered: {dayjs(order.createdAt).format(DateFormat.MMMM_DD_YYYY)} • {order.items?.length}{' '}
          Item{order.items?.length > 1 ? 's' : ''}
        </p>
      </div>
      <div className="flex w-full items-center justify-between py-4 text-mBase font-semibold">
        <span>Receipt</span>
        <Common.Icon className="text-gray" name="arrow-right" />
      </div>
    </button>
  );
};

export default OrderItem;
