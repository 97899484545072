import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetGeneralSettingsRes } from 'services/settings/settings.types';

import { NotificationNames, NotificationTypes } from 'utils/enums';

import { SettingsState } from './settings.types';

const initialState: SettingsState = {
  _id: '',
  globalSettings: { krogerNearbyDistance: 0, patientMaxAge: 0, patientMinAge: 0 },
  notificationPreferences: {
    browserNotification: {
      appointmentChanges: false,
      appointmentReminder: false,
      messages: false,
      newOffers: false
    },
    emailNotification: {
      appointmentChanges: false,
      appointmentReminder: false,
      messages: false,
      newOffers: false
    },
    textNotification: {
      appointmentChanges: false,
      appointmentReminder: false,
      messages: false,
      newOffers: false
    }
  },
  userId: ''
};

const settingsSlice = createSlice({
  initialState,
  name: 'settings',
  reducers: {
    setGlobalSettings: (state, action: PayloadAction<GetGeneralSettingsRes>) => {
      state.globalSettings = action.payload;
    },
    setSettings: (state, action: PayloadAction<Omit<SettingsState, '_id' | 'globalSettings'>>) => {
      state.notificationPreferences = action.payload.notificationPreferences;
      state.userId = action.payload.userId;
    },
    toggleSetting: (
      state,
      action: PayloadAction<{
        notificationName: NotificationNames;
        notificationType: NotificationTypes;
      }>
    ) => {
      state.notificationPreferences[action.payload.notificationType][
        action.payload.notificationName
      ] =
        !state.notificationPreferences[action.payload.notificationType][
          action.payload.notificationName
        ];
    }
  }
});

export const { setSettings, toggleSetting, setGlobalSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
