import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetSet } from 'react-use';
import uniqBy from 'lodash/uniqBy';

import { useSendMigrationDecisionMutation } from 'services/general/general';
import { UserDecision } from 'services/general/general.types';
import { useGetMifStructureQuery, useSendMifResponseMutation } from 'services/mifs/mifs';
import { useLazyGetMyAccountQuery, useUpdateMyAccountMutation } from 'services/myAccount/myAccount';

import { selectMifInfo, selectUser } from 'store';
import { MifStateProps } from 'store/mif/mif.types';
import { clearTirzepatideMif, setTirzepatideMif } from 'store/mif/mifSlice';
import { ValidCouponCodes } from 'store/signup/signup.types';

import {
  PossibleMoveToStepTypes,
  Props
} from 'containers/MigrateFromTirzepatide/Content/content.types';
import {
  FillMifDataProps,
  MIGRATE_FROM_TIRZEPATIDE_STEPS,
  PatchedQuestion,
  StepName
} from 'containers/MigrateFromTirzepatide/migrateFormTirzepatide.types';
import { notifyError } from 'shared/Toast/Toast';

import { SEMAGLUTIDE_PRICE_POINT, TIRZEPATIDE_PRICE_POINT } from 'constants/pricepoints';
import { PathName, PlanCodes, TirzepatideMigrationPatientAction } from 'utils/enums';
import { findMaintenancePP, handleRequestCatch } from 'utils/helpers';

import { UserCurrentWMPPType } from 'models/user.types';

import { useAppDispatch, useAppSelector, useQuery } from './index';
import { useGetActivePlan } from './useGetActivePlan';
const ID_FOR_MIF_REQUESTS = 'tirzepatide-migration';

const buildPayload = (type: PossibleMoveToStepTypes): MifStateProps['tirzepatideMif'] | null => {
  let payload: MifStateProps['tirzepatideMif'] | null = null;
  if (typeof type === 'object') {
    switch (type.step) {
      case 'mif-1':
      case 'mif-2':
        payload = [type.data];
        break;
      // add more conditions here
      default:
        break;
    }
  }
  return payload;
};

const getNextStep = (
  type: FillMifDataProps,
  {
    mifCheckResults = ''
  }: {
    mifCheckResults?: string;
  }
): StepName | null => {
  let nextStep: StepName | null = null;
  switch (type.step) {
    case 'mif-1':
      nextStep = 'mif-2';
      break;
    case 'mif-2':
      nextStep = 'results';
      break;
    case 'results':
      if (type.data) {
        if (['not-eligible-bundled', 'not-eligible-maintenance'].includes(mifCheckResults)) {
          nextStep = 'select-medication';
        }
      } else {
        nextStep = 'appointment';
      }
      break;
    case 'select-medication':
      nextStep = type.data ? 'checkout' : 'appointment';
      break;
    default:
  }
  return nextStep;
};

export const useMigrateFromTirzepatide = (onComplete: () => void, onLeaveFlow: () => void) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentStep = (useQuery().get('s') ?? '') as StepName;
  const { tirzepatideMif } = useAppSelector(selectMifInfo);
  const { activePricePoint, tirzepatideMigration } = useAppSelector(selectUser);

  const mifCheckResults = tirzepatideMigration?.eligibilityType;

  const { data, isLoading: isMifLoading } = useGetMifStructureQuery(ID_FOR_MIF_REQUESTS);
  const [submitMif, { isLoading: isSubmittingMif }] = useSendMifResponseMutation();
  const [getMyAccount, { isFetching: isGettingMyAccount }] = useLazyGetMyAccountQuery();
  const [updateMyAccount, { isLoading: isUpdatingUser }] = useUpdateMyAccountMutation();
  const [sendDecision, { isLoading: isSendingMigrationDecision }] =
    useSendMigrationDecisionMutation();

  const [isBackAnimation, toggleBackAnimation] = useGetSet(false);

  const mifSteps = (data?.data.configs ?? [])
    .filter((c) => c.config.type !== 'info')
    .toSorted((a, b) => a.order - b.order) as PatchedQuestion[];

  const { plan, pricePoint, isFetching } = useGetActivePlan({
    planCode: PlanCodes.WeightManagementMembership
  });

  const userCurrentPPType: UserCurrentWMPPType =
    activePricePoint?.includes('low-dose') || activePricePoint?.includes('maintenance')
      ? 'maintenance'
      : activePricePoint?.includes('bundle')
        ? 'bundle'
        : 'care-only';

  const userCurrentPP = pricePoint;

  const tirzepatideMaintenancePP = findMaintenancePP(plan?.pricePoints, {
    keyword: 'tirzepatide'
  });
  const semaglutideMaintenancePP = findMaintenancePP(plan?.pricePoints, {
    keyword: 'semaglutide'
  });

  const isBundlePPType = userCurrentPPType === 'bundle';
  const couponCode: ValidCouponCodes | undefined = isBundlePPType ? 'GLP100FLATREC' : undefined;

  const ppToUpgrade = plan?.pricePoints?.find(
    (pp) =>
      pp.planPricePointId ===
      (isBundlePPType ? SEMAGLUTIDE_PRICE_POINT : semaglutideMaintenancePP?.planPricePointId)
  );

  const tirzepatidePP = plan?.pricePoints?.find(
    (pp) =>
      pp.planPricePointId ===
      (isBundlePPType ? TIRZEPATIDE_PRICE_POINT : tirzepatideMaintenancePP?.planPricePointId)
  );

  const updateSearchParams = (value: string) => {
    searchParams.set('s', value);
    setSearchParams(searchParams);
  };

  const handleCompleteFlow = (manualAction?: UserDecision | 'skip') => {
    const getAction = (): UserDecision | null => {
      if (manualAction === 'skip') {
        return null;
      }
      if (manualAction) {
        return manualAction;
      }
      switch (mifCheckResults) {
        case 'eligible':
          return null;
        case 'not-eligible-care-only':
          return TirzepatideMigrationPatientAction.ACCEPTED_SEMAGLUTIDE;
        case 'not-eligible-bundled':
          return TirzepatideMigrationPatientAction.ACCEPTED_TIRZEPATIDE_PLUS_BUNDLED;
        case 'not-eligible-maintenance':
          return TirzepatideMigrationPatientAction.ACCEPTED_TIRZEPATIDE_PLUS_MAINTENANCE;
        default:
          return null;
      }
    };
    const action = getAction();
    if (!action) {
      dispatch(clearTirzepatideMif());
      return onComplete();
    }
    sendDecision({
      action
    })
      .unwrap()
      .then(() => getMyAccount().unwrap())
      .then(() => {
        dispatch(clearTirzepatideMif());
        onComplete();
      })
      .catch(handleRequestCatch);
  };

  const exitFlow = () => {
    navigate(PathName.Home);
    dispatch(clearTirzepatideMif());
  };

  const handleUpdateMyAccount = () => {
    updateMyAccount({
      couponCode,
      planId: plan?._id ?? '',
      planPricePointId: ppToUpgrade?.planPricePointId
    })
      .unwrap()
      .then(() => getMyAccount().unwrap())
      .then(() => handleCompleteFlow('skip'))
      .catch((error) =>
        handleRequestCatch(error, 'Error during plan upgrade, please try again later')
      );
  };

  const moveToStep: Props['moveToStep'] = async (type, extraSearch = ''): Promise<void> => {
    const additionalSearch = extraSearch ? '&' + extraSearch : '';
    const currentStepIndex = MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(currentStep);
    // if we need to store some data in redux, let's do it here
    const payload = buildPayload(type);
    payload && dispatch(setTirzepatideMif(payload));

    // when arg type is an object, then we'll have conditional next step, so below is the logic to define it
    if (typeof type === 'object') {
      const nextStep = getNextStep(type, { mifCheckResults });

      if (type.step === 'mif-2') {
        const body = uniqBy([type.data, ...tirzepatideMif], 'question');
        await submitMif({ body, id: ID_FOR_MIF_REQUESTS })
          .unwrap()
          .then(() => getMyAccount().unwrap())
          .catch(handleRequestCatch);
      }

      if (type.step === 'results' && !nextStep) return handleCompleteFlow();
      if (type.step === 'appointment')
        return handleCompleteFlow(TirzepatideMigrationPatientAction.REQUESTED_APPOINTMENT);
      if (type.step === 'checkout') return handleUpdateMyAccount();

      const indexOfCurrentStep = MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(type.step);
      const indexOfTheNextStep = !!nextStep ? MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(nextStep) : 0;
      toggleBackAnimation(indexOfCurrentStep > indexOfTheNextStep);
      return nextStep ? updateSearchParams(`${nextStep}${additionalSearch}`) : exitFlow();
    } else if (type === 'prev') {
      if (MIGRATE_FROM_TIRZEPATIDE_STEPS.indexOf(currentStep) === 0) {
        return onLeaveFlow();
      }
      toggleBackAnimation(true);
      return navigate(-1);
    } else if (type === 'next') {
      toggleBackAnimation(false);
      let nextStep = MIGRATE_FROM_TIRZEPATIDE_STEPS[currentStepIndex + 1];
      return nextStep ? updateSearchParams(`${nextStep}${additionalSearch}`) : exitFlow();
    } else {
      if (!MIGRATE_FROM_TIRZEPATIDE_STEPS.includes(type)) {
        toggleBackAnimation(true);
        notifyError('Something went wrong, please try again');
        return updateSearchParams(MIGRATE_FROM_TIRZEPATIDE_STEPS[0]);
      }
      toggleBackAnimation(false);
    }
  };

  const onInit = () => {
    dispatch(clearTirzepatideMif());
    if (!!tirzepatideMigration?.patientActionSubmittedAt || !!tirzepatideMigration?.patientAction) {
      updateSearchParams('all-set');
    } else if (!currentStep || !MIGRATE_FROM_TIRZEPATIDE_STEPS.includes(currentStep)) {
      updateSearchParams('intro');
    }
  };

  useEffect(onInit, []);

  return {
    couponCode,
    currentStep,
    isBackAnimation: isBackAnimation(),
    isGettingMyAccount,
    isSubmittingMif,
    loading: isSendingMigrationDecision || isUpdatingUser || isFetching || isMifLoading,
    mifSteps,
    moveToStep,
    ppToUpgrade,
    tirzepatidePP,
    userCurrentPP,
    userCurrentPPType
  };
};
