import { useCallback, useState } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { Common } from '@thecvlb/design-system';
import { Option } from '@thecvlb/design-system/lib/src/common/AutocompleteInputSelect/autocompleteInputSelect.props';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import SmartySDK from 'smartystreets-javascript-sdk';

import { selectStates } from 'store';

import StateSelect from 'shared/form/StateSelect';
import TextInput from 'shared/form/TextInput';
import ZipCodeInput from 'shared/form/ZipCodeInput';

import { useAppSelector } from 'hooks';
import { buildAddress, handleRequestCatch } from 'utils/helpers';
import { ADDRESS_REGEXP, CITY_REGEXP } from 'utils/regExp';

import { AddressOption } from './address.types';

const autoCompleteClient = new SmartySDK.core.ClientBuilder(
  new SmartySDK.core.SharedCredentials(import.meta.env.VITE_SMARTYSTREET_KEY || '')
)
  .withLicenses(['us-autocomplete-pro-cloud'])
  .buildUsAutocompleteProClient();

const Address = <TFormValues extends FieldValues>({
  control,
  trigger,
  setValue,
  size,
  isDividedToTwoColumns = false,
  isStatePickerDisabled = false,
  benefitsFlow
}: TFormValues) => {
  const states = useAppSelector(selectStates);
  const [selectOptions, setSelectOptions] = useState<AddressOption[]>([]);

  const { field, fieldState } = useController({
    control,
    defaultValue: '',
    name: 'address',
    rules: {
      pattern: {
        message: 'Address is invalid',
        value: ADDRESS_REGEXP
      },
      required: {
        message: 'Address is required',
        value: true
      }
    }
  });

  const handleGetSuggestionsThen = (response: SmartySDK.usAutocompletePro.Lookup) => {
    const options = response.result.map((item) => {
      const updatedAddress = buildAddress(item, states);
      return {
        label: `${updatedAddress.address} ${updatedAddress.city}, ${updatedAddress.state}, ${updatedAddress.zipCode}`,
        value: `${updatedAddress.address}`,
        ...item,
        zipCode: item.zipcode
      };
    });

    setSelectOptions(options);
  };

  const queryAutoComplete = useCallback(
    debounce(async (query: string) => {
      if (!query.trim()) {
        return;
      }
      autoCompleteClient
        .send(new SmartySDK.usAutocompletePro.Lookup(query))
        .then(handleGetSuggestionsThen)
        .catch(handleRequestCatch);
    }, 500),
    []
  );

  const handleChangeInput = (val: string) => {
    queryAutoComplete(val);
    field.onChange(val);
  };

  const addressOnChange = (option: AddressOption | null) => {
    if (option) {
      setValue('address', option.value);
      setValue('city', option.city);
      setValue('state', states.find((el) => el.value === option.state)?.label ?? '');
      setValue('zipCode', option.zipCode);
    }
    ['address', 'city', 'state', 'zipCode'].forEach((field) => trigger(field));
  };

  const handleClickOption = (option?: Option) => {
    const selectedOption = selectOptions.find((item) => item.label === option?.label);
    if (selectedOption) {
      addressOnChange(selectedOption);
    }
  };

  return (
    <div
      className={classNames('address-grid', {
        'gap-4': !benefitsFlow && !isDividedToTwoColumns,
        'gap-x-4 gap-y-6': benefitsFlow,
        'gap-x-6 gap-y-3': isDividedToTwoColumns && !benefitsFlow
      })}
    >
      <div className="col-span-6" data-testid="address_dropdown">
        <Common.AutocompleteInputSelect
          {...field}
          errors={fieldState.error}
          helperText={fieldState.error?.message}
          inputValue={field.value}
          label="Street"
          options={selectOptions.map((p, i) => ({
            ...p,
            customClassName: size === 'lg' ? 'text-mBase' : '',
            id: String(i)
          }))}
          placeholder="Enter address..."
          selected={{ id: field.value, label: field.value, value: field.value }}
          size={size}
          onChange={handleClickOption}
          onInputChange={(v) => handleChangeInput(v.target.value)}
        />
      </div>
      <div className={classNames('col-span-6', isDividedToTwoColumns ? '' : 'md:col-span-2')}>
        <TextInput
          control={control}
          dataTestId="city_field"
          invalidErrorMsg="City is invalid"
          label="City"
          name="city"
          placeholder="Enter city..."
          regExp={CITY_REGEXP}
          requiredErrorMsg="City is required"
          size={size}
        />
      </div>
      <div className={isDividedToTwoColumns ? 'col-span-3' : 'col-span-6 md:col-span-2'}>
        <StateSelect
          control={control}
          dataTestId="state_dropdown"
          disabled={isStatePickerDisabled}
          label="State"
          name="state"
          size={size}
        />
      </div>
      <div className={isDividedToTwoColumns ? 'col-span-3' : 'col-span-6 md:col-span-2'}>
        <ZipCodeInput
          control={control}
          dataTestId="zip_field"
          label="ZIP code"
          name="zipCode"
          placeholder={benefitsFlow ? '00000' : 'ZIP code'}
          size={size}
        />
      </div>
    </div>
  );
};

export default Address;
