import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from 'utils/services';

import {
  CheckMedicationCoverageResProps,
  SignAgreementsReqProps,
  SignAgreementsResProps
} from './patients.types';

export const patientsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    checkMedicationCoverage: build.query<CheckMedicationCoverageResProps, string>({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken
        },
        url: '/patients/benefits'
      })
    }),
    signAgreements: build.mutation<SignAgreementsResProps, SignAgreementsReqProps>({
      query: ({ patientId, ...body }) => ({
        body,
        method: 'POST',
        url: `/patients/${patientId}/agreements`
      })
    })
  }),
  reducerPath: 'patientsApi',
  tagTypes: ['Patients']
});

export const { useLazyCheckMedicationCoverageQuery, useSignAgreementsMutation } = patientsApi;
