import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';
import dayjs from 'dayjs';

import { useGenerateDynamicLinkMutation, useGetShortTermTokenMutation } from 'services/auth/auth';
import {
  DynamicLinkActions,
  GenerateDynamicLinkReqProps,
  GenerateDynamicLinkResProps
} from 'services/general/general.types';

import { selectSignUp, selectUser } from 'store';
import { clearUserState } from 'store/signup/signupSlice';
import { setUser } from 'store/user/userSlice';

import WelcomeToUnlimited from 'modals/WelcomeToUnlimited';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import ButtonAddToCalendar from 'shared/ButtonAddToCalendar';
import VerificationBanner from 'widgets/VerificationBanner';

import { useAppDispatch, useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import useContinueInWeb from 'hooks/useContinueInWeb';
import useWidth from 'hooks/useWidth';
import { DateFormat, FlowTypes, PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { ConfirmationProps } from './confirmation.types';

const Confirmation: React.FC<ConfirmationProps> = ({
  appointment: { startTime, endTime, id, isStartsSoon, title, subtitle, apptName = '' },
  isPopupOpen,
  closePopup,
  providerId,
  src,
  showActionRequiredBanner = true
}) => {
  const logEvent = useAnalytics();
  const { isMobile } = useWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [generateDynamicLink, { isLoading }] = useGenerateDynamicLinkMutation();
  const [getToken, { isLoading: isGettingToken }] = useGetShortTermTokenMutation();

  const { user, firstAppointment } = useAppSelector(selectSignUp);
  const { accessToken } = useAppSelector(selectUser);
  const isSignUpPage = location.pathname.includes(PathName.SignUp);
  const isB1LikeFlow = location.pathname.includes(FlowTypes.BasicFlow);

  const continueInWeb = useContinueInWeb(user);

  const clearUserData = () => {
    dispatch(
      setUser({
        isEmailVerified: false,
        profileImage: '',
        userId: user._id
      })
    );
    dispatch(clearUserState());
    sessionStorage.removeItem('session-id');
  };

  const handleGenerateDynamicLinkThen = ({ data }: GenerateDynamicLinkResProps) => {
    setTimeout(() => window.open(data.dynamicLink, '_top'));
  };

  const handleGenerateDynamicLink = async (action = DynamicLinkActions.DASHBOARD) => {
    try {
      const tokenData = await getToken(isSignUpPage ? user.accessToken : accessToken).unwrap();
      const body: GenerateDynamicLinkReqProps = {
        accessToken: isSignUpPage ? user.accessToken : accessToken,
        action,
        src: src === 'appMobile' ? src : 'signUp',
        ...(isSignUpPage && {
          appointmentId: firstAppointment._id,
          doctorId: providerId,
          expiredToken: tokenData.data.token
        }),
        ...(action === 'waiting-room' && {
          appointmentId: id,
          doctorId: providerId
        })
      };
      await generateDynamicLink(body).unwrap().then(handleGenerateDynamicLinkThen);
    } catch (e) {
      handleRequestCatch(e as MessageEvent);
    } finally {
      if (isSignUpPage) clearUserData();
    }
  };

  const onClickEnterWaitingRoom = () => {
    logEvent('appt_schd_succ_waiting_room_btn_click');
    if (src === 'appMobile') {
      handleGenerateDynamicLink(DynamicLinkActions.WAITING_ROOM);
    } else {
      isSignUpPage
        ? continueInWeb(id)
        : navigate({
            pathname: PathName.ZoomCall,
            search: `callId=${id}`
          });
    }
  };

  const onClickBackToTheDashboard = () => {
    logEvent('appt_schd_succ_dashboard_btn_click');
    src === 'appMobile'
      ? handleGenerateDynamicLink(DynamicLinkActions.DASHBOARD)
      : navigate(PathName.Home);
  };

  const fillUserWithRedirect = () => {
    isMobile ? handleGenerateDynamicLink(DynamicLinkActions.DASHBOARD) : continueInWeb();
  };

  const handleClickTakeToDashboard = () => {
    isSignUpPage ? fillUserWithRedirect() : onClickBackToTheDashboard();
  };

  const handleClicAddToCalendar = () => {
    logEvent('appt_schd_succ_add_calendar_btn_click');
  };

  return (
    <>
      {closePopup && <WelcomeToUnlimited isOpen={!!isPopupOpen} onClose={closePopup} />}
      <SlideAnimateWrapper className="flex h-full grow flex-col gap-4 md:gap-8">
        {showActionRequiredBanner && <VerificationBanner />}
        <Common.Icon
          className="mx-auto size-[112px] flex-none rounded-full bg-secondary-100 p-8 text-secondary"
          name="check"
        />
        <div className="mb-4 flex flex-col gap-2">
          <h1
            className="text-center text-m2xl font-bold"
            data-testid="appointment_scheduled_heading"
          >
            {title}
          </h1>
          <p className="flex flex-col text-center text-mBase font-medium text-gray-700">
            {subtitle && <span>{subtitle}</span>}
            <span className="text-gray">Video-based appointment</span>
          </p>
        </div>
        <div className="flex flex-col items-center gap-8">
          <Common.Appointment
            description={
              startTime ? dayjs(startTime).format(DateFormat.MMM_D_h_mma_z) : 'ASAP appointment'
            }
            startTime={startTime}
            title={apptName}
            type="default"
            onClick={onClickEnterWaitingRoom}
          />
          {!isStartsSoon && (
            <div className="md:w-auto">
              <ButtonAddToCalendar
                dataTestId="add_to_calendar_btn"
                endsAt={endTime}
                id={id}
                name={apptName ? `Your LifeMD appointment re: ${apptName}` : title}
                startsAt={startTime}
                onClick={handleClicAddToCalendar}
              />
            </div>
          )}
        </div>
        <div className="flex justify-end gap-3 max-md:h-full max-md:flex-col md:justify-center md:gap-4">
          <Common.Button
            color="blue"
            dataTestId="take_to_dashboard_btn"
            isLoading={isGettingToken || isLoading}
            fullWidthOnMobile
            onClick={handleClickTakeToDashboard}
          >
            Take me to my dashboard
          </Common.Button>
          {(!isSignUpPage || isB1LikeFlow) && (
            <Common.Button
              color={isStartsSoon ? 'blue' : 'white-alt'}
              dataTestId="enter_waiting_room_btn"
              isLoading={isLoading || isGettingToken}
              fullWidthOnMobile
              onClick={onClickEnterWaitingRoom}
            >
              {isStartsSoon ? 'Join appointment' : 'Enter waiting room'}
            </Common.Button>
          )}
        </div>
      </SlideAnimateWrapper>
    </>
  );
};

export default Confirmation;
