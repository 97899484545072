import { NavLink } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { PathName } from 'utils/enums';

import { CartItemProps } from './cartItem.types';

const CartItem: React.FC<CartItemProps> = ({ product, onDelete }) => {
  return (
    <NavLink
      className="flex items-center justify-between gap-3 p-4"
      to={PathName.ShopProducts + '/' + product.productId}
    >
      <img
        alt="product"
        className="size-[120px] flex-none rounded-lg object-contain"
        src={product.product.featuredAsset.url}
      />
      <div className="flex grow flex-col justify-between self-stretch">
        <div>
          <p className="flex justify-between text-mBase font-semibold text-gray-800">
            <span data-testid="product_in_cart_name">{product.product.name}</span>
            <span className="text-gray-800" data-testid="product_in_cart_price">
              ${product.product.unitPrice}
            </span>
          </p>
          <span className="text-mSm text-gray" data-testid="product_in_cart_type">
            {product.product.type}
          </span>
        </div>
        <button
          className="flex w-fit items-center rounded-full bg-gray-200 p-2 transition-all"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <Common.Icon className="flex-none" name="trash" />
          <span className="w-6 text-sm font-bold">1</span>
        </button>
      </div>
    </NavLink>
  );
};

export default CartItem;
