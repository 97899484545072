// import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

// import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';
// import { useSignAgreementsMutation } from 'services/patients/patients';
// import { selectUser } from 'store';
import { selectNewAppointmentExtended } from 'store';

// import CheckboxLabel from 'features/CheckboxLabel';
import { DEFAULT_LAB_TESTS_APPT_CODE } from 'constants/defaults';
import { LINK_TO_FAQ } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { RED_FLAGS } from 'utils/constants';
import {
  findAppointmentTypeByData
  // handleRequestCatch
} from 'utils/helpers';

import { RedFlagsProps } from './redFlags.types';

import CalendarSvg from 'assets/icons/ambulance.svg?react';

const RedFlags: React.FC<RedFlagsProps> = ({
  onSelect,
  onSkip,
  selectedFlow,
  loading,
  appointmentTypes,
  src
}) => {
  // const { accessToken, agreements, userId } = useAppSelector(selectUser);
  const logEvent = useAnalytics();
  // const [isAccepted, toggleAccepted] = useToggle(false);
  // const [signAgreements, { isLoading }] = useSignAgreementsMutation();
  // const [getMyAccount] = useLazyGetMyAccountQuery();

  // const termsWasSigned = accessToken && !agreements?.lifemdOnboardingTermsV2AgreedTime;
  const { predefinedAppointmentQuery } = useAppSelector(selectNewAppointmentExtended);

  const handleClickCancel = () => {
    logEvent('appt_agreement_cancel_btn_click');
    onSkip?.();
  };

  const handleClickAgree = () => {
    logEvent('appt_agreement_continue_btn_click');
    const predefinedAppointmentType =
      src === 'labs-appt'
        ? appointmentTypes?.find((item) => item.code === DEFAULT_LAB_TESTS_APPT_CODE)
        : predefinedAppointmentQuery
          ? (findAppointmentTypeByData(appointmentTypes, predefinedAppointmentQuery) ?? undefined)
          : undefined;
    // if (isAccepted) {
    //   signAgreements({ patientId: userId, lifemdOnboardingTermsV2AgreedTime: new Date() })
    //     .unwrap()
    //     .then(() => {
    //       getMyAccount();
    //       onSelect(predefinedAppointmentType);
    //     })
    //     .catch(handleRequestCatch);
    // } else {
    onSelect(predefinedAppointmentType);
    // }
  };

  const handleClickFAQ = () => {
    logEvent('appt_agreement_faq_link_click');
  };

  // const handleChangeTermsAndCond = () => {
  //   logEvent('wm_check_in_terms_chbox_click');
  //   toggleAccepted();
  // };

  return (
    <div className="flex flex-col gap-6 rounded-2xl bg-white p-4 max-md:border md:gap-4 md:px-12 md:py-8 md:shadow">
      <CalendarSvg className="mx-auto text-primary-100 md:size-[170px]" />
      <h2 className="text-mLg font-bold text-primary-700 md:text-xl" data-testid="heading">
        LifeMD virtual appointments are not intended for medical emergencies or for certain health
        conditions.
      </h2>
      <p className="font-medium">
        If you or a loved one are experiencing any of the following medical issues or any other
        critical or life-threatening situation, call 911 or your local emergency service right away:
      </p>
      <ul
        className="ml-4 flex list-disc flex-col flex-wrap gap-x-4 gap-y-0.5 font-bold md:mx-4 md:ml-6 md:max-h-[410px] md:gap-y-1"
        data-testid="red_flags_list"
      >
        {RED_FLAGS.map((item) => (
          <li className="md:w-[240px]" key={nanoid()}>
            {item}
          </li>
        ))}
      </ul>
      <Common.Alert type="info" colorableBackground>
        LifeMD does not provide prescriptions or refills for controlled substances such as
        benzodiazepines, narcotics or amphetamines. Read more in our&nbsp;
        <a
          className="underline"
          href={LINK_TO_FAQ}
          target="_blank"
          onClick={handleClickFAQ}
          rel="noreferrer"
        >
          FAQ
        </a>
        .
      </Common.Alert>
      {/*{termsWasSigned && (*/}
      {/*  <Common.Checkbox checked={isAccepted} color="blue" onChange={handleChangeTermsAndCond}>*/}
      {/*    <CheckboxLabel isChecked={isAccepted} placement="wm-flow" />*/}
      {/*  </Common.Checkbox>*/}
      {/*)}*/}
      <div
        className={classNames(
          'flex flex-col items-center justify-center gap-3 md:mx-auto md:mt-6',
          selectedFlow ? 'md:max-w-[190px]' : 'md:max-w-[388px]'
        )}
      >
        <Common.Button
          color="blue"
          data-testid="agree_or_and_continue_btn"
          dataTestId="agree_or_and_continue_btn"
          // disabled={(termsWasSigned && !isAccepted) || loading}
          disabled={loading}
          // isLoading={isLoading}
          fullWidthOnMobile
          onClick={handleClickAgree}
        >
          Agree and continue
        </Common.Button>
        <Common.Button
          color="white-alt"
          dataTestId="cancel_btn"
          fullWidthOnMobile
          onClick={handleClickCancel}
        >
          Cancel
        </Common.Button>
      </div>
    </div>
  );
};

export default RedFlags;
