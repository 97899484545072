import { Common } from '@thecvlb/design-system/lib/src';

import { TimeLineSection } from '../AppointmentStatus/TimeLine';

interface PreCheckoutProps {
  haveToPayForAppt: boolean;
  loading?: boolean;
  onContinue: () => void;
  title: string;
}

const TimeLine = (title: string, description: string) => (
  <TimeLineSection>
    <p
      className={`text-mSm font-medium ${description === 'Complete' ? 'text-gray-500' : 'text-gray-700'}`}
    >
      {title}
    </p>
    <p className="text-mSm font-medium text-gray-500">{description}</p>
  </TimeLineSection>
);

const PreCheckout: React.FC<PreCheckoutProps> = ({
  onContinue,
  title,
  loading,
  haveToPayForAppt
}) => {
  return (
    <div className="mx-auto flex h-full grow flex-col justify-between md:max-w-[664px]">
      <div className="flex flex-col gap-8">
        <div className="text-center">
          <Common.Icon
            className="mx-auto size-[96px] rounded-full bg-secondary-100 p-8 text-secondary"
            name="video-outline"
          />
          <h1 className="mt-4 text-m2xl font-bold">{title}</h1>
          <p className="mt-1 text-mBase font-medium text-gray-500">Video-based consult</p>
        </div>
        <div className="flex flex-col gap-4">
          {!!haveToPayForAppt && (
            <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
              <h2 className="text-mBase font-bold text-primary-400">Check out</h2>
              <p className="font-medium text-gray-700">
                Confirm payment to reserve your appointment.
              </p>
            </div>
          )}

          {TimeLine('Medical intake form', 'Verify your identity and complete a brief survey.')}
          {TimeLine(
            'Meet with next available provider',
            'Once you’ve completed your intake form, you’ll be added to the queue for a video appointment.'
          )}
        </div>
      </div>
      <Common.Button
        className="mx-auto md:mt-6"
        color="blue"
        isLoading={loading}
        size="lg"
        fullWidthOnMobile
        onClick={onContinue}
      >
        {haveToPayForAppt ? 'Continue to checkout' : 'Continue'}
      </Common.Button>
    </div>
  );
};

export default PreCheckout;
