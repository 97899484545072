import React, { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import { ActiveSpeaker } from '@zoom/videosdk/dist/types/event-callback';
import classNames from 'classnames';

import VoiceIndicator from 'widgets/zoom/VoiceIndicator';

import { useZoomCall } from 'hooks';
import useWidth from 'hooks/useWidth';

const LocalParticipant = () => {
  const { videoEnabled, room, audioEnabled, isMinimize, isOpenChat } = useZoomCall();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { isMobile } = useWidth();
  const isMinimizeOrMobile = isMinimize || (isMobile && isOpenChat);

  const activeSpeakerHandler = (payload: Array<ActiveSpeaker>) => {
    const user = payload.find((el) => el.userId === room?.getCurrentUserInfo()?.userId);
    if (!user) return;
    setIsSpeaking(true);
    setTimeout(() => setIsSpeaking(false), 1000);
  };

  useEffect(() => {
    room?.on('active-speaker', activeSpeakerHandler);
    return () => {
      room?.off('active-speaker', activeSpeakerHandler);
    };
  }, []);

  const videoPlayer = (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <video-player-container
      className={classNames('size-full bg-gray-700', {
        hidden: !videoEnabled
      })}
      id="local-video"
    />
  );

  return (
    <div
      className={classNames(
        'absolute bottom-[150px] left-4 z-10 h-auto overflow-hidden rounded-2xl border bg-white shadow-mXl md:bottom-4',
        {
          'aspect-video w-[148px]': videoEnabled,
          hidden: isMinimizeOrMobile,
          'p-[18px]': !videoEnabled
        }
      )}
    >
      {videoPlayer}
      {(!videoEnabled || isSpeaking) && audioEnabled && (
        <VoiceIndicator isSpeaking={isSpeaking} videoEnabled={videoEnabled} local />
      )}
      {!audioEnabled && (
        <Common.Icon
          className={classNames('right-1 top-1 size-7 rounded-full p-1 text-white bg-black/40', {
            absolute: videoEnabled
          })}
          name="microphone-off"
        />
      )}
    </div>
  );
};

export default LocalParticipant;
