import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system';

import { selectOrchestrate } from 'store';

import { ReducedSignupStepProps, WmFlowType } from 'containers/SignUp/Content/content.types';
import RadioGroup from 'shared/RadioGroup';

import { YES_NO_OPTIONS } from 'constants/formFields';
import { useAppSelector, useQuery } from 'hooks';
import { useGetSelectedItem } from 'hooks/useGetSelectedItem';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { PathName } from 'utils/enums';
import { buildSearch } from 'utils/helpers';

import Heading from '../../components/Heading';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';

const GallStones: React.FC<ReducedSignupStepProps & { flow?: WmFlowType }> = ({
  moveToStep,
  flow = 'onboarding'
}) => {
  const { value, setValue } = useGetSelectedItem('gallstones_issues');

  const navigate = useNavigate();
  const query = useQuery();
  const { send, isLoading } = useSubmitOrchestrateForm();
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);

  const handleSelect = (v: boolean) => {
    setValue(v);
    if (v === true) {
      navigate({
        search: buildSearch(
          flow === 'authorized' ? 's=wm-gallstones-question&' : '',
          `hideProgress=true&subStep=fail-gallstones-question&answer=${v}`
        )
      });
      return;
    } else {
      send(
        'mif_qa',
        [{ answer: v, question: 'gallstones_issues' }],
        () => {
          setValue(undefined);
          moveToStep('next');
        },
        undefined,
        () => {
          setValue(undefined);
        }
      );
    }
  };

  const handleProspect = () => {
    if (flow === 'authorized') {
      return navigate(PathName.Home);
    }
    const answer = query.get('answer');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer: answer === 'true', question: 'history_of_gallstones' }],
        () => {
          moveToStep(-1);
        },
        'PROSPECT'
      );
    } else {
      moveToStep(-1);
    }
  };

  const isErrorState = query.get('subStep') === 'fail-gallstones-question';

  return (
    <div className="flex flex-col gap-6">
      {isErrorState ? (
        <>
          <h2 className="wm-signup-title text-center" data-testid="header">
            We regret to inform you that you are not suitable for GLP-1 medication.
          </h2>
          <Common.Alert type="warning" colorableBackground>
            Based upon your answers, you are not a suitable candidate for GLP-1 medication.
          </Common.Alert>
          <div className="mt-auto flex flex-col items-center gap-3">
            <Common.Button fullWidthOnMobile onClick={() => navigate(-1)}>
              Made a mistake? Update response
            </Common.Button>
            <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
          </div>
        </>
      ) : (
        <>
          <Heading category="About you" title="Do you have known gallstones?" />
          <RadioGroup
            isLoading={isLoading}
            items={YES_NO_OPTIONS}
            selectedItem={value as boolean | undefined}
            onSelect={handleSelect}
          />
        </>
      )}
    </div>
  );
};

export default GallStones;
