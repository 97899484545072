import { useEffect, useState } from 'react';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { useDeleteDocumentMutation } from 'services/documents/documents';

import FileZone from 'features/FileZone';
import { DocsInfo } from 'features/FileZone/fileZone.types';
import { notifyError } from 'shared/Toast/Toast';
import ErrorMessages from 'widgets/identityVerification/ErrorMessages';

import { GOVERNMENT_ID_ERRORS } from 'constants/defaults';
import { useUploadDocument } from 'hooks/useUploadDocument';

import { GovernmentIDProps } from './governmentID.types';

const INSTRUCTIONS = [
  'Photo must be clean with all edges visible.',
  'Full name and date of birth must be legible.',
  'Your name must match that on your ID.'
];

const GovernmentID: React.FC<GovernmentIDProps> = ({
  onFileStatusUpdate,
  initialValue,
  accessToken,
  verifyIdentity
}) => {
  const [fileData, setFileData] = useState<DocsInfo['government']>({
    _id: undefined,
    fileName: '',
    filePath: '',
    fileStatus: 'initial',
    identity: null
  });

  const [deleteDocument] = useDeleteDocumentMutation();
  const { isLoading, uploadFile } = useUploadDocument();

  const handleUpload = async (files: File[]) => {
    try {
      if (fileData._id) {
        await deleteDocument({
          accessToken,
          documentId: fileData._id,
          sourcePage: 'verification'
        }).unwrap();
      }
      setFileData((prev) => ({
        ...prev,
        fileStatus: 'processing'
      }));
      const { data, error } = await uploadFile(files, {
        category: 'user-identity',
        subCategory: 'government-issued-id'
      });
      if (error !== null) {
        setFileData((prev) => ({
          ...prev,
          fileStatus: 'initial'
        }));
        notifyError(error);
        onFileStatusUpdate();
      } else {
        setFileData((prev) => ({
          ...prev,
          ...data,
          fileStatus: 'success'
        }));
        onFileStatusUpdate();
      }
    } catch {
      setFileData((prev) => ({
        ...prev,
        fileStatus: 'initial'
      }));
    }
  };

  const handleDelete = (documentId: string) => {
    deleteDocument({ accessToken, documentId, sourcePage: 'verification' })
      .unwrap()
      .then(onFileStatusUpdate);
  };

  useEffect(() => {
    setFileData({
      ...initialValue,
      ...(!!accessToken && { fileStatus: !!initialValue._id ? 'success' : 'initial' })
    });
  }, [initialValue]);

  const errors = (fileData.identity?.errors || []).filter(({ type }) =>
    GOVERNMENT_ID_ERRORS.includes(type)
  );

  const isSuccessful = fileData._id && !errors.length;

  return (
    <>
      <div className="flex flex-col items-center gap-4 text-center max-md:mt-6">
        <Common.Icon
          className="size-20 rounded-full text-primary-400 max-md:bg-primary-50 max-md:p-4 md:size-12"
          name="identification"
        />
        <h2 className="verify-profile-heading">Upload a government issued ID</h2>
        <p className="text-mSm text-gray-600 md:text-sm">
          <b>
            Accepted forms of ID: <br />
          </b>
          Driver’s License, ID Card, or Passport
        </p>
        <div className="flex flex-col gap-1">
          {INSTRUCTIONS.map((text, index) => (
            <div
              className={classNames('flex items-center gap-1', { 'text-gray-600': !isSuccessful })}
              key={index}
            >
              <Common.Icon
                className={classNames('size-4 flex-none', { 'text-green': isSuccessful })}
                name={isSuccessful ? 'check' : 'arrow-alt-right'}
              />
              <p className="text-mSm">{text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full max-w-[267px] md:my-4" data-testid="government_id">
        <FileZone
          accept="image/png, image/jpeg, image/jpg, .heic"
          loading={isLoading}
          status={fileData}
          type="Front"
          onDelete={handleDelete}
          onSelect={handleUpload}
        />
      </div>
      <ErrorMessages
        filesIdentified={!!fileData.identity}
        filesVerified={!!fileData.identity?.isVerified}
        mergedErrors={errors}
        verifyIdentity={verifyIdentity}
      />
    </>
  );
};

export default GovernmentID;
