import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';

import CompleteYourProfile from 'modals/CompleteYourProfile';

import { useCompleteProfile } from 'hooks/useCompleteProfile';
import { PathName } from 'utils/enums';

import { VerificationBannerKeysProps, VerificationBannerProps } from './verificationBanner.types';
const errorMessages = {
  address: 'We need your address in order to prescribe any medications.',
  bodyImage:
    'Finish your profile. In order to serve you better, your provider has a few questions about your medical history.',
  insurance: 'We need to verify your insurance in order to prescribe any medications.',
  isVerifiedByVouched: 'We need to verify your identity in order to prescribe any medications.',
  miCompletionStatus:
    'You need to complete your profile in order for us to prescribe you any medications.'
} as const;
const VerificationBanner: React.FC<VerificationBannerProps> = ({ text }) => {
  const navigate = useNavigate();
  const { filteredKeys, refresh, isFetching } = useCompleteProfile();
  const [isOpenCompleteProfile, toggleCompleteProfile] = useToggle(false);

  const getText = () => {
    return filteredKeys.length === 1
      ? errorMessages[(filteredKeys as VerificationBannerKeysProps)[0]]
      : errorMessages.miCompletionStatus;
  };

  const handleClose = () => {
    refresh();
    toggleCompleteProfile(false);
  };

  if (!filteredKeys.length || isFetching) {
    return null;
  }

  return (
    <>
      <CompleteYourProfile
        isOpen={isOpenCompleteProfile}
        keys={filteredKeys as VerificationBannerKeysProps}
        onClose={handleClose}
      />
      <Common.Alert type="error" colorableBackground>
        <p>
          <b>Action required: </b>
          {text || getText()}
        </p>
        <Common.Button
          className="mt-2"
          color="white-alt"
          onClick={() => navigate(PathName.IntakeForm)}
        >
          Complete profile
        </Common.Button>
      </Common.Alert>
    </>
  );
};

export default VerificationBanner;
