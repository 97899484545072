import { JSX } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { BLUE_LINE_ONBOARDING_PRICE_POINTS } from 'constants/pricepoints';
import { FlowTypes } from 'utils/enums';
import { calculateDiscountPercentageByPrices, parseDecimal } from 'utils/helpers';

import { PricePoint } from 'models/plans.types';

export const getDynamicPPContent = (
  pricePoint: PricePoint,
  flow: FlowTypes | 'authorized'
): {
  additionalText?: string;
  discountTag: JSX.Element | null;
  subheaderText: string;
  totalValue: string;
} => {
  if (!pricePoint) {
    return {
      discountTag: null,
      subheaderText: '',
      totalValue: ''
    };
  }
  if (flow === FlowTypes.WeightManagementBalladHealth) {
    switch (pricePoint.paymentInterval.qty) {
      case 1:
        return {
          discountTag: <Common.ColorTag color="secondary" text="40% OFF" />,
          subheaderText: 'Save $59 Today ',
          totalValue: `${Number(pricePoint.totalCost) - 59}`
        };
      case 3:
        return {
          additionalText: 'Pay-in-full every 3 months',
          discountTag: <Common.ColorTag color="secondary" text="50% OFF" />,
          subheaderText: `$${parseDecimal((Number(pricePoint.totalCost) - 222) / pricePoint.paymentInterval.qty)}/mo`,
          totalValue: `${Number(pricePoint.totalCost) - 222}`
        };
      default:
        return {
          discountTag: null,
          subheaderText: 'Billed monthly',
          totalValue: pricePoint.totalCost
        };
    }
  }

  const isInsurancePP = pricePoint.categories?.includes('insurance');
  const isBlueLinePP = BLUE_LINE_ONBOARDING_PRICE_POINTS.includes(pricePoint.planPricePointId);

  if (isInsurancePP) {
    return {
      discountTag: null,
      subheaderText: `Renews at $${pricePoint.totalCost}/${pricePoint.paymentInterval.qty === 1 ? 'mo' : pricePoint.paymentInterval.qty + ' months'}`,
      totalValue: pricePoint.totalCost
    };
  }

  if (isBlueLinePP) {
    return {
      discountTag: (
        <Common.ColorTag
          color="secondary"
          text={`${calculateDiscountPercentageByPrices(59, 149)}% OFF`}
        />
      ),
      subheaderText: 'Save $90 Today ',
      totalValue: pricePoint.totalCost
    };
  }

  if (flow === FlowTypes.WeightManagementFlowInsuranceDiscovery) {
    switch (pricePoint.paymentInterval.qty) {
      case 1:
        return {
          discountTag: <Common.ColorTag color="secondary" text="Save $74" />,
          subheaderText: `Save $74 Today`,
          totalValue: `${Number(pricePoint.totalCost) - 74}`
        };
      case 3:
        return {
          discountTag: <Common.ColorTag color="secondary" text="Save 25%" />,
          subheaderText: `Lifetime discount of 25%!`,
          totalValue: `${Number(pricePoint.totalCost) - 112}`
        };
      default:
        return {
          discountTag: null,
          subheaderText: 'Billed monthly',
          totalValue: pricePoint.totalCost
        };
    }
  }

  switch (pricePoint.paymentInterval.qty) {
    case 1:
      return {
        discountTag: null,
        subheaderText: 'Billed monthly',
        totalValue: pricePoint.totalCost
      };
    case 3:
      return {
        discountTag: <Common.ColorTag color="secondary" text="Save 15%" />,
        subheaderText: `Save $${parseDecimal((Number(pricePoint.totalCost) * parseFloat('15%')) / 100)} Today`,
        totalValue: `${(Number(pricePoint.totalCost) * parseFloat('85%')) / 100}`
      };
    case 6:
      return {
        discountTag: <Common.ColorTag color="secondary" text="Save 25%" />,
        subheaderText: `Save $${parseDecimal((Number(pricePoint.totalCost) * parseFloat('25%')) / 100)} Today`,
        totalValue: `${parseDecimal((Number(pricePoint.totalCost) * parseFloat('75%')) / 100)}`
      };
    case 12:
      return {
        discountTag: <Common.ColorTag color="secondary" text="Save 50%" />,
        subheaderText: `Save $${parseDecimal((Number(pricePoint.totalCost) * parseFloat('50%')) / 100)} Today`,
        totalValue: `${parseDecimal((Number(pricePoint.totalCost) * parseFloat('50%')) / 100)}`
      };
    default:
      return {
        discountTag: null,
        subheaderText: 'Billed monthly',
        totalValue: pricePoint.totalCost
      };
  }
};
