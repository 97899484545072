import { Common } from '@thecvlb/design-system';

import { SocialHistoryItemProps } from 'store/mif/mif.types';

import ListItemWrapper from 'shared/animationWrappers/ListItemWrapper';

import { SOCIAL_HISTORY_FORM_FIELDS } from 'constants/formFields';

import { FieldType, SocialHistoryProps } from './socialHistory.types';

const SocialHistory: React.FC<SocialHistoryProps> = ({
  loading = false,
  isPostOnboarding,
  onUpdate,
  values
}) => {
  const NONE_ELEMENT = SOCIAL_HISTORY_FORM_FIELDS.find((e) => e.id === '0');
  const handleToggle = (id: string, type: FieldType) => {
    if (values.some((item) => item.name === id)) {
      onUpdate(values.filter((item) => item.name !== id));
    } else {
      if (id === NONE_ELEMENT?.id) {
        onUpdate([{ name: NONE_ELEMENT.id }]);
      } else {
        const newItems = [
          ...values,
          {
            name: id,
            ...(type === 'select' && { useFrequency: '0' }),
            ...(type === 'textarea' && { notes: '' })
          }
        ].filter((n) => n.name !== NONE_ELEMENT?.id) as SocialHistoryItemProps[];
        onUpdate(newItems);
      }
    }
  };

  const handleChange = (value: string, fieldID: string, type: FieldType) => {
    const newFields = values.map((item) => {
      if (item.name !== fieldID) return item;
      switch (type) {
        case 'select':
          return { ...item, useFrequency: value as SocialHistoryItemProps['useFrequency'] };
        case 'textarea':
          return { ...item, notes: value as string };
        default:
          return item;
      }
    });
    onUpdate(newFields);
  };

  return (
    <div className="flex flex-col gap-2">
      {SOCIAL_HISTORY_FORM_FIELDS.map((field) => {
        const value = values.find((v) => v.name === field.id);
        return (
          <ListItemWrapper dataTestId="social_history" key={field.id}>
            <Common.RichCheckbox
              checked={field.id === value?.name}
              className="!overflow-visible"
              dataTestId="checkbox"
              disabled={loading}
              key={field.id}
              size={isPostOnboarding ? undefined : 'lg'}
              label={`${field.label}${loading ? ' - saving...' : ''}`}
              onChange={() => handleToggle(field.id, field.type)}
            >
              {field.additional && value && (
                <>
                  {field.type === 'select' && value.useFrequency && (
                    <Common.SelectAlt
                      disabled={loading}
                      label={field.additional.label}
                      options={field.additional.options || []}
                      value={value.useFrequency}
                      hideSuccessState
                      onChange={(v) => handleChange(v, field.id, field.type)}
                    />
                  )}
                  {field.type === 'textarea' && (
                    <Common.TextArea
                      dataTestId="text_area"
                      disabled={loading}
                      label={field.additional.label}
                      placeholder={field.additional.placeholder || ''}
                      value={value.notes}
                      onChange={(e) => handleChange(e.target.value, field.id, field.type)}
                    />
                  )}
                </>
              )}
            </Common.RichCheckbox>
          </ListItemWrapper>
        );
      })}
    </div>
  );
};

export default SocialHistory;
