import { useEffect, useState } from 'react';

import { selectOrchestrate } from 'store';
import { OrchestrateState } from 'store/orchestrate-signup/orchestrate.types';

import { useAppSelector } from 'hooks';

export const useGetSelectedItem = (question: string) => {
  const { mif_qa: mifQA } = useAppSelector(selectOrchestrate);
  const [value, setValue] = useState<OrchestrateState['mif_qa'][number]['answer']>();

  useEffect(() => {
    const item = mifQA.find((q) => q.question === question)?.answer ?? '';

    if (item !== undefined) {
      setValue(item);
    }
  }, []);

  return { setValue, value };
};
