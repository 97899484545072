import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { BillingHistoryItemProps } from 'services/myAccount/myAccount.types';

const ModalContent: React.FC<{ item: BillingHistoryItemProps }> = ({ item }) => {
  return (
    <div className="py-6 md:py-8">
      <div className="flex flex-col gap-4 rounded-xl border border-gray-200 bg-gray-50 p-4">
        <p className="flex justify-between md:text-lg md:font-bold">
          <span>{item.invoice.toString().split('-')[0]}</span>
          <span className={classNames({ 'text-gray line-through': item.refundAmount })}>
            ${+item.amount + +item.discountAmount}
          </span>
        </p>
        {!!item.refundAmount ? (
          <>
            {!!item.discountAmount && (
              <p className="flex justify-between font-bold text-gray md:text-lg">
                <span>Discount</span>
                <span className="line-through">-${item.discountAmount}</span>
              </p>
            )}
            {!!item.credit && (
              <p className="flex justify-between text-gray">
                <span>LifeMD credit</span>
                <span className={classNames({ 'text-gray line-through': item.refundAmount })}>
                  -${item.credit}
                </span>
              </p>
            )}
            <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
              <p className="flex justify-between font-bold md:text-lg">
                <span>Total paid</span>
                <span className={classNames({ 'text-gray line-through': item.refundAmount })}>
                  ${item.paidAmount}
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
              <p className="flex justify-between font-bold md:text-lg">
                <span>Total refunded</span>
                <span className="text-green">+${item.refundAmount}</span>
              </p>
              <p className="text-gray">
                Refunded to {item.cardBrand} – {item.maskedCardNumber || item.email}
              </p>
            </div>
          </>
        ) : (
          <>
            {item.discountTitle && (
              <p className="flex justify-between text-orange md:text-lg md:font-bold">
                <span>{item.discountTitle}</span>
                <span>-${item.discountAmount}</span>
              </p>
            )}
            <div>
              <p className="flex justify-between font-bold md:text-lg">
                <span>Total due</span>
                <span>
                  {!!item.credit && (
                    <span className="mr-2 font-medium text-gray line-through">${item.amount}</span>
                  )}
                  ${Math.floor((+item.amount - +item.credit) * 100) / 100}
                </span>
              </p>
              {!!item.credit && (
                <p className="flex justify-between text-gray">
                  <span>LifeMD credit</span>
                  <span>-${Math.floor(+item.credit * 100) / 100}</span>
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1 border-t border-gray-200 pt-4">
              <p className="flex justify-between font-bold md:text-lg">
                <span>Amount paid</span>
                <span>${item.paidAmount}</span>
              </p>
              {item.cardBrand && (
                <p className="capitalize text-gray">
                  Payment from {item.cardBrand} – {item.maskedCardNumber || item.email}
                </p>
              )}
            </div>
          </>
        )}
      </div>
      <a href={item.invoiceUrl.toString()} target="_blank" rel="noreferrer">
        <Common.Button className="mt-6 md:mt-8" color="blue" preIcon="download" fullWidthOnMobile>
          Download
        </Common.Button>
      </a>
    </div>
  );
};

export default ModalContent;
