import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectUser } from 'store';

import { useAppSelector, useMessages } from 'hooks';
import { PathName } from 'utils/enums';

import { AppointmentStatusProps } from './appointmentStatus.types';
import { TimeLineSection } from './TimeLine';

const AppointmentStatus: React.FC<AppointmentStatusProps> = ({
  isNewTask = false,
  appointment,
  postCheckout
}) => {
  const { miCompletionStatus, identityVerified } = useAppSelector(selectUser);

  const { totalMessageCountUpdate } = useMessages();
  const navigate = useNavigate();
  const isQueue = appointment?.category === 'queue';
  const idVerificationSkippedAt = appointment?.idVerificationSkippedAt;
  const isPostOnboarded = isQueue
    ? (identityVerified || idVerificationSkippedAt) && miCompletionStatus
    : miCompletionStatus;
  const isCompleted = appointment?.status === 'careTeamSentMessage';
  const messagesCount = totalMessageCountUpdate?.totalCount || 0;
  const isMessageBased = appointment?.method === 'message';
  const apptTitle = appointment?.displayName || 'Async';
  const title = isNewTask
    ? isPostOnboarded
      ? 'You’re all set!'
      : 'Success!'
    : `${apptTitle} appointment`;

  const TimeLine = (title: string, description: string) => (
    <TimeLineSection>
      <p
        className={`text-mSm font-medium ${description === 'Complete' ? 'text-gray' : 'text-gray-700'}`}
      >
        {title}
      </p>
      <p className="text-mSm font-medium text-gray-500">{description}</p>
    </TimeLineSection>
  );

  return (
    <div className="mx-auto flex size-full grow flex-col gap-8 md:max-w-[664px]">
      <div className="text-center">
        <Common.Icon
          className="mx-auto size-[96px] rounded-full bg-secondary-100 p-8 text-secondary"
          name={isNewTask ? 'check' : isMessageBased ? 'chat-outline' : 'video-outline'}
        />
        <h1 className="mt-4 text-m2xl font-bold">{title}</h1>
        {!isPostOnboarded && isNewTask && (
          <p className="mt-1">
            <span className="font-bold">Next step:</span> We need to collect some basic medical
            information. This should only take a couple of minutes.
          </p>
        )}
        {!isNewTask && (
          <p className="mt-1 text-mBase font-medium text-gray-500">
            {isMessageBased ? 'Message' : 'Video'}-based consult
          </p>
        )}
      </div>
      {!miCompletionStatus && (
        <Common.Alert type="info" colorableBackground>
          Complete your medical intake form to secure your spot in line.
        </Common.Alert>
      )}
      <div className="flex flex-col gap-4">
        {postCheckout && TimeLine('Check out', 'Complete')}
        {isPostOnboarded && TimeLine('Medical intake form', 'Complete')}
        {isPostOnboarded && isMessageBased && !isCompleted && (
          <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
            <h2 className="text-mBase font-bold text-primary-400">Care team review</h2>
            <p className="font-medium text-gray-700">
              Next, our care team will review your answers.
            </p>
          </div>
        )}
        {isPostOnboarded && isCompleted && TimeLine('Care team review', 'Complete')}
        {!isPostOnboarded && (
          <div className="flex flex-col gap-2 rounded-xl bg-white p-4 shadow-lg">
            <h2 className="text-mBase font-bold text-primary-400">Medical intake form</h2>
            <p className="flex items-center gap-2">
              <Common.Icon
                className="size-4 text-green-500"
                name={identityVerified ? 'check-circle' : 'donut-0'}
              />
              <span className={identityVerified ? 'text-gray' : 'text-gray-700'}>
                Verify identity
              </span>
            </p>
            <p className="flex items-center gap-2">
              <Common.Icon
                className="size-4 text-green-500"
                name={miCompletionStatus ? 'check-circle' : 'donut-0'}
              />
              <span className={miCompletionStatus ? 'text-gray' : 'text-gray-700'}>
                Complete questionnaire
              </span>
            </p>
            <Common.Button
              className="mx-auto mt-2"
              color="blue"
              size="md"
              fullWidthOnMobile
              onClick={() =>
                navigate({
                  pathname: PathName.IntakeForm,
                  search: 's=verify-identity&apptId=' + appointment?.id
                })
              }
            >
              Next:{' '}
              {identityVerified && !miCompletionStatus
                ? 'Complete questionnaire'
                : 'Verify identity'}
            </Common.Button>
          </div>
        )}
        {!isPostOnboarded &&
          !isMessageBased &&
          TimeLine(
            'Meet with your provider',
            'Once you’ve completed your intake form, you’ll be added to the queue for a video appointment.'
          )}
        {isPostOnboarded && !isMessageBased && (
          <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
            <h2 className="text-mBase font-bold text-primary-400">Meet with your provider</h2>
            <p className="font-medium text-gray-700">
              We’ll notify you when your provider is ready.
            </p>
            <Common.Button
              className="mx-auto mt-2"
              color="blue"
              size="md"
              fullWidthOnMobile
              onClick={() =>
                navigate({
                  pathname: PathName.ZoomCall,
                  search: `callId=${appointment?.id}` // ASK Andrii if we need callId instead of apptID
                })
              }
            >
              Enter waiting room
            </Common.Button>
          </div>
        )}
        {!isPostOnboarded &&
          isMessageBased &&
          TimeLine('Care team review', 'Our care team will review your answers.')}
        {isMessageBased &&
          !isCompleted &&
          TimeLine(
            'Follow-up via messaging',
            'Your provider will follow-up with next steps via messaging. If a prescription is warranted, we’ll send it to your pharmacy.'
          )}
        {isMessageBased && isCompleted && (
          <div className="flex flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
            <h2 className="text-mBase font-bold text-primary-400">Follow-up via messaging</h2>
            <p className="font-medium text-gray-700">Your care team has sent you a new message.</p>
            <Common.Button
              className="mx-auto"
              color="white-alt"
              size="md"
              fullWidthOnMobile
              onClick={() => navigate({ pathname: PathName.Messages, search: 'tab=care-team' })}
            >
              View messages{' '}
              {!!messagesCount && (
                <div className="flex size-3 items-center justify-center rounded-full bg-red-500 text-[7px] font-bold text-white">
                  {messagesCount}
                </div>
              )}
            </Common.Button>
          </div>
        )}
      </div>
      {miCompletionStatus && (
        <Common.Button
          className="mx-auto"
          color="white-alt"
          size="lg"
          fullWidthOnMobile
          onClick={() => navigate(PathName.Home)}
        >
          View dashboard
        </Common.Button>
      )}
    </div>
  );
};

export default AppointmentStatus;
