import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';

import { selectUser } from 'store';

import { PathName } from 'utils/enums';

import { useAppSelector, useZoomCall } from './index';
import { useLogout } from './useLogout';

export const useLogoutOnIdle = () => {
  const navigate = useNavigate();
  const { userId, globalSettings } = useAppSelector(selectUser);
  const logout = useLogout();
  const { room } = useZoomCall();
  const timeout =
    (globalSettings?.find((el) => el.uid === 'securityMobileAppSessionIdleTimeout')?.value || 10) *
    60000;

  const { reset, start, pause } = useIdleTimer({
    crossTab: true,
    onIdle: () => logout(() => navigate(PathName.Login)),
    startManually: true,
    timeout
  });

  useEffect(() => {
    if (room) {
      reset();
      pause();
    } else {
      userId && start();
    }
  }, [room, userId, reset, start, pause]);
};
