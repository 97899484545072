import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { LIFEMD_WEBSITE } from 'constants/externalLinks';
import { PathName } from 'utils/enums';

const UnableToRefill = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex flex-col gap-2 text-primary-700">
        <Common.ColorTag color="primary" icon="check" text="Prescription refills" />
        <h1 className="text-m2xl font-bold">We’re unable to refill your prescription.</h1>
        <span>
          At this time, we’re only able to refill medications that were originally prescribed by a
          LifeMD provider.
        </span>
      </div>
      <div className="mt-auto flex w-full flex-col items-center gap-4">
        <Common.Button
          color="blue"
          fullWidthOnMobile
          onClick={() => {
            window.open(LIFEMD_WEBSITE, '_self');
          }}
        >
          Back to LifeMD website
        </Common.Button>
        <Common.Button color="white-alt" fullWidthOnMobile onClick={() => navigate(PathName.Login)}>
          Sign in
        </Common.Button>
      </div>
    </div>
  );
};

export default UnableToRefill;
