import { useEffect } from 'react';
import { useToggle } from 'react-use';
import dayjs from 'dayjs';

import { selectUser } from 'store';

import { useAppSelector } from './index';

export const useExpiredCard = (paymentProfileId?: string | null) => {
  const { pastDueInvoicesAmount, paymentProfiles } = useAppSelector(selectUser);
  const [isOpenEditPayment, toggleIsOpenEditPayment] = useToggle(false);

  const cardInfo = paymentProfiles?.find((method) =>
    paymentProfileId ? method.chargifyPaymentProfileId == paymentProfileId : method.isDefault
  );
  const cardExpirationDate = cardInfo?.expirationMonth + '/' + cardInfo?.expirationYear;
  const daysUntilCardExpiration = dayjs(cardExpirationDate, 'M/YYYY')
    .endOf('month')
    .diff(dayjs(), 'days');

  const isExpired = daysUntilCardExpiration <= 0;

  useEffect(() => {
    (isExpired || pastDueInvoicesAmount) && toggleIsOpenEditPayment(true);
  }, [isExpired, pastDueInvoicesAmount]);

  return {
    cardExpirationDate,
    cardInfo,
    daysUntilCardExpiration,
    isExpired,
    isOpenEditPayment,
    toggleIsOpenEditPayment
  };
};
