import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import { useFlag } from '@unleash/proxy-client-react';

import { useGetProductsQuery } from 'services/shop/shop';

import { selectMigrateToBundlePlanStatus } from 'store';

import ProductsList from 'features/ProductsList';
import PageWrapper from 'shared/animationWrappers/PageWrapper';

import { useAppSelector } from 'hooks';
import { FeatureFlag, PathName } from 'utils/enums';

import medications from 'assets/images/medications/med-l.webp';

const Home = () => {
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('');
  const isEnableMigrationToBundlePlan = useFlag(FeatureFlag.MigrateToBundlePlan);

  const isDisabledMigrateToBundlePlan = useAppSelector(selectMigrateToBundlePlanStatus);
  const { data } = useGetProductsQuery({
    featured: true
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const devices =
    data?.data.filter(
      (el) =>
        el.type !== 'At-home lab kit' && el.name.toLowerCase().includes(searchKey.toLowerCase())
    ) || [];

  const orderLabs =
    data?.data.filter(
      (el) =>
        el.type === 'At-home lab kit' && el.name.toLowerCase().includes(searchKey.toLowerCase())
    ) || [];

  return (
    <PageWrapper className="flex flex-col gap-8">
      <Common.SearchInput
        dataTestId="search_input"
        placeholder="Search"
        value={searchKey}
        onChange={handleInputChange}
      />
      {!isDisabledMigrateToBundlePlan && isEnableMigrationToBundlePlan && (
        <div>
          <h3 className="py-3 text-mBase font-semibold" data-testid="featured_header">
            Featured
          </h3>
          <div
            className="flex flex-col gap-6 rounded-2xl px-6 pb-[25px] pt-[37px]"
            data-testid="featured_product"
            style={{
              background: 'linear-gradient(4deg, #A2DFF2 -8.7%, #24748E 43.11%, #00394B 96.81%)'
            }}
          >
            <img alt="medications" className="mx-auto h-[222px]" src={medications} />
            <div className="flex flex-col gap-4">
              <p className="text-mBase font-semibold text-white">
                GLP-1 Weight Management (Medication included)
              </p>
              <Common.Button
                color="blue"
                dataTestId="upgrade_plan"
                size="sm"
                onClick={() => navigate(PathName.MigrateToBundlePlan)}
              >
                Upgrade plan
              </Common.Button>
            </div>
          </div>
        </div>
      )}
      {!!devices.length && (
        <div>
          <div className="flex items-center justify-between py-3">
            <h3 className="text-mBase font-semibold" data-testid="devices_header">
              Devices
            </h3>
            <Common.Button
              color="blue"
              dataTestId="view_all_devices"
              postIcon="arrow-right"
              size="sm"
              style="text-only"
              onClick={() =>
                navigate({
                  pathname: PathName.ShopProducts,
                  search: '?category=devices'
                })
              }
            >
              View all
            </Common.Button>
          </div>
          <ProductsList products={devices} />
        </div>
      )}
      {!!orderLabs.length && (
        <div>
          <div className="flex items-center justify-between py-3">
            <h3 className="text-mBase font-semibold" data-testid="order_labs_header">
              Order labs
            </h3>
            <Common.Button
              color="blue"
              dataTestId="view_all_labs"
              postIcon="arrow-right"
              size="sm"
              style="text-only"
              onClick={() =>
                navigate({
                  pathname: PathName.ShopProducts,
                  search: '?category=labs'
                })
              }
            >
              View all
            </Common.Button>
          </div>
          <ProductsList products={orderLabs} />
        </div>
      )}
    </PageWrapper>
  );
};

export default Home;
