// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// TODO - rewrite types to turn off ts-nocheck

import { FormEvent, useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import HTMLReactParser from 'html-react-parser';

import {
  AppointmentMifQuestion,
  ExtendedMifResponseItem,
  MifResponseItem
} from 'services/mifs/mifs.types';

import { selectMifInfo } from 'store';
import { setAppointmentMif } from 'store/mif/mifSlice';

import Slider from 'features/Slider';
import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import CheckboxGroup from 'shared/CheckboxGroup';
import RadioGroup from 'shared/RadioGroup';
import { notifyError } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector } from 'hooks';
import { useMifNavigate } from 'hooks/useMifNavigate';

import { manualErrorCheck } from './dynamicQuestion.settings';
import { DynamicQuestionProps } from './dynamicQuestion.types';
import InfoScreen from './InfoScreen';

const DynamicQuestion: React.FC<DynamicQuestionProps> = ({
  config,
  onClickContinue,
  groupTitle,
  onDQCondition,
  behaviorContext = 'strict',
  isBackAnimation,
  tag = ''
}) => {
  const dispatch = useAppDispatch();
  const { isEdit } = useMifNavigate();
  const { appointmentMif = [] } = useAppSelector(selectMifInfo);
  const isInfoScreen = config.type === 'info';

  const [textareaError, setTextAreaError] = useState('');

  const answerOnCurrentQuestion = appointmentMif.find((item) =>
    isInfoScreen ? undefined : item.question === config.question.value
  );

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleSubmitForm = (e: FormEvent<SubmitEvent>) => {
    try {
      e.preventDefault();
      const errorsList = manualErrorCheck({
        answers: answerOnCurrentQuestion,
        config
      });
      if (Object.keys(errorsList).length) {
        return setErrors(errorsList);
      }
      return onClickContinue(isEdit);
    } catch (error) {
      notifyError(error?.message);
    }
  };

  const handleSubmitTextArea = () => {
    if (!('validation' in config) || !config.validation) {
      return onClickContinue(isEdit);
    }
    let error = null;
    if (
      config.validation?.minLength &&
      (!answerOnCurrentQuestion?.answer ||
        String(answerOnCurrentQuestion?.answer).length < config.validation.minLength)
    ) {
      error = `Minimum ${config.validation.minLength} characters`;
    } else if (
      config.validation?.maxLength &&
      String(answerOnCurrentQuestion?.answer).length > config.validation.maxLength
    ) {
      error = `Maximum ${config.validation.maxLength} characters`;
    } else {
      const pattern = new RegExp(config.validation.pattern);
      if (!pattern.test(answerOnCurrentQuestion?.answer as string)) {
        error = 'Please enter a valid text';
      }
    }

    if (error) {
      setTextAreaError(error);
    } else {
      return onClickContinue(isEdit);
    }
  };

  const handleSubmitAnswer = (
    type: AppointmentMifQuestion['config']['type'] | 'textarea',
    value: string | number | boolean,
    option?: string
  ) => {
    let payload: MifResponseItem | ExtendedMifResponseItem = {
      answer: '',
      question: ''
    };
    let shouldPreventFromContinue = false;
    if (isInfoScreen) {
      return;
    }
    switch (type) {
      case 'info':
        break;
      case 'radio':
        const selectedOption = config.options.find((o) => o.value === value);
        if (selectedOption?.requireSpecialistConsultation && behaviorContext === 'strict') {
          onDQCondition();
          shouldPreventFromContinue = true;
          break;
        }
        payload = { answer: String(value), question: config.question.value };
        break;
      case 'textarea':
        payload = {
          answer: answerOnCurrentQuestion?.answer as string[],
          question: config.question.value,
          textAreaFields: [
            ...(answerOnCurrentQuestion?.textAreaFields ?? [])?.filter((i) => i.option !== option),
            {
              option: option ?? '',
              value: String(value)
            }
          ]
        };
        break;
      case 'form':
        shouldPreventFromContinue = true;
        const getInputs = () => {
          const checkIfCurrentInputsHasValue = () => {
            return answerOnCurrentQuestion?.inputs?.some((i) => i.value === option);
          };
          // if true, we modify the value of the input
          if (checkIfCurrentInputsHasValue()) {
            return answerOnCurrentQuestion?.inputs?.map((i) => {
              if (i.value === option) {
                return { text: String(value), value: option };
              }
              return i;
            });
          }
          // if false, we add a new input
          return [
            ...(answerOnCurrentQuestion?.inputs ?? []),
            {
              text: String(value),
              value: String(option)
            }
          ];
        };

        if (errors[option]) {
          setErrors((prev) => {
            delete prev[option];
            return { ...prev };
          });
        }
        payload = {
          inputs: getInputs() ?? [],
          question: config.question.value
        };
        break;
      case 'checkbox':
        if (value === 'none') {
          payload = {
            answer:
              Array.isArray(answerOnCurrentQuestion?.answer) &&
              answerOnCurrentQuestion?.answer.includes(value)
                ? []
                : [value],
            question: config.question.value,
            textAreaFields: undefined
          };
        } else if (typeof value === 'string') {
          if (Array.isArray(answerOnCurrentQuestion?.answer)) {
            const newAnswer = answerOnCurrentQuestion.answer.includes(value)
              ? answerOnCurrentQuestion.answer.filter((v) => v !== value)
              : [...answerOnCurrentQuestion.answer, value];
            if (newAnswer.length > 1 && newAnswer.includes('none')) {
              newAnswer.splice(newAnswer.indexOf('none'), 1);
            }
            payload = { answer: newAnswer, question: config.question.value };
            if (answerOnCurrentQuestion?.textAreaFields && newAnswer.includes('other')) {
              payload.textAreaFields = answerOnCurrentQuestion.textAreaFields;
            }
          } else {
            payload = { answer: [value], question: config.question.value };
          }
        } else {
          throw new Error('Checkbox value should be a string');
        }
        break;
      case 'text':
        setTextAreaError('');
        payload = {
          answer: value,
          group: config.group,
          groupTitle,
          question: config.question.value,
          questionContext: config.questionContext
        };
        break;
      case 'number':
        const getAnswer = () => {
          if (value === 'can_not_complete') {
            return value;
          }
          // uncomment if we'll need a different behavior for null values
          // if (value === 'null') {
          //   return '';
          // }
          if (value === '') {
            return value;
          }
          return Number(value);
        };

        payload = {
          answer: getAnswer(),
          group: config.group,
          groupTitle,
          question: config.question.value,
          questionContext: config.questionContext
        };
        break;
      default:
        break;
    }

    const defineIfAnswerIsChanged = () => {
      if (config.type === 'form') {
        return true; // TODO: implement form answer change detection
      }
      if (Array.isArray(answerOnCurrentQuestion?.answer) && Array.isArray(payload.answer)) {
        const typedAnswer = payload.answer;
        return (
          answerOnCurrentQuestion?.answer.length !== payload.answer.length ||
          answerOnCurrentQuestion?.answer.every((v) => typedAnswer.includes(v))
        );
      }
      return answerOnCurrentQuestion?.answer !== payload.answer;
    };
    dispatch(setAppointmentMif([payload]));
    const shouldPreventMoveToTheNextStep = () => {
      if (isInfoScreen) {
        return false;
      }
      if (shouldPreventFromContinue) {
        return true;
      }
      const option = config.options?.find((i) => i.value === value);
      return (
        ['checkbox', 'textarea', 'number', 'text'].includes(type) ||
        (type === 'radio' && !!option?.textArea)
      );
    };
    !shouldPreventMoveToTheNextStep() &&
      onClickContinue(isEdit ? defineIfAnswerIsChanged() : false);
  };

  const handleContinueCheckbox = () => {
    const shouldDQ =
      'options' in config &&
      config?.options.some(
        (i) =>
          Array.isArray(answerOnCurrentQuestion?.answer) &&
          answerOnCurrentQuestion?.answer?.includes(i.value) &&
          i.requireSpecialistConsultation
      );
    if (shouldDQ && behaviorContext === 'strict') {
      return onDQCondition();
    } else if (behaviorContext === 'allow-dq') {
      // exclude question from DQ
    }
    onClickContinue();
  };

  const isShowSelectedOption = () => {
    const selectedOption =
      'options' in config &&
      config.options.find((i) => i.value === answerOnCurrentQuestion?.answer);
    return selectedOption ? !!selectedOption?.textArea || selectedOption.value : false;
  };

  const getSliderValue = () => {
    return isNaN(Number(answerOnCurrentQuestion?.answer))
      ? undefined
      : (Number(answerOnCurrentQuestion?.answer) ?? undefined);
  };

  const content = () => {
    switch (config.type) {
      case 'info':
        return <InfoScreen config={config} onClickContinue={() => onClickContinue(false)} />;
      case 'radio':
        return (
          <div className="flex h-full flex-col gap-8">
            <RadioGroup
              items={config.options}
              selectedItem={isShowSelectedOption() ? String(answerOnCurrentQuestion?.answer) : ''}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('radio', v)}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            {config.options.find((i) => i.value === answerOnCurrentQuestion?.answer)?.textArea && (
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                fullWidthOnMobile
                onClick={() => onClickContinue()}
              >
                {isEdit ? 'Save' : 'Continue'}
              </Common.Button>
            )}
          </div>
        );
      case 'checkbox':
        return (
          <div className="flex h-full flex-col gap-8">
            <CheckboxGroup
              items={config.options}
              selectedItems={(answerOnCurrentQuestion?.answer as string[]) ?? []}
              textAreaValue={answerOnCurrentQuestion?.textAreaFields}
              onSelect={(v) => handleSubmitAnswer('checkbox', String(v))}
              onTextAreaChange={(v, option) => handleSubmitAnswer('textarea', v, option)}
            />
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!(answerOnCurrentQuestion?.answer as string[])?.length}
              fullWidthOnMobile
              onClick={handleContinueCheckbox}
            >
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </div>
        );
      case 'number':
        if (config?.inputType === 'slider') {
          return (
            <div className="flex flex-col gap-6 max-md:h-full md:gap-16">
              <Slider
                defaultPivot={(config.validation.max + config.validation.min) / 2}
                max={config.validation.max}
                min={config.validation.min || 0}
                value={getSliderValue()}
                onChange={(v) => handleSubmitAnswer('number', String(v))}
              />
              <Common.Button
                className="mx-auto mt-auto"
                color="blue"
                disabled={!answerOnCurrentQuestion?.answer && answerOnCurrentQuestion?.answer !== 0}
                fullWidthOnMobile
                onClick={() => onClickContinue()}
              >
                {isEdit ? 'Save' : 'Continue'}
              </Common.Button>
            </div>
          );
        }
        return (
          <div className="flex flex-col gap-6 max-md:h-full">
            <Common.Input
              placeholder={config.answerPlaceholder}
              type="text"
              value={
                answerOnCurrentQuestion?.answer === 'can_not_complete'
                  ? ''
                  : !!answerOnCurrentQuestion?.answer || answerOnCurrentQuestion?.answer === 0
                    ? String(answerOnCurrentQuestion?.answer)
                    : ''
              }
              onChange={(e) => handleSubmitAnswer('number', e.target.value.replace(/\D/g, ''))}
            />
            {config.options.length &&
              config.options.map((option) =>
                option.type === 'checkbox' ? (
                  <Common.Checkbox
                    checked={answerOnCurrentQuestion?.answer === 'can_not_complete'}
                    color="blue"
                    key={option.value}
                    onChange={() => {
                      const newValue =
                        answerOnCurrentQuestion?.answer === 'can_not_complete'
                          ? ''
                          : 'can_not_complete';

                      handleSubmitAnswer('text', newValue);
                    }}
                  >
                    {option.label}
                  </Common.Checkbox>
                ) : null
              )}
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              disabled={!answerOnCurrentQuestion?.answer && answerOnCurrentQuestion?.answer !== 0}
              fullWidthOnMobile
              onClick={() => onClickContinue()}
            >
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </div>
        );
      case 'form':
        return (
          <form className="flex h-full flex-col gap-6" onSubmit={handleSubmitForm}>
            {config.inputs?.map((input) => {
              switch (input.size) {
                case 'field':
                  return (
                    <Common.Input
                      errors={
                        errors[input.value]
                          ? { message: errors[input.value], type: 'error' }
                          : undefined
                      }
                      helper={errors[input.value]}
                      key={input.value}
                      label={input.label}
                      labelClassName="!text-primary-700 !font-medium"
                      maxLength={input.validation.maxLength}
                      minLength={input.validation.minLength}
                      pattern={input.validation.pattern}
                      placeholder={input.answerPlaceholder}
                      value={
                        answerOnCurrentQuestion?.inputs?.find((i) => i.value === input.value)?.text
                      }
                      onChange={(e) => handleSubmitAnswer('form', e.target.value, input.value)}
                    />
                  );
                case 'area':
                  return (
                    <Common.TextArea
                      errors={
                        errors[input.value]
                          ? { message: errors[input.value], type: 'error' }
                          : undefined
                      }
                      helper={errors[input.value]}
                      key={input.value}
                      label={input.label}
                      maxLength={input.validation.maxLength}
                      minLength={input.validation.minLength}
                      pattern={input.validation.pattern}
                      placeholder={input.answerPlaceholder}
                      rows={5}
                      value={
                        answerOnCurrentQuestion?.inputs?.find((i) => i.value === input.value)?.text
                      }
                      onChange={(e) => handleSubmitAnswer('form', e.target.value, input.value)}
                    />
                  );
                default:
                  return null;
              }
            })}
            <Common.Button className="mx-auto mt-auto" color="blue" fullWidthOnMobile>
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </form>
        );
      case 'text':
        return (
          <div className="flex flex-col gap-6 max-md:h-full">
            <Common.TextArea
              errors={
                textareaError
                  ? {
                      message: textareaError,
                      type: 'error'
                    }
                  : undefined
              }
              helper={textareaError}
              label={config?.description}
              placeholder={config.answerPlaceholder}
              value={
                !!answerOnCurrentQuestion?.answer ? String(answerOnCurrentQuestion?.answer) : ''
              }
              onChange={(e) => handleSubmitAnswer('text', e.target.value)}
            />
            <Common.Button
              className="mx-auto mt-auto"
              color="blue"
              fullWidthOnMobile
              onClick={handleSubmitTextArea}
            >
              {isEdit ? 'Save' : 'Continue'}
            </Common.Button>
          </div>
        );
    }
  };

  return (
    <SlideAnimateWrapper
      className="flex h-full flex-col gap-6"
      isBackAnimation={isBackAnimation}
      key={config._id}
    >
      {config.type !== 'info' && (
        <>
          {!!config.alertMessage && (
            <Common.Alert className="mb-4" type="warning" colorableBackground>
              {config.alertMessage}
            </Common.Alert>
          )}
          <div className="flex flex-col gap-2">
            {!!tag && <Common.ColorTag color="primary" icon="check" text={tag} />}
            <h3 className="wm-signup-title" data-testid="header">
              {HTMLReactParser(config.question.label)}
            </h3>
            {!!config.question.description && (
              <span className="text-primary-700">{config.question.description}</span>
            )}
          </div>
        </>
      )}
      {content()}
    </SlideAnimateWrapper>
  );
};

export default DynamicQuestion;
