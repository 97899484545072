import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { LoaderProps } from './loader.types';

const VARIANTS = {
  animate: {
    scale: [0.75, 1, 0.75, 1, 0.75, 0.75],
    transition: {
      duration: 1,
      repeat: Infinity
    }
  }
};

const Loader: React.FC<LoaderProps> = ({ isVisible, relative = false, reversedIcon = false }) => {
  return (
    <>
      {isVisible && (
        <motion.div
          animate="animate"
          className={classNames('inset-0 z-[101] m-auto size-20', relative ? 'relative' : 'fixed')}
          data-testid="page_loader"
          initial="initial"
          variants={VARIANTS}
        >
          <Common.Icon
            className="size-full text-primary"
            name={reversedIcon ? 'lifemd-reversed' : 'lifemd'}
          />
        </motion.div>
      )}
    </>
  );
};

export default Loader;
