import { ShopItem } from 'services/kroger/kroger.types';

import Bakers from 'assets/icons/kroger-shops/Store=Baker’s.svg';
import CityMarket from 'assets/icons/kroger-shops/Store=City Market.svg';
import Dillons from 'assets/icons/kroger-shops/Store=Dillons.svg';
import Food4Less from 'assets/icons/kroger-shops/Store=Food 4 Less.svg';
import FoodsCo from 'assets/icons/kroger-shops/Store=Foods Co.svg';
import FredMeyer from 'assets/icons/kroger-shops/Store=Fred Meyer.svg';
import Fry from "assets/icons/kroger-shops/Store=Fry's.svg";
import Gerbes from 'assets/icons/kroger-shops/Store=Gerbes.svg';
import HarrisTeeter from 'assets/icons/kroger-shops/Store=Harris Teeter.svg';
import JayC from 'assets/icons/kroger-shops/Store=Jay C Food Store.svg';
// import Vitacost from 'assets/icons/kroger-shops/Store=Jay C Food Store.svg';
import KingSoopers from 'assets/icons/kroger-shops/Store=King Soopers.svg';
import Kroger from 'assets/icons/kroger-shops/Store=Kroger.svg';
import Marianos from "assets/icons/kroger-shops/Store=Mariano's.svg";
import MetroMarket from 'assets/icons/kroger-shops/Store=Metro Market.svg';
import PayLess from 'assets/icons/kroger-shops/Store=Pay-Less Super Markets.svg';
import PickNSave from "assets/icons/kroger-shops/Store=Pick'n Save.svg";
import Qfc from 'assets/icons/kroger-shops/Store=QFC.svg';
import Ralphs from 'assets/icons/kroger-shops/Store=Ralphs.svg';
import Ruler from 'assets/icons/kroger-shops/Store=Ruler.svg';
import Smiths from "assets/icons/kroger-shops/Store=Smith's Food and Drug.svg";
import PointSvg from 'assets/images/map/point.svg';

type BrandKeys = keyof typeof brandsList;

export const getIcon = (shop: ShopItem, isOpen: boolean, _id?: string, isMobile?: boolean) => {
  try {
    let size: number;
    if (_id === shop._id && isOpen) {
      size = isMobile ? 48 : 38;
    } else {
      size = isMobile ? 32 : 28;
    }
    if (google !== undefined && google.maps !== undefined) {
      return {
        scaledSize: new google.maps.Size(size, size),
        size: new google.maps.Size(size, size),
        url: getIconByBrand(shop.brand as BrandKeys)
      };
    }
    return {
      scaledSize: { equals: () => true, height: size, width: size },
      size: { equals: () => true, height: size, width: size },
      url: getIconByBrand(shop.brand as BrandKeys)
    };
  } catch {
    return {
      scaledSize: { equals: () => true, height: 32, width: 32 },
      size: { equals: () => true, height: 32, width: 32 },
      url: PointSvg
    };
  }
};

const getIconByBrand = (brand: BrandKeys): string => {
  if (!brandsList[brand]?.icon) {
    return PointSvg;
  }
  return brandsList[brand]['icon'];
};

export const getWebsiteURLByBrand = (brand?: string): string => {
  if (!brand) {
    return '';
  }
  const query =
    'search?keyword=OptUpFavorites&query=OptUpFavorites&searchType=mktg%20attribute&monet=false&fulfillment=all&pzn=relevance';
  if (!brandsList[brand as BrandKeys]?.websiteURL) {
    return 'https://www.kroger.com/' + query;
  }
  if (brand === 'ruler') {
    return brandsList[brand as BrandKeys]['websiteURL'];
  }
  return brandsList[brand as BrandKeys]['websiteURL'] + query;
};

const brandsList = {
  bakers: { icon: Bakers, websiteURL: 'https://www.bakersplus.com/' },
  city_market: { icon: CityMarket, websiteURL: 'https://www.citymarket.com/' },
  dillon: { icon: Dillons, websiteURL: 'https://www.dillons.com/' },
  food_4_less: { icon: Food4Less, websiteURL: 'https://www.food4less.com/' },
  foods_co: { icon: FoodsCo, websiteURL: 'https://www.foodsco.net/' },
  fred_meyer: { icon: FredMeyer, websiteURL: 'https://www.fredmeyer.com/' },
  frys: { icon: Fry, websiteURL: 'https://www.frysfood.com/' },
  gerbes: { icon: Gerbes, websiteURL: 'https://www.gerbes.com/' },
  harris_teeter: { icon: HarrisTeeter, websiteURL: 'https://www.harristeeter.com/' },
  jay_c: { icon: JayC, websiteURL: 'https://www.jaycfoods.com/' },
  king_soopers: { icon: KingSoopers, websiteURL: 'https://www.kingsoopers.com/' },
  kroger: { icon: Kroger, websiteURL: 'https://www.kroger.com/' },
  marianos: { icon: Marianos, websiteURL: 'https://www.marianos.com/' },
  metro_market: { icon: MetroMarket, websiteURL: 'https://www.metromarket.net/' },
  payless: { icon: PayLess, websiteURL: 'https://shop.paylessmarkets.com/shop' },
  pick_n_save: { icon: PickNSave, websiteURL: 'https://www.picknsave.com/' },

  qfc: { icon: Qfc, websiteURL: 'https://www.qfc.com/' },

  ralphs: { icon: Ralphs, websiteURL: 'https://www.ralphs.com/' },
  // vitacost: { icon: Vitacost, websiteURL: 'https://www.kroger.com/' },
  ruler: { icon: Ruler, websiteURL: 'https://www.rulerfoods.com/locations/' },
  smiths: { icon: Smiths, websiteURL: 'https://www.smithsfoodanddrug.com/' }
} as const;
