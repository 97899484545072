import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import { Props } from './radioGroup.types';

const RadioGroup = <T extends string | number | boolean>({
  isLoading,
  items,
  onSelect,
  selectedItem,
  wrapperClassName = '',
  textAreaValue,
  onTextAreaChange
}: Props<T>) => {
  return (
    <div className={classNames('flex flex-col gap-2', wrapperClassName)}>
      {items.map((item, i) => (
        <button
          // removed progress-wrapper class for now as client asked for it
          className={classNames(item.value === selectedItem && isLoading ? 'progress-wrapper' : '')}
          disabled={isLoading || item.disabled}
          key={String(item.label) + i}
          onClick={(e) => {
            // if textarea is expanded, we should prevent regular onClick, because it will move to the next step
            if (item.textArea && item.value === selectedItem) {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            onSelect(item.value);
          }}
        >
          <Common.RichRadioButton
            checked={item.value === selectedItem}
            disabled={isLoading || item.disabled}
            label={
              item.description || item.subLabel ? (
                <div className="flex flex-col gap-0.5">
                  {item.subLabel &&
                    (typeof item.subLabel === 'string' ? (
                      <span className="text-mSm text-primary-400">{item.subLabel}</span>
                    ) : (
                      item.subLabel
                    ))}
                  <span className="font-semibold">{item.label}</span>
                  <span className="text-mSm text-gray">{item.description}</span>
                </div>
              ) : (
                item.label
              )
            }
            name={String(item.value)}
            onChange={(e) => e.preventDefault()}
          >
            {selectedItem === item.value && !!item.textArea && (
              <Common.TextArea
                className="!gap-2 text-start text-base"
                dataTestId="text_area"
                disabled={isLoading}
                label={item.textArea?.subquestion}
                name={String(item.value)}
                placeholder={
                  typeof item.textArea === 'object' ? item.textArea.placeholder || '' : ''
                }
                value={textAreaValue?.find((i) => i.option === item.value)?.value || ''}
                onChange={(v) => {
                  v.preventDefault();
                  v.stopPropagation();
                  onTextAreaChange?.(String(v.target.value), String(item.value));
                }}
              />
            )}
          </Common.RichRadioButton>
        </button>
      ))}
    </div>
  );
};

export default RadioGroup;
