import { SexAtBirth } from 'utils/enums';

import { PossibleStepsType, StepName } from './migrateToTT.types';

export const defineFlowSteps = (
  steps: PossibleStepsType,
  params: {
    hadWM: boolean;
    sexAtBirth: SexAtBirth | '';
  }
): StepName[] => {
  let copySteps: StepName[] = [...steps];
  const stepsToFilter: StepName[] = [];

  // Check different conditions to filter steps by adding steps to filter array in the end

  // 1. Remove wm relates steps if user had weight management
  if (params.hadWM) {
    stepsToFilter.push('weight-loss-goal', 'health-state', 'diabetes');
  }

  // 2. Remove pregnancy step for non female patients
  if (params.sexAtBirth !== SexAtBirth.Female) {
    stepsToFilter.push('pregnancy-plans');
  }

  copySteps = copySteps.filter((step) => !stepsToFilter.includes(step));

  return copySteps;
};
