import { PaymentCombinedReqProps } from 'services/auth/auth.types';

import { AppointmentsState } from 'store/appointments/appointments.types';

import { PossibleMoveToStepTypes } from 'containers/CreateAppointmentExtended/Content/content.types';
import {
  BuildNextStepParams,
  FillAppointmentDataProps,
  StepName
} from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';
import { buildCreditCardAttributesFromForm } from 'widgets/PaymentFormNew/paymentFormNew.settings';
import { PaymentFormFields } from 'widgets/PaymentFormNew/paymentFormNew.types';

import { DEFAULT_LAB_TESTS_APPT_CODE } from 'constants/defaults';

import { MembershipPlan, PricePoint } from 'models/plans.types';

// on some steps we need to write data about session, here we define what data we need to write
export const buildPayload = (
  type: PossibleMoveToStepTypes
): Partial<AppointmentsState['newAppointmentExtended']> | null => {
  let payload: Partial<AppointmentsState['newAppointmentExtended']> | null = null;
  if (typeof type !== 'object') {
    return payload;
  }

  switch (type.step) {
    case 'red-flags':
      if (type.data) {
        const { status, _id, plans, ...rest } = type.data;
        payload = {
          ...rest,
          ...(!!plans ? { plans } : { plans: undefined }),
          appointmentTypeId: _id
        };
      }
      break;
    case 'appointment-types-picker':
      const { status, _id, plans, code, careProviders, ...rest } = type.data;
      payload = {
        ...rest,
        ...(!!plans ? { plans } : { plans: undefined }),
        ...(!!code ? { code } : { code: '' }),
        ...(!!careProviders ? { careProviders } : { careProviders: undefined }),
        appointmentTypeId: _id,
        ...(code === DEFAULT_LAB_TESTS_APPT_CODE
          ? { src: 'shop-labs' }
          : {
              labsResultID: undefined,
              src: 'talk-to-a-doctor'
            })
      };
      break;
    case 'choose-type-of-care':
      payload = { src: type.data };
      break;
    case 'details-for-provider':
      payload = { appointmentDescription: type.data.appointmentDescription, callMethod: 'video' };
      break;
    case 'prescriptions-type':
      const {
        status: statusFromApptType,
        _id: appointmentTypeId,
        ...restApptData
      } = type.data.apptType;
      payload = { ...restApptData, appointmentTypeId };
      break;
    case 'labs-to-review':
      payload = {
        labsResultID: type.data.labsResultID !== 'another' ? type.data.labsResultID : ''
      };

      break;
    case 'qualified-for-async':
      payload = { ...type.data };
      break;
    case 'upload-files':
      payload = { files: type.data };
      break;
    case 'subscription-required':
      payload = {
        membershipData: {
          planId: type.data.planId,
          planPricePoint: type.data.pricePoint
        }
      };
      break;
    // add more conditions here
    default:
      break;
  }

  return payload;
};

// in this function we define what step should be next and set into query params
export const getNextStep = (
  type: FillAppointmentDataProps,
  {
    src,
    accessToken,
    isRequiredToUpgradeToLifeMDPlus, // to show / hide upgrade step
    shouldAllowToSeeAsyncSelectScreen, // to show / hide qualified-for-async step
    haveToPayForAppt, // to show / hide checkout step
    uploadRequired,
    // code,
    hasPredefinedAppointmentType = false,
    careProviders,
    shouldAskDetails
  }: BuildNextStepParams
): StepName | 'shop' | null => {
  let nextStep: StepName | 'shop' | null = null;
  const isSteadyMDFlow = careProviders?.includes('SteadyMD');

  switch (type.step) {
    case 'choose-type-of-care':
      nextStep = type.data === 'shop-labs' ? 'shop' : 'red-flags';
      break;
    case 'red-flags': // red flags is just a marketing page, flow is defined based on src (place from where user came)
      switch (src) {
        case 'labs-appt':
          nextStep = 'details-for-provider';
          break;
        case 'prescriptions':
          nextStep = 'prescription-for';
          break;
        case 'talk-to-a-doctor':
          nextStep = hasPredefinedAppointmentType
            ? type.data?.mifCode
              ? 'mif'
              : type.data?.uploadRequired
                ? 'upload-files'
                : type.data?.shouldAskDetails
                  ? 'details-for-provider'
                  : 'appointment-types-picker'
            : 'appointment-types-picker';
          break;
        default:
          break;
      }
      break;
    case 'appointment-types-picker': // if selected appointment type has some mif in it, we should show mif screen, otherwise just show details
      nextStep = type.data.mifCode
        ? 'mif'
        : type.data.code === DEFAULT_LAB_TESTS_APPT_CODE
          ? 'labs-to-review'
          : type.data.uploadRequired
            ? 'upload-files'
            : type.data.shouldAskDetails
              ? 'details-for-provider'
              : shouldAllowToSeeAsyncSelectScreen
                ? 'qualified-for-async'
                : !accessToken
                  ? 'create-account-intro'
                  : 'date-time';

      break;
    case 'labs-to-review':
      nextStep = type.data.labsResultID === 'another' ? 'upload-files' : 'details-for-provider';
      break;
    case 'prescription-for':
      switch (type.data) {
        case 'new-prescription':
          nextStep = 'appointment-types-picker';
          break;
        case 'prescription-refill':
          nextStep = 'prescriptions-type';
          break;
        default:
          break;
      }
      break;
    case 'is-renew':
      nextStep = 'details-for-provider';
      break;
    case 'details-for-provider':
      nextStep = 'date-time';
      break;
    case 'prescriptions-type':
      switch (type.data.prescriptionsValueType) {
        case 'weight-management':
          nextStep = 'is-renew';
          break;
        case 'something-else':
          nextStep = !accessToken
            ? 'unable-to-refill'
            : type.data.apptType.mifCode
              ? 'mif'
              : 'details-for-provider';
          break;
        default:
          break;
      }
      break;
    case 'mif': // here is next step based on requirements:
      // 1. If user needs to add files - go to upload-files
      // 2. If has no account - go to create-account-intro (we are not sending mif yet in those cases)
      // 3. If user can see qualified for async screen - go to qualified-for-async
      // 4. Based on user's plan we should go to upgrade plan screen or for the date selection
      nextStep = uploadRequired
        ? 'upload-files'
        : !accessToken
          ? 'create-account-intro'
          : shouldAllowToSeeAsyncSelectScreen
            ? 'qualified-for-async'
            : isSteadyMDFlow && isRequiredToUpgradeToLifeMDPlus
              ? 'subscription-required'
              : shouldAskDetails
                ? 'details-for-provider'
                : isSteadyMDFlow
                  ? haveToPayForAppt
                    ? 'pre-checkout'
                    : 'confirmation'
                  : 'date-time';
      break;
    case 'upload-files': // logic is the same as in mif step
      nextStep = !accessToken
        ? 'create-account-intro'
        : shouldAllowToSeeAsyncSelectScreen
          ? 'qualified-for-async'
          : isSteadyMDFlow
            ? isRequiredToUpgradeToLifeMDPlus
              ? 'subscription-required'
              : haveToPayForAppt
                ? 'pre-checkout'
                : 'confirmation'
            : 'details-for-provider';
      break;
    case 'subscription-required':
      // TODO will change condition to show pre-checkout screen for all queue appt later
      nextStep = isSteadyMDFlow ? 'pre-checkout' : 'payment-checkout';
      break;
    case 'create-account-intro':
      nextStep = 'create-account-personal-details';
      break;
    case 'create-account-personal-details':
      nextStep = 'create-account-address';
      break;
    case 'create-account-address':
      nextStep = 'create-account-dob';
      break;
    case 'create-account-dob':
      nextStep = 'create-account-phone';
      break;
    case 'create-account-phone':
      nextStep = 'create-account-password';
      break;
    case 'create-account-password':
      nextStep = shouldAllowToSeeAsyncSelectScreen
        ? 'qualified-for-async'
        : isSteadyMDFlow
          ? 'subscription-required'
          : 'details-for-provider';
      break;
    case 'qualified-for-async':
      nextStep = isSteadyMDFlow
        ? isRequiredToUpgradeToLifeMDPlus
          ? 'subscription-required'
          : type.data.callMethod === 'video'
            ? haveToPayForAppt
              ? 'pre-checkout'
              : 'confirmation'
            : 'confirmation'
        : type.data.callMethod === 'video'
          ? 'details-for-provider'
          : isRequiredToUpgradeToLifeMDPlus
            ? 'subscription-required'
            : haveToPayForAppt
              ? 'payment-checkout'
              : 'confirmation';
      break;
    case 'date-time':
      nextStep = isRequiredToUpgradeToLifeMDPlus
        ? 'subscription-required'
        : haveToPayForAppt
          ? 'payment-checkout'
          : 'confirmation';
      break;
    case 'pre-checkout':
      nextStep = 'payment-checkout';
      break;
    default:
  }
  return nextStep;
};

// method to create body for the payment of the new user
export const buildBodyForPayment = ({
  data,
  formData,
  lifeMDPlusPlan,
  defaultPricePoint
}: {
  data: Omit<
    AppointmentsState['newAppointmentExtended'],
    'membershipData' | 'src' | 'plans' | 'mifCode' | 'asyncAllowed' | 'uploadRequired'
  > & {
    accessToken: string;
  };
  defaultPricePoint: PricePoint;
  formData: PaymentFormFields;
  lifeMDPlusPlan: MembershipPlan;
}): PaymentCombinedReqProps => {
  const creditCardInfo = buildCreditCardAttributesFromForm(formData, 'credit_card');

  return {
    accessToken: data.accessToken,
    appointment: {
      appointmentDescription: data.appointmentDescription,
      appointmentMethod: data.callMethod,
      appointmentTypeId: data.appointmentTypeId,
      bookedSlotId: data.bookedSlotId,
      doctorId: data.doctorId,
      isAsapAppointment: !!data.careProviders?.includes('SteadyMD')
    },
    credit_card: creditCardInfo,
    flow: 'lifemd-plus',
    subscription: {
      appointmentPricePointId: defaultPricePoint.initialAppointmentPricePointId,
      componentId: defaultPricePoint.initialAppointmentComponentId,
      planCode: lifeMDPlusPlan.planCode,
      planId: lifeMDPlusPlan._id,
      planPricePointId: defaultPricePoint.planPricePointId
    }
  };
};
