import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { useGetAppointmentsQuery } from 'services/appointments/appointments';

import { selectAppointments, selectUser } from 'store';

import AskToUpgradeToTheWM from 'modals/AskToUpgradeToTheWM';
import Loader from 'shared/Loader';
import NotOnThisList from 'shared/NotOnThisList';
import RadioGroup from 'shared/RadioGroup';
import { notifyToast } from 'shared/Toast/Toast';

import { DEFAULT_APPOINTMENT_CODE } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import useWeightManagement from 'hooks/useWeightManagement';
import { AppointmentListTypes, PathName } from 'utils/enums';

import { setAppointmentOptions } from './appointmentTypesPicker.settings';
import { AppointmentTypesPickerProps } from './appointmentTypesPicker.types';

const AppointmentTypesPicker: React.FC<AppointmentTypesPickerProps> = ({
  appointmentTypes,
  onClickNext
}) => {
  const { accessToken } = useAppSelector(selectUser);
  const { upcomingAppointments } = useAppSelector(selectAppointments);

  const { isFetching } = useGetAppointmentsQuery(
    {
      appointmentListType: AppointmentListTypes.UPCOMING
    },
    {
      skip: !accessToken
    }
  );
  const navigate = useNavigate();
  const { isWeightManagement } = useWeightManagement();
  const [activeItem, setActiveItem] = useState<string>();
  const [searchKey, setSearchKey] = useState<string>('');
  const [isUpgradePopupOpen, toggleUpgradePopup] = useToggle(false);
  const [isFullList, setFullList] = useState(false);

  const appointmentOptions = [
    ...setAppointmentOptions(!isFullList, appointmentTypes, searchKey),
    ...(!isFullList ? [{ data: null, label: 'Something else', value: 'other' }] : [])
  ];

  const handleOnSelect = (value: string) => {
    setActiveItem(value);

    if (value === 'other') {
      setFullList(true);
    } else {
      const selectedOption = appointmentOptions.find((appt) => appt.value === value);
      if (!selectedOption) {
        throw new Error('Selected option not found');
      }
      const pendingAsapAppt = upcomingAppointments?.find(
        (a) => a.category === 'queue' && a.appointmentStatus === 'pending'
      );
      if (pendingAsapAppt && selectedOption.data?.careProviders?.includes('SteadyMD')) {
        return notifyToast(
          'You already have an appointment pending',
          {
            closeOnClick: true,
            toastId: 'existing_asap_appt'
          },
          () => navigate(PathName.Appointment + `/${pendingAsapAppt._id}`),
          'View'
        );
      } else {
        const shouldTriggerWMUpgradePopup = (): boolean => {
          if (isWeightManagement) {
            return false;
          }
          return (
            Array.isArray(selectedOption.data?.plans) &&
            selectedOption.data.plans.some((plan) => plan.code === 'weight-management') &&
            !selectedOption.data.plans.some((plan) => plan.code === 'lifemd-plus')
          );
        };

        if (shouldTriggerWMUpgradePopup()) {
          setActiveItem(undefined);
          return toggleUpgradePopup();
        }
        !!selectedOption?.data && onClickNext(selectedOption.data);
      }
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value);
  };

  const defaultAppointmentType = appointmentTypes.find(
    (appt) => appt.code === DEFAULT_APPOINTMENT_CODE
  );

  const redirectToDetailsScreen = () => {
    defaultAppointmentType && onClickNext(defaultAppointmentType);
  };

  useEffect(() => {
    const featured = setAppointmentOptions(true, appointmentTypes);
    if (appointmentTypes && !featured.length) {
      setFullList(true);
    }
  }, [appointmentTypes]);

  return (
    <>
      <AskToUpgradeToTheWM
        alertContent={
          <>
            <b>Please upgrade your plan</b> to book a weight management appointment.
          </>
        }
        declineButtonText="Close"
        isOpen={isUpgradePopupOpen}
        onClose={toggleUpgradePopup}
        // onReopen={() => toggleUpgradePopup(true)}
      />
      <Loader isVisible={isFetching} />
      <div
        className={classNames('flex flex-col gap-6', {
          'pointer-events-none': isFetching
        })}
      >
        {!isFullList && (
          <div className="flex flex-col gap-2">
            <Common.ColorTag color="primary" text="Talk to a doctor" />
            <h1 className="text-m2xl font-bold text-primary-700" data-testid="header">
              What would you like to be seen for?
            </h1>
          </div>
        )}

        {isFullList && (
          <>
            <Common.SearchInput
              className="mt-0.5"
              dataTestId="search_input"
              placeholder="Find an appointment type..."
              onChange={handleSearch}
            />
            {!!defaultAppointmentType && (
              <NotOnThisList
                btnText="Be seen for something else"
                onClick={redirectToDetailsScreen}
              />
            )}
          </>
        )}

        <RadioGroup
          items={appointmentOptions}
          selectedItem={activeItem}
          onSelect={handleOnSelect}
        />
      </div>
    </>
  );
};

export default AppointmentTypesPicker;
