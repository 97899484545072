import { ChangeEvent, useRef } from 'react';
import { useDropArea, useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';

import Image from 'modals/Image';

import { FileZoneProps } from './fileZone.types';

const FileZone: React.FC<FileZoneProps> = ({
  onSelect,
  type,
  status: { filePath, fileStatus, _id },
  onDelete,
  loading,
  accept = 'image/*',
  placeholder,
  defaultIcon = 'camera'
}) => {
  const isProcessing = fileStatus === 'processing';
  const isInitial = fileStatus === 'initial';

  const inputRef = useRef<HTMLInputElement>(null);
  const [isOpenImage, toggleIsOpenImage] = useToggle(false);
  const [bond, { over }] = useDropArea({
    onFiles: (files) => isInitial && onSelect(files, type)
  });

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    onSelect(Array.from(e.target.files), type);
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };

  return (
    <>
      <Image filePath={filePath} isOpen={isOpenImage} onClose={toggleIsOpenImage} />
      <label
        {...bond}
        className={classNames(
          'relative flex h-[140px] w-full items-center justify-center rounded-2xl  px-4 py-6 text-gray transition-all duration-300',
          isInitial
            ? 'target-animate-div border cursor-pointer active:border-gray-600 active:text-gray-600 md:hover:border-gray-600 md:hover:text-gray-600'
            : 'pointer-events-none',
          isProcessing
            ? 'bg-gray-200 text-gray-700 border'
            : over
              ? 'border-2 border-solid border-primary bg-primary-400'
              : 'border-dashed'
        )}
        data-testid={`file_zone_${fileStatus}`}
        htmlFor={`file_zone_${type}`}
        style={{
          background: filePath
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), center / cover no-repeat url(${filePath})`
            : ''
        }}
      >
        {!!_id && (
          <button
            className="pointer-events-auto absolute right-3 top-3 text-white"
            data-testid="delete-button"
            disabled={loading}
            type="button"
            onClick={() => onDelete(_id)}
          >
            <Common.Icon name="close" />
          </button>
        )}
        <input
          accept={accept}
          className="hidden"
          data-testid="file_zone"
          disabled={loading}
          id={`file_zone_${type}`}
          ref={inputRef}
          type="file"
          onChange={handleChangeInput}
        />
        <div
          className={classNames('max-w-full', {
            hidden: !(isProcessing || isInitial),
            'text-white': over
          })}
        >
          <Common.Icon
            className={classNames('m-auto', {
              'animate-spin text-gray': isProcessing
            })}
            name={isProcessing ? 'loader' : over ? 'arrow-alt-down' : defaultIcon}
          />
          <span className="mx-auto block max-h-[50px] max-w-full truncate text-pretty break-all text-center text-sm first-letter:uppercase max-md:max-w-[100px]">
            {isProcessing ? 'uploading...' : over ? 'drop photo to upload' : placeholder || type}
          </span>
        </div>
      </label>
    </>
  );
};

export default FileZone;
