import React, { createContext, PropsWithChildren, useState } from 'react';
import { useToggle } from 'react-use';
import { LocalVideoTrack, RecordingStatus } from '@zoom/videosdk';

import Room from 'widgets/zoom/Room';
import Settings from 'widgets/zoom/Settings';

import { ZoomCallContextProps } from './zoomCallContext.types';

const defaultValue: ZoomCallContextProps = {
  appointment: null,
  audioEnabled: true,
  cameraId: '',
  connectionLost: false,
  isEnableCaptions: false,
  isFullscreen: false,
  isMinimize: false,
  isOpenChat: false,
  isOpenSettings: false,
  loadingRoom: false,
  loggerClient: null,
  microphoneId: '',
  participantDisconnected: false,
  resetValuesToDefault: () => {},
  room: null,
  setAppointment: () => {},
  setCameraId: () => {},
  setLoggerClient: () => {},
  setMicrophoneId: () => {},
  setRoom: () => {},
  setShowControls: () => {},
  setVideoTrack: () => {},
  showControls: true,
  toggleAudioEnabled: () => {},
  toggleConnectionLost: () => {},
  toggleIsEnableCaptions: () => {},
  toggleIsFullscreen: () => {},
  toggleIsMinimize: () => {},
  toggleIsOpenChat: () => {},
  toggleIsOpenSettings: () => {},
  toggleLoadingRoom: () => {},
  toggleParticipantDisconnected: () => {},
  toggleVideoEnabled: () => {},
  videoEnabled: true,
  videoTrack: null
};

export const ZoomCallContext = createContext<ZoomCallContextProps>(defaultValue);

export const ZoomCallProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [room, setRoom] = useState(defaultValue.room);
  const [appointment, setAppointment] = useState(defaultValue.appointment);
  const [loggerClient, setLoggerClient] = useState(defaultValue.loggerClient);
  const [isOpenChat, toggleIsOpenChat] = useToggle(defaultValue.isOpenChat);
  const [loadingRoom, toggleLoadingRoom] = useToggle(defaultValue.loadingRoom);
  const [audioEnabled, toggleAudioEnabled] = useToggle(defaultValue.audioEnabled);
  const [videoEnabled, toggleVideoEnabled] = useToggle(defaultValue.videoEnabled);
  const [isFullscreen, toggleIsFullscreen] = useToggle(defaultValue.isFullscreen);
  const [connectionLost, toggleConnectionLost] = useToggle(defaultValue.connectionLost);
  const [participantDisconnected, toggleParticipantDisconnected] = useToggle(
    defaultValue.participantDisconnected
  );

  const [isMinimize, toggleIsMinimize] = useToggle(defaultValue.isMinimize);
  const [showControls, setShowControls] = useToggle(defaultValue.showControls);
  const [isOpenSettings, toggleIsOpenSettings] = useToggle(defaultValue.isOpenSettings);
  const [isEnableCaptions, toggleIsEnableCaptions] = useToggle(defaultValue.isEnableCaptions);
  const [cameraId, setCameraId] = useState(defaultValue.cameraId);
  const [microphoneId, setMicrophoneId] = useState(defaultValue.microphoneId);
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack | null>(defaultValue.videoTrack);

  const resetValuesToDefault = () => {
    const client = room?.getRecordingClient();

    Promise.all([
      ...(client?.getCloudRecordingStatus() === RecordingStatus.Recording
        ? [client.stopCloudRecording()]
        : []),
      room?.leave()
    ]).finally(() => {
      setRoom(defaultValue.room);
      setAppointment(defaultValue.appointment);
      toggleIsOpenChat(defaultValue.isOpenChat);
      setLoggerClient(defaultValue.loggerClient);
      toggleLoadingRoom(defaultValue.loadingRoom);
      toggleAudioEnabled(defaultValue.audioEnabled);
      toggleVideoEnabled(defaultValue.videoEnabled);
      toggleIsFullscreen(defaultValue.isFullscreen);
      toggleConnectionLost(defaultValue.connectionLost);
      toggleParticipantDisconnected(defaultValue.participantDisconnected);
    });
  };

  return (
    <ZoomCallContext.Provider
      value={{
        appointment,
        audioEnabled,
        cameraId,
        connectionLost,
        isEnableCaptions,
        isFullscreen,
        isMinimize,
        isOpenChat,
        isOpenSettings,
        loadingRoom,
        loggerClient,
        microphoneId,
        participantDisconnected,
        resetValuesToDefault,
        room,
        setAppointment,
        setCameraId,
        setLoggerClient,
        setMicrophoneId,
        setRoom,
        setShowControls,
        setVideoTrack,
        showControls,
        toggleAudioEnabled,
        toggleConnectionLost,
        toggleIsEnableCaptions,
        toggleIsFullscreen,
        toggleIsMinimize,
        toggleIsOpenChat,
        toggleIsOpenSettings,
        toggleLoadingRoom,
        toggleParticipantDisconnected,
        toggleVideoEnabled,
        videoEnabled,
        videoTrack
      }}
    >
      <Settings />
      <Room />
      {children}
    </ZoomCallContext.Provider>
  );
};
