import { PlanCodes } from 'utils/enums';

import { useGetActivePlan } from './useGetActivePlan';

export const useGetLifeMDPlusPlan = () => {
  const { plan, isFetching } = useGetActivePlan({
    planCode: PlanCodes.LifeMDPlus
  });
  const defaultPricePoint = plan?.pricePoints?.find((p) => p.isDefault) || plan?.pricePoints[0];

  return {
    defaultPricePoint,
    isFetching,
    lifeMDPlusPlan: plan
  };
};
