import { useGetMembershipPlansQuery } from 'services/lookup/lookup';

import { selectLookup, selectUser } from 'store';

import { useAppSelector } from 'hooks';
import { PlanCodes } from 'utils/enums';
export const useGetActivePlan = ({
  planCode,
  planPricePointId
}: { planCode?: PlanCodes; planPricePointId?: string } = {}) => {
  const { activePricePoint, activePlanCode } = useAppSelector(selectUser);
  const { membershipPlans } = useAppSelector(selectLookup);
  const { data: fetchedPlans, isFetching } = useGetMembershipPlansQuery(undefined, {
    skip: !!membershipPlans.length
  });

  const plans = fetchedPlans?.data || membershipPlans;
  const plan = plans.find((el) => el.planCode === (planCode || activePlanCode));
  const pricePoint = plan?.pricePoints.find(
    (el) => el.planPricePointId === (planPricePointId || activePricePoint)
  );

  return {
    isFetching,
    plan,
    pricePoint
  };
};
