import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { getOffset } from './bmiLine.settings';
import { BMIlineProps } from './bmiLine.types';

const BMILine: React.FC<BMIlineProps> = ({ value, benefitsFlowStyles }) => {
  const tooltipClassName = classNames(
    'box--bottom absolute flex items-center gap-1 min-w-max bg-black max-md:text-sm -top-2.5 rounded-xl text-white max-w-[240px] shadow-sm p-3',
    getOffset(value)
  );

  return (
    <div
      className={classNames('relative flex min-w-full pt-12', {
        'text-gray-700': benefitsFlowStyles
      })}
    >
      <div className={tooltipClassName} data-testid="bmi-tooltip">
        {value > 30 && (
          <Common.Icon className="flex-none fill-white text-green" name="check-circle" />
        )}
        <span className="min-w-fit">Your BMI</span>
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full rounded-s-lg bg-red"></div>
        <span className="block font-bold">Below 27</span>
        {!benefitsFlowStyles && (
          <span className="px-0.5" data-testid="bmi-line-hint">
            Not qualified
          </span>
        )}
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full bg-yellow"></div>
        <span className="block font-bold">27-29.9</span>
        {!benefitsFlowStyles && (
          <span className="px-0.5 text-center" data-testid="bmi-line-hint">
            May qualify;
            <br /> lab work required
          </span>
        )}
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full bg-green-300"></div>
        <span className="block font-bold">30-34.9</span>
        {!benefitsFlowStyles && (
          <span className="px-0.5 text-center" data-testid="bmi-line-hint">
            May qualify
          </span>
        )}
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full bg-green-400"></div>
        <span className="block font-bold">35-39.9</span>
        {!benefitsFlowStyles && (
          <span className="px-0.5 text-center" data-testid="bmi-line-hint">
            May qualify
          </span>
        )}
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full rounded-e-lg bg-green"></div>
        <span className="block font-bold">{'>40'}</span>
        {!benefitsFlowStyles && (
          <span className="px-0.5 text-center" data-testid="bmi-line-hint">
            May qualify
          </span>
        )}
      </div>
    </div>
  );
};

export default BMILine;
