import { useState } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';

import { DetailsForProvidersProps } from './detailsForProvider.types';

const DetailsForProviders: React.FC<DetailsForProvidersProps> = ({
  onClickNext,
  appointmentDescription,
  category,
  title = 'Please add details for your provider.',
  placeholder
}) => {
  const [details, setDetails] = useState(appointmentDescription);
  const textareaPlaceholder =
    placeholder ||
    (category === 'Prescription refill'
      ? 'What prescription are you trying to refill?'
      : 'What brings you in today?');

  return (
    <div className="flex h-full grow flex-col justify-between">
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Common.ColorTag color="primary" icon="check" text={category} />
          <h1
            className="text-m2xl font-bold text-primary-700"
            data-testid="add_details_for_provider_header"
          >
            {title}
          </h1>
        </div>
        <Common.TextArea
          className="w-full"
          dataTestId="details_textArea"
          placeholder={textareaPlaceholder}
          rows={3}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        />
      </div>
      <Common.Button
        className="mx-auto mt-4"
        color="blue"
        dataTestId="continue_btn"
        fullWidthOnMobile
        onClick={() => onClickNext(details)}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default DetailsForProviders;
