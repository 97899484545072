import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

import { useLazyGetAppointmentsQuery } from 'services/appointments/appointments';

import { AppointmentItem } from 'store/appointments/appointments.types';
import { setUser } from 'store/user/userSlice';

import { notifyError } from 'shared/Toast/Toast';

import { useAppDispatch } from 'hooks';
import { AppointmentListTypes } from 'utils/enums';
import { parseUpcomingAppointment } from 'utils/helpers';

import { CommonSocketContextParams, PatientDoctorUpdateProps } from './commonSocketContext.types';

export const CommonSocketContext = createContext<CommonSocketContextParams>({
  appointmentUpdatedViaSocket: null
});

export const CommonSocketProvider: React.FC<PropsWithChildren<{ socket: Socket }>> = ({
  children,
  socket
}) => {
  const [appointment, setAppointment] = useState<AppointmentItem | null>(null);
  const [getAppointments] = useLazyGetAppointmentsQuery();
  const dispatch = useAppDispatch();

  const handleSetAppointmentUpdate = (appointment: AppointmentItem) => {
    setAppointment(parseUpcomingAppointment(appointment));
    getAppointments({ appointmentListType: AppointmentListTypes.UPCOMING });
    if (appointment.appointmentStatus === 'cancelled') {
      notifyError('This appointment has been cancelled.', true);
    }
  };

  const handlePatientDoctorUpdate = (params: PatientDoctorUpdateProps) => {
    dispatch(setUser({ doctorId: params.doctorId }));
  };

  useEffect(() => {
    socket.on('appointmentUpdate', handleSetAppointmentUpdate);
    socket.on('patientDoctorUpdate', handlePatientDoctorUpdate);
    return () => {
      socket.off('appointmentUpdate');
      socket.off('patientDoctorUpdate');
    };
  }, []);

  return (
    <CommonSocketContext.Provider value={{ appointmentUpdatedViaSocket: appointment }}>
      {children}
    </CommonSocketContext.Provider>
  );
};
