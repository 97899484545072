import { createApi } from '@reduxjs/toolkit/query/react';

import {
  setCurrentAppointment,
  setPastAppointments,
  setUpcomingAppointments
} from 'store/appointments/appointmentsSlice';

import { AppointmentListTypes } from 'utils/enums';
import { parseUpcomingAppointment } from 'utils/helpers';
import { baseQueryWithReauth } from 'utils/services';

import {
  AddAppointmentReqProps,
  AddAppointmentResProps,
  CallLogTracksReqProps,
  CallLogTracksResProps,
  CancelAppointmentReqProps,
  CancelAppointmentResProps,
  CheckAppointmentPaymentInfoReqProps,
  CheckAppointmentPaymentInfoResProps,
  CheckFreeAppointmentsReqProps,
  CheckFreeAppointmentsResProps,
  GetAppointmentReqProps,
  GetAppointmentResProps,
  GetAppointmentsReqProps,
  GetAppointmentsResProps,
  GetTokenZoomReqProps,
  GetTokenZoomResProps,
  GuestUserAppointmentProps,
  MissedBannerViewedReqProps,
  MissedBannerViewedResProps,
  PoorConnectionReqProps,
  PoorConnectionResProps,
  RescheduleAppointmentReqProps,
  RescheduleAppointmentResProps,
  SkipIdVerificationReqProps,
  SkipIdVerificationResProps,
  UpdateAppointmentReqProps,
  UpdateAppointmentResProps,
  UpdateCallErrorsReqProps,
  UpdateCallErrorsResProps,
  UploadFilesReqProps,
  UploadFilesResProps
} from './appointments.types';

export const appointmentsApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (build) => ({
    addAppointment: build.mutation<AddAppointmentResProps, AddAppointmentReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'appointments/schedule'
      })
    }),
    callLogTracks: build.mutation<CallLogTracksResProps, CallLogTracksReqProps>({
      query: ({ appointmentId, ...body }) => ({
        body: {
          ...body
        },
        headers: { Platform: 'web-patient' },
        method: 'POST',
        url: `appointments/${appointmentId}/call-log-tracks`
      })
    }),
    cancelAppointment: build.mutation<CancelAppointmentResProps, CancelAppointmentReqProps>({
      query: ({ appointmentId, ...body }) => ({
        body,
        method: 'PATCH',
        url: `appointments/${appointmentId}/cancel`
      })
    }),
    cancelAppointmentForGuest: build.mutation<CancelAppointmentResProps, GuestUserAppointmentProps>(
      {
        query: ({ id, accessToken, ...body }) => ({
          body,
          headers: {
            'API-KEY': import.meta.env.VITE_API_KEY,
            Authorization: accessToken
          },
          method: 'PATCH',
          url: `appointments/${id}/cancel`
        })
      }
    ),
    checkFreeAppointments: build.mutation<
      CheckFreeAppointmentsResProps,
      CheckFreeAppointmentsReqProps
    >({
      query: (body) => ({
        body,
        method: 'POST',
        url: 'appointments/payment-predict'
      })
    }),
    checkPaymentInfo: build.query<
      CheckAppointmentPaymentInfoResProps,
      CheckAppointmentPaymentInfoReqProps
    >({
      query: (appointmentId) => ({
        url: `appointments/${appointmentId}/payment-info`
      })
    }),
    getAppointment: build.query<GetAppointmentResProps, GetAppointmentReqProps>({
      keepUnusedDataFor: 1,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setCurrentAppointment(data.data));
        }
      },
      query: (id) => `appointments/${id}`
    }),
    getAppointmentInfoForGuest: build.query<GetAppointmentResProps, GuestUserAppointmentProps>({
      keepUnusedDataFor: 1,
      query: ({ accessToken, id }) => ({
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        url: `appointments/${id}`
      })
    }),
    getAppointments: build.query<GetAppointmentsResProps, GetAppointmentsReqProps>({
      keepUnusedDataFor: 3,
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          switch (arg.appointmentListType) {
            case AppointmentListTypes.UPCOMING:
              dispatch(setUpcomingAppointments([...data.data.map(parseUpcomingAppointment)]));
              break;
            case AppointmentListTypes.PAST:
              dispatch(setPastAppointments(data.data));
              break;
          }
        }
      },
      query: (params) => ({
        params: { ...params, limit: 50 },
        url: 'appointments'
      })
    }),
    getTokenZoom: build.query<GetTokenZoomResProps, GetTokenZoomReqProps>({
      query: (id) => `appointments/${id}/call-token-zoom?type=video`
    }),
    missedBannerViewed: build.mutation<MissedBannerViewedResProps, MissedBannerViewedReqProps>({
      query: ({ appointmentId }) => ({
        method: 'PATCH',
        url: `appointments/${appointmentId}/missed-banner-viewed`
      })
    }),
    poorConnection: build.mutation<PoorConnectionResProps, PoorConnectionReqProps>({
      query: ({ appointmentId, ...body }) => ({
        body,
        method: 'POST',
        url: `appointments/${appointmentId}/poor-connection`
      })
    }),
    rescheduleAppointment: build.mutation<
      RescheduleAppointmentResProps,
      RescheduleAppointmentReqProps
    >({
      query: ({ appointmentId, ...body }) => ({
        body,
        method: 'PATCH',
        url: `appointments/${appointmentId}/reschedule`
      })
    }),
    skipIdVerification: build.mutation<SkipIdVerificationResProps, SkipIdVerificationReqProps>({
      query: ({ appointmentId }) => ({
        method: 'PATCH',
        url: `/appointments/${appointmentId}/skip-id-verification`
      })
    }),
    updateAppointment: build.mutation<UpdateAppointmentResProps, UpdateAppointmentReqProps>({
      query: ({ accessToken, appointmentId, ...body }) => ({
        body,
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        method: 'PATCH',
        url: `appointments/${appointmentId}`
      })
    }),
    updateCallErrors: build.mutation<UpdateCallErrorsResProps, UpdateCallErrorsReqProps>({
      query: ({ appointmentId, ...body }) => ({
        body: {
          ...body,
          callType: 'video'
        },
        headers: { Platform: 'web-patient' },
        method: 'POST',
        url: `/appointments/${appointmentId}/call-event-errors`
      })
    }),
    uploadFiles: build.mutation<UploadFilesResProps, UploadFilesReqProps>({
      query: ({ appointmentId, body }) => ({
        body,
        method: 'POST',
        url: `appointments/${appointmentId}/upload`
      })
    })
  }),
  reducerPath: 'appointmentsApi',
  tagTypes: ['Appointments']
});

export const {
  useCheckFreeAppointmentsMutation,
  useCheckPaymentInfoQuery,
  useGetAppointmentsQuery,
  useLazyGetAppointmentsQuery,
  useAddAppointmentMutation,
  useCancelAppointmentMutation,
  useUpdateAppointmentMutation,
  useCallLogTracksMutation,
  useRescheduleAppointmentMutation,
  usePoorConnectionMutation,
  useGetAppointmentQuery,
  useLazyGetAppointmentQuery,
  useUploadFilesMutation,
  useGetAppointmentInfoForGuestQuery,
  useMissedBannerViewedMutation,
  useLazyGetTokenZoomQuery,
  useUpdateCallErrorsMutation,
  useSkipIdVerificationMutation
} = appointmentsApi;
