import { JSX, ReactElement } from 'react';
import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import { selectNewAppointmentExtended, selectUser } from 'store';

import AppointmentMif from 'pages/AppointmentMif';

import SlideAnimateWrapper from 'shared/animationWrappers/SlideAnimateWrapper';
import Confirmation from 'widgets/createAppointment/Confirmation';
import DateAndTime from 'widgets/createAppointment/DateAndTime';
import AdditionalInformation from 'widgets/createAppointment/extendedFlow/AdditionalInformation';
import AppointmentStatus from 'widgets/createAppointment/extendedFlow/AppointmentStatus';
import AppointmentTypesPicker from 'widgets/createAppointment/extendedFlow/AppointmentTypesPicker';
import ChooseTypeOfCare from 'widgets/createAppointment/extendedFlow/ChooseTypeOfCare';
import ConfirmAndPay from 'widgets/createAppointment/extendedFlow/ConfirmAndPay';
import ConfirmWithoutSubscription from 'widgets/createAppointment/extendedFlow/ConfirmWithoutSubscription';
import Address from 'widgets/createAppointment/extendedFlow/createAccount/Address';
import Dob from 'widgets/createAppointment/extendedFlow/createAccount/DOB';
import Intro from 'widgets/createAppointment/extendedFlow/createAccount/Intro';
import Password from 'widgets/createAppointment/extendedFlow/createAccount/Password';
import PersonalDetails from 'widgets/createAppointment/extendedFlow/createAccount/PersonalDetails';
import PhoneNumber from 'widgets/createAppointment/extendedFlow/createAccount/PhoneNumber';
import DetailsForProviders from 'widgets/createAppointment/extendedFlow/DetailsForProvider';
import LabsToReview from 'widgets/createAppointment/extendedFlow/LabsToReview';
import PreCheckout from 'widgets/createAppointment/extendedFlow/PreCheckout';
import PrescriptionFor from 'widgets/createAppointment/extendedFlow/PrescriptionFor';
import PrescriptionsType from 'widgets/createAppointment/extendedFlow/PrescriptionsType';
import QualifiedForAsync from 'widgets/createAppointment/extendedFlow/QualifiedForAsync';
import RenewPrescription from 'widgets/createAppointment/extendedFlow/RenewPrescription';
import SubscriptionRequired from 'widgets/createAppointment/extendedFlow/SubscriptionRequired';
import UnableToRefill from 'widgets/createAppointment/extendedFlow/UnableToRefill';
import RedFlags from 'widgets/createAppointment/RedFlags';

import { DEFAULT_LAB_TESTS_APPT_CODE } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

import { PossibleStepsType } from '../createAppointmentExtended.types';

import { Props } from './content.types';

const renderWithAnimation = (
  component: JSX.Element,
  key: string,
  params?: {
    className?: string;
    isBackAnimation?: boolean;
  }
) => (
  <SlideAnimateWrapper
    className={classNames(
      'mx-auto w-full max-w-[500px] h-full p-4 md:pt-8 max-md:flex-1',
      params?.className ?? ''
    )}
    isBackAnimation={!!params?.isBackAnimation}
    key={key}
  >
    {component}
  </SlideAnimateWrapper>
);

const Content: React.FC<Props> = ({
  defaultPlanId = '',
  currentPlan,
  loading = false,
  step,
  moveToStep,
  isBackAnimation,
  onSelectDateAndTime,
  selectedPlan,
  onUpgradePlan,
  appointmentTypes,
  onCreateAccount,
  exitFlow,
  onSubmitMIF,
  isRequiredToUpgradeToLifeMDPlus,
  haveToPayForAppt
}) => {
  const {
    accessToken,
    doctorId: myDoctorID,
    activePlanCode,
    activePricePoint
  } = useAppSelector(selectUser);
  const {
    appointmentTypeId,
    appointmentDescription,
    initialDate,
    startTime,
    endTime,
    _id,
    callMethod,
    code,
    displayName,
    doctorId,
    mifCode,
    // files,
    asyncAllowed,
    membershipData = {
      planId: '',
      planPricePoint: null
    },
    appointmentStatus = 'pending',
    careProviders,
    user,
    src
  } = useAppSelector(selectNewAppointmentExtended);

  const isLabTestsAppointment = code === DEFAULT_LAB_TESTS_APPT_CODE;

  const content: { [key in PossibleStepsType[number]]: ReactElement } = {
    'appointment-types-picker': renderWithAnimation(
      <AppointmentTypesPicker
        appointmentTypes={appointmentTypes}
        onClickNext={(data) => {
          moveToStep({
            data,
            step: 'appointment-types-picker'
          });
        }}
      />,
      'appointment-types-picker',
      {
        isBackAnimation
      }
    ),
    'choose-type-of-care': renderWithAnimation(
      <ChooseTypeOfCare
        onSelect={(data) =>
          moveToStep({
            data,
            step: 'choose-type-of-care'
          })
        }
      />,
      'choose-type-of-care',
      {
        className: 'max-w-screen-sm',
        isBackAnimation
      }
    ),
    confirmation: renderWithAnimation(
      callMethod === 'message' || careProviders?.includes('SteadyMD') ? (
        <AppointmentStatus
          appointment={{
            category: careProviders?.includes('SteadyMD') ? 'queue' : undefined,
            displayName,
            id: _id ?? '',
            method: callMethod,
            status: appointmentStatus
          }}
          postCheckout={haveToPayForAppt}
        />
      ) : (
        <Confirmation
          appointment={{
            apptName: displayName,
            description: appointmentDescription,
            endTime,
            id: _id ?? '',
            startTime,
            subtitle: isLabTestsAppointment ? 'Appointment scheduled.' : '',
            title: isLabTestsAppointment ? `${displayName} appointment` : 'Appointment is scheduled'
          }}
          providerId={doctorId}
        />
      ),
      'confirmation',
      { isBackAnimation }
    ),
    'create-account-address': renderWithAnimation(
      <Address
        onContinue={() =>
          moveToStep({
            step: 'create-account-address'
          })
        }
      />,
      'create-account-address',
      {
        isBackAnimation
      }
    ),
    'create-account-dob': renderWithAnimation(
      <Dob
        onContinue={() =>
          moveToStep({
            step: 'create-account-dob'
          })
        }
      />,
      'create-account-dob',
      {
        isBackAnimation
      }
    ),
    'create-account-intro': renderWithAnimation(
      <Intro onContinue={() => moveToStep('next')} />,
      'create-account-intro',
      {
        isBackAnimation
      }
    ),
    'create-account-password': renderWithAnimation(
      <Password loading={loading} onContinue={onCreateAccount} />,
      'create-account-password',
      {
        isBackAnimation
      }
    ),
    'create-account-personal-details': renderWithAnimation(
      <PersonalDetails
        onContinue={() =>
          moveToStep({
            step: 'create-account-personal-details'
          })
        }
        onExit={(email) =>
          exitFlow({
            pathname: PathName.Login,
            search: `?new=false&with=email&email=${email}`
          })
        }
      />,
      'create-account-personal-details',
      {
        isBackAnimation
      }
    ),
    'create-account-phone': renderWithAnimation(
      <PhoneNumber
        onContinue={() =>
          moveToStep({
            step: 'create-account-phone'
          })
        }
      />,
      'create-account-phone',
      {
        isBackAnimation
      }
    ),
    'date-time': renderWithAnimation(
      <DateAndTime
        accessToken={!!activePlanCode ? accessToken : undefined}
        appointmentTypeId={appointmentTypeId}
        doctorId={myDoctorID}
        initialDate={initialDate}
        isSignUp={!activePlanCode}
        loading={loading}
        onboardingUserData={
          !!activePlanCode
            ? undefined
            : { planID: membershipData?.planId || defaultPlanId, state: user?.address?.state ?? '' }
        }
        providerType={!myDoctorID || isLabTestsAppointment ? 'any' : undefined}
        showSelectorBetweenProviders={!isLabTestsAppointment}
        updateStartDate={(_, value, valueEnd, id, displayName) => {
          onSelectDateAndTime({
            doctorId: id || myDoctorID,
            endTime: valueEnd,
            providerName: displayName,
            startTime: value
          });
        }}
        onSelect={() => {
          moveToStep({
            step: 'date-time'
          });
        }}
      />,
      'date-time',
      { className: 'max-w-screen-sm', isBackAnimation } // as it was before, to find out new size
    ),
    'details-for-provider': renderWithAnimation(
      <DetailsForProviders
        appointmentDescription={appointmentDescription}
        category={displayName}
        placeholder={isLabTestsAppointment ? '(Optional) Add details here...' : undefined}
        title={
          isLabTestsAppointment
            ? 'Are there any details about your labs you’d like to discuss?'
            : undefined
        }
        onClickNext={(appointmentDescription) =>
          moveToStep({
            data: { appointmentDescription, hasAccount: !!accessToken },
            step: 'details-for-provider'
          })
        }
      />,
      'details-for-provider',
      { isBackAnimation }
    ),
    'is-renew': renderWithAnimation(
      <RenewPrescription onSelect={() => moveToStep({ step: 'is-renew' })} />,
      'is-renew',
      {
        isBackAnimation
      }
    ),
    'labs-to-review': renderWithAnimation(
      <LabsToReview
        onSelect={(resultsID) => {
          moveToStep({
            data: {
              labsResultID: resultsID
            },
            step: 'labs-to-review'
          });
        }}
      />,
      'labs-to-review',
      {
        isBackAnimation
      }
    ),
    mif: renderWithAnimation(
      <AppointmentMif
        behaviorContext={asyncAllowed ? 'allow-dq' : 'strict'}
        loading={loading}
        mifCode={mifCode}
        onContinue={() => onSubmitMIF(!!accessToken)}
      />,
      'mif',
      {
        isBackAnimation
      }
    ),
    'payment-checkout': renderWithAnimation(
      activePricePoint ? (
        <ConfirmAndPay
          currentPlan={currentPlan}
          isRequiredToUpgradeToLifeMDPlus={isRequiredToUpgradeToLifeMDPlus}
          loading={loading}
          selectedPlan={selectedPlan}
          onUpgradePlan={() => onUpgradePlan({ isNewUser: false })}
        />
      ) : (
        <ConfirmWithoutSubscription
          loading={loading}
          onProceed={(formData) => onUpgradePlan({ formData, isNewUser: true })}
        />
      ),
      'payment-checkout',
      { className: '!max-w-screen-sm', isBackAnimation }
    ),
    'pre-checkout': renderWithAnimation(
      <PreCheckout
        haveToPayForAppt={haveToPayForAppt}
        loading={loading}
        title={displayName}
        onContinue={() =>
          moveToStep({
            step: 'pre-checkout'
          })
        }
      />,
      'pre-checkout',
      { isBackAnimation }
    ),
    'prescription-for': renderWithAnimation(
      <PrescriptionFor
        onChoosePrescriptionFor={(data) => moveToStep({ data, step: 'prescription-for' })}
      />,
      'prescription-for',
      { isBackAnimation }
    ),
    'prescriptions-type': renderWithAnimation(
      <PrescriptionsType
        appointmentTypes={appointmentTypes}
        onChoosePrescriptionsType={(data) => moveToStep({ data, step: 'prescriptions-type' })}
      />,
      'prescriptions-type',
      {
        isBackAnimation
      }
    ),
    'qualified-for-async': renderWithAnimation(
      <QualifiedForAsync
        category={displayName}
        isRequiredToUpgradeToLifeMDPlus={isRequiredToUpgradeToLifeMDPlus}
        onSelect={(data) =>
          moveToStep({
            data,
            step: 'qualified-for-async'
          })
        }
      />,
      'qualified-for-async',
      {
        isBackAnimation
      }
    ),
    'red-flags': renderWithAnimation(
      <RedFlags
        appointmentTypes={appointmentTypes}
        loading={loading}
        src={src}
        onSelect={(data) =>
          moveToStep({
            data,
            step: 'red-flags'
          })
        }
        onSkip={() => moveToStep('prev')}
      />,
      'red-flags',
      { className: 'max-w-screen-sm', isBackAnimation }
    ),
    'subscription-required': renderWithAnimation(
      <SubscriptionRequired
        callMethod={callMethod}
        category={displayName}
        onSelect={(data) =>
          moveToStep({
            data,
            step: 'subscription-required'
          })
        }
      />,
      'subscription-required',
      {
        isBackAnimation
      }
    ),
    'unable-to-refill': renderWithAnimation(<UnableToRefill />, 'unable-to-refill', {
      isBackAnimation
    }),
    'upload-files': renderWithAnimation(
      <AdditionalInformation
        category={displayName}
        required={code === DEFAULT_LAB_TESTS_APPT_CODE}
        title={
          code === DEFAULT_LAB_TESTS_APPT_CODE
            ? 'Please upload the labs that you would like to review.'
            : undefined
        }
        onContinue={(data) => moveToStep({ data, step: 'upload-files' })}
      />,
      'upload-files',
      {
        isBackAnimation
      }
    )
  };

  return <AnimatePresence mode="wait">{content[step]}</AnimatePresence>;
};

export default Content;
