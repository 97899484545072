import { FormEvent } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectLookup, selectOrchestrate, selectSignUp, selectUser } from 'store';
import { ValidCouponCodes } from 'store/signup/signup.types';

import Heading from 'pages/SignUp/components/Heading';

import { AltSignupStepProps } from 'containers/SignUp/Content/content.types';
import BasicConfirmation from 'modals/BasicConfirmation';
import { notifyError } from 'shared/Toast/Toast';

import { TRIPLE_THERAPY_PRICE_POINTS } from 'constants/pricepoints';
import { useAppSelector } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import useWidth from 'hooks/useWidth';
import { PlanCodes } from 'utils/enums';

import { MedicationsListInfo, NextSteps, TTWeightGraphAndInfo } from './parts';

// const arr = [
//   {
//     id: '1',
//     text: (
//       <div>
//         <p className="text-mSm text-gray md:text-sm">
//           <span className="mb-4 block">
//             These medications are FDA-approved for other uses, but are also prescribed by weight
//             loss specialists to help you lose weight.
//           </span>
//           <span className="block">
//             Like any medication, there can be side effects. Most are temporary and often go away as
//             your body adjusts. Check your treatment overview for more details, and don't hesitate to
//             contact your provider with any questions.
//           </span>

//           <h3 className="mb-1 mt-4 text-mSm font-bold text-primary-400">Metformin</h3>
//           <span>
//             Common potential side effects include nausea, diarrhea, and stomach upset. Taking the
//             medication with meals can help lessen these side effects.
//           </span>
//           <h3 className="mb-1 mt-4 text-mSm font-bold text-primary-400">Bupropion</h3>
//           <span>
//             Common side effects of Bupropion include difficulty sleeping and increased anxiety.
//           </span>
//           <h3 className="mb-1 mt-4 text-mSm font-bold text-primary-400">Topiramate</h3>
//           <span>
//             Common potential side effects include tingling in the arms and legs (paresthesia),
//             altered taste perception (especially with carbonated beverages), drowsiness, memory
//             issues, effects on thinking and alertness, and kidney stones.
//           </span>
//         </p>
//       </div>
//     ),
//     title: 'How it works & side effects'
//   }
// ];

const TTPlanSelect: React.FC<AltSignupStepProps> = ({ onContinue, selectedFlow }) => {
  const { isMobile } = useWidth();
  const [isOpenCancelPopup, toggleCancelPopup] = useToggle(false);
  const { membershipPlans } = useAppSelector(selectSignUp);
  const { membershipPlans: authorizedUserMembershipPlans } = useAppSelector(selectLookup);
  const {
    user: { firstName }
  } = useAppSelector(selectOrchestrate);
  const { firstName: authorizedUserFirstName } = useAppSelector(selectUser);

  const { send, isLoading, handleProspect } = useSubmitOrchestrateForm();

  const plans = membershipPlans.length > 0 ? membershipPlans : authorizedUserMembershipPlans;

  const WMPlan = plans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);

  const selectedPP = WMPlan?.pricePoints.find((pp) =>
    TRIPLE_THERAPY_PRICE_POINTS.includes(pp.planPricePointId)
  );

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedPP) {
      const couponCode: ValidCouponCodes = 'LFMD30OFF';
      send(
        'payment',
        {
          coupon_codes: [couponCode],
          includesScales: false,
          product_handle: WMPlan?.planCode,
          product_price_point_handle: selectedPP.planPricePointId
        },
        () => onContinue()
      );
    } else {
      notifyError('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="md:mx-auto md:max-w-[500px]">
      <BasicConfirmation
        cancelButtonText="Back"
        confirmButtonText="Yes, cancel"
        isLoading={isLoading}
        isOpen={isOpenCancelPopup}
        onClose={toggleCancelPopup}
        onConfirm={() =>
          handleProspect({ answer: false, question: '' }, selectedFlow === 'authorized')
        }
      />
      <div className="mb-6">
        <Heading
          category="Plan"
          title={`${firstName || authorizedUserFirstName}, let's get started on your Triple Therapy Program!`}
        />
      </div>
      <form className="grid gap-6" onSubmit={handleSubmit}>
        <div className="">
          <TTWeightGraphAndInfo
            discountPrice="$99"
            pricePoint={selectedPP}
            regularPrice={`$${selectedPP?.monthlyPrice}/mo`}
          />
        </div>
        <div className="">
          <NextSteps activeStep="checkout" />
        </div>
        <p className="text-mXl font-semibold">
          A unique combination of medications, each specifically{' '}
          <span className="text-primary">designed to support your weight loss</span> journey.
        </p>
        <div>
          <MedicationsListInfo />
        </div>
        {/* <div>
          <AccordionInfo items={arr} />
        </div> */}
        <Common.Button
          className="sticky bottom-6 z-10 mt-auto md:mx-auto"
          color="blue"
          isLoading={isLoading}
          size={isMobile ? 'lg' : 'md'}
          type="submit"
          fullWidthOnMobile
        >
          Continue to checkout
        </Common.Button>
        <Common.Button
          className="-mt-2 md:mx-auto"
          color="white-alt"
          disabled={isLoading}
          type="button"
          fullWidthOnMobile
          onClick={toggleCancelPopup}
        >
          Cancel
        </Common.Button>
      </form>
    </div>
  );
};

export default TTPlanSelect;
