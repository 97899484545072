import {
  ExtendedMifResponseItem,
  MifResponseItem,
  QuestionWithAnswerConfig
} from 'services/mifs/mifs.types';

export const manualErrorCheck = ({
  config,
  answers
}: {
  answers: MifResponseItem | ExtendedMifResponseItem;
  config: QuestionWithAnswerConfig;
}): Record<string, string> => {
  try {
    const errors: Record<string, string> | null = {};
    config?.inputs?.forEach((input) => {
      if (input.validation.minLength && !answers) {
        errors[input.value] = 'This field is required';
      } else if (input.validation && !!answers && 'inputs' in answers) {
        const answer = answers.inputs.find((ans) => ans.value === input.value)?.text ?? '';
        switch (true) {
          case input.validation.maxLength && answer?.length > input.validation.maxLength:
            errors[input.value] =
              `This field must be less than ${input.validation.maxLength} characters`;
            break;
          case input.validation.minLength && answer?.length < input.validation.minLength:
            errors[input.value] =
              `This field must be more than ${input.validation.minLength} characters`;
            break;
          case input.validation.pattern && !new RegExp(input.validation.pattern).test(answer):
            errors[input.value] = 'Please enter a valid input';
            break;
          default:
            break;
        }
      }
    });
    return errors;
  } catch {
    throw new Error('failed validation check');
  }
};
