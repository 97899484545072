import { Common } from '@thecvlb/design-system';

import { CareTypes } from 'containers/CreateAppointmentExtended/createAppointmentExtended.types';

export const getBuildCards = (onSelect: (v: CareTypes) => void, isWeightManagement?: boolean) => {
  return [
    {
      icon: <Common.Icon className="text-red" name="medical-plus" />,
      onClick: () => onSelect('talk-to-a-doctor'),
      subtitle: '24/7 access to a doctor or nurse practitioner',
      title: 'Talk to a doctor'
    },
    {
      icon: <Common.Icon name="pill" />,
      onClick: () => onSelect('prescriptions'),
      subtitle: 'Refill a medication',
      title: 'Prescriptions'
    },
    ...(isWeightManagement
      ? [
          {
            icon: <Common.Icon name="lab" />,
            onClick: () => onSelect('shop-labs'),
            subtitle: 'Know your body',
            title: 'Labs'
          }
        ]
      : [])
  ];
};
